import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";

import styles from "../../assets/jss/material-kit-react/views/componentsSections/javascriptStyles";
import Button from "../../Components/initComponents/CustomButtons/Button";
import Card from "../../Components/initComponents/Card/Card";
import CardHeader from "../../Components/initComponents/Card/CardHeader";
import CardBody from "../../Components/initComponents/Card/CardBody";

import featuresStyle from "../ConsultantList/featuresStyle";

const useStyles = makeStyles(styles);
const featureStyles = makeStyles(featuresStyle);
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

Transition.displayName = "Transition";

const ConsultantActiveTermModal = (props) => {
  const { isModal, setIsModal } = props;

  const classes = useStyles();
  const featureClasses = featureStyles();

  return (
    <>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={isModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setIsModal(false)}
          >
            <Close className={classes.modalClose} />
          </IconButton>
        </DialogTitle>

        <DialogContent
          id="classic-modal-slide-description"
          className={featureClasses.modalBody}
        >
          <Card profile className="boxShadowNone">
            <CardHeader image>
              <div className={featureClasses.cardTitleAbsolute} />
            </CardHeader>
            <CardBody>
              <h4 className="fb">
                예술인컨설팅매칭사업(아이컨택)
                <br />
                컨설턴트 운영 기준
              </h4>

              <br />
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                }}
              >
                1. 대상기준
                <br />
                1. 컨설턴트는 해당 분야에 대하여 전문성과 식견이 있고 청렴성을
                갖춘 인사로서 다음 각 호 중 하나 이상의 자격을 갖추어야 함
                <br />
                1) 문화예술의 창작(시각예술, 공연예술, 문학)․비평․연구․기획
                분야에서 10년 이상 종사하거나 활동한 자
                <br />
                2) 문화일반, 기타(기술, 창업, 홍보, 마케팅 등) 컨설팅에 필요한
                분야에서 10년 이상 종사하거나 활동한 자
                <br />
                3) 문화예술단체에서 10년 이상 활동한 자
                <br />
                4) 제1호 내지 제3호에 해당하는 경력을 합하여 10년 이상인 자
                <br />
                5) 판‧검사, 변호사, 변리사, 공인회계사, 감정평가사로서 5년 이상
                실무경력자
                <br />
                6) 제1호 내지 제4호와 동등한 자격을 갖추었다고 위원회가 인정한
                자
                <br />
                7) 인터넷을 활용한 온라인시스템 상 서류제출이 가능한 자
                <br />
                <br />
                2. 다음 각 호의 어느 하나에 해당하는 자는 컨설턴트가 될 수 없음
                <br />
                1) 컨설턴트로서 불공정, 금품향응 수수 등 심의위원 직무수행지침
                위반을 이유로 해촉된 자로 심의위원 자격제한 기간 중에 있는 자
                <br />
                2) 보조사업자 또는 보조단체의 대표자로서 관련 법령을 위반하여
                현재 보조금 지원제한 기간 중에 있는 자
                <br />
                3) 성희롱, 성폭력 등의 중대한 문제로 인해 정상적인 컨설팅이
                불가능하다고 판단될 경우
                <br />
                <br />
                2. 컨설턴트의 의무
                <br />
                1) “컨설턴트”는 “재단”의 “예술인컨설팅매칭사업”의 목적 및 취지를
                이해하고, 컨설팅에 성실히 이행해야 함
                <br />
                2) “컨설턴트”는 사업 진행을 위해 개인정보동의 범위 내에 공개 및
                제공 됨
                <br />
                3) “컨설턴트”는 컨설팅 중 취득한 사항에 대하여 컨설턴트 활동
                중은 물론 이후에도 누설하지 않아야 함
                <br />
                4) “컨설턴트”는 컨설팅 수락 후 컨설팅의 불가피한 변경이 예상되는
                경우에는 최소 1주 전에 “예술가”와 협의를 거쳐야 함
                <br />
                5) “컨설턴트”는 컨설팅 종료 후 2주 이내에 컨설팅 결과서를
                웹사이트에 업로드 해야 하며, 미진한 사항이 있을 경우에 “재단”은
                해당 사항의 보완을 요구할 수 있음
                <br />
                <br />
                3. 컨설팅비 지급
                <br />
                1) “재단”은 “컨설턴트”의 결과보고서를 승인 완료한 후 2주~1개월
                이내에 제출한 본인명의 계좌로 컨설팅비를 지급 완료함
                <br />
                2) 컨설팅비는 부산문화재단 강사수당 지급기준 이내로 함
                <br />
                <br />
                4.제3자 정보제공 동의
                <br />
                제공받는 자 : 컨설팅 매칭 예술인 <br />
                이용목적 : 컨설팅 <br />
                제공하는 개인정보 항목 : 성명, 휴대폰번호, 이메일 주소 <br />
                보유 및 이용기간 : 컨설팅 진행기간 <br />
                <br />
                <br />
              </p>
            </CardBody>
          </Card>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setIsModal(false)} color="primary" simple>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConsultantActiveTermModal;
