import React, { useEffect, useContext } from 'react';

import Firebase from '../../Context/Firebase';
import AuthenticationContext from '../../Context/Authentication';


const Test = () => {
  const firebase = useContext(Firebase);
  const authUser = useContext(AuthenticationContext);
  useEffect(() => {
    firebase.firestore().collection('UsersCollection')
      .doc('tgkim@gdcomm.io')
      .collection('subCollection')
      .doc()
      .add({
        test: 'sdfasdf',
      });
  });

  return (
    <div>
      테스트 페이지입니다.
    </div>
  );
};


export default Test;
