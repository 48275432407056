// 계정찾기 중 이메일 특정 부분 별표표시
export const replaceEmailInfo = (value) => {
  const selectEmailLoaction = value.indexOf('@');
  const targetChar = value.substring(selectEmailLoaction - 3, selectEmailLoaction);
  return value.replace(targetChar, '***');
};

// 랜덤 키 생성 함수
export const RandomKey = (length) => {
  const chars = '123456789ABCDEFGHIJKLMNPQRSTUVWXTZ';
  let randomString = '';
  for (let i = 0; i < length; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomString += chars.substring(rnum, rnum + 1);
  }

  return randomString;
};


// 날짜 출력 함수
export const printDate = (value) => {
  const date = value.getDate();
  const month = value.getMonth();
  const year = value.getFullYear();
  const dateString = `${year}년 ${month + 1}월 ${date}일 `;
  return dateString;
};
export const printDateDash = (value) => {
  const date = ("0" + value.getDate()).slice(-2);
  const month = ("0" + (value.getMonth() + 1)).slice(-2);
  const year = value.getFullYear();
  const dateString = `${year}-${month}-${date}`;
  return dateString;
};

export const autoHypenPhone = (value) => {
  const str = value.replace(/[^0-9]/g, '');
  let tmp = '';
  if (str.length < 4) {
    return str;
  }
  if (str.length < 7) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3);
    return tmp;
  }
  if (str.length < 11) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3, 3);
    tmp += '-';
    tmp += str.substr(6);
    return tmp;
  }
  tmp += str.substr(0, 3);
  tmp += '-';
  tmp += str.substr(3, 4);
  tmp += '-';
  tmp += str.substr(7);
  return tmp;
};


export const getCookie = (name) => {
  let i;
  let x;
  let y;
  const ARRcookies = document.cookie.split(';');
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
    x = x.replace(/^\s+|\s+$/g, '');
    if (x == name) {
      return unescape(y);
    }
  }
};

export const setCookie = (cName, value, expireDays) => {
  const expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + expireDays);
  const cValue = escape(value) + ((expireDays === null) ? '' : `; expires=${expireDate.toUTCString()};`);
  document.cookie = `${cName}=${cValue}`;
};
