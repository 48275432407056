import React from 'react';
import Container from '@material-ui/core/Container';
import GridContainer from '../initComponents/Grid/GridContainer';
import GridItem from '../initComponents/Grid/GridItem';
import subBannerText from '../../assets/img/subbantext.jpg';
import infograph from '../../assets/img/infograph.jpg';


const Index = () => (
  <Container maxWidth="lg">

    <GridContainer>
      <GridItem
        xs={12}
        sm={12}
        md={8}

      >
        <img src={subBannerText} style={{ maxWidth: '100%', display: 'block', margin: '0 auto' }} alt="..." />
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={4}

      >
        <img src={infograph} style={{ maxWidth: '100%', display: 'block', margin: '0 auto' }} alt="..." />
      </GridItem>


    </GridContainer>
  </Container>
);


export default Index;
