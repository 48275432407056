import React from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import * as SiteConfig from '../../Constants/SiteConfig';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: SiteConfig.mainColor,
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

const ServiceTerm = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AssignmentIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          서비스 이용약관
        </Typography>
        <br />

        <br />

        <div style={{ width: '100%', maxWidth: '912px', margin: '0 auto' }}>
          제1장 총직
          <br />

          제1조 (목적)
          <br />

          본 약관은 부산문화재단(이하 "문화재단")이 구축·관리·운영하고 문화재단을 비롯하여 문화재단과 업무협약(MOU) 체결에 의한 사용기관 (이하 "사용기관")이 사용하는
          예술인컨설팅매칭시스템(이하 "아이컨택"/ 웹사이트 도메인명 : www.icontact.kr)이 제공하는 모든 서비스 (이하 "서비스")의 이용조건 및 절차, 이용자와
          문화재단·사용기관의 권리, 의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.
          <br />
          <br />

          제2조 (약관의 명시, 효력 및 개정)
          <br />

          1. 문화재단은 약관의 내용과 주소지, 관리자와 운영자의 성명, 사용기관 개인정보보호 담당자의 성명, 연락처(전화, 팩스 등) 등을 이용자가 알 수 있도록 아이컨택 웹사이트 초기
          서비스 화면(전면)에 게시하고, 동시에 그 효력이 발생됩니다.
          <br />

          2.. 문화재단은 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래 기본법」, 「전자서명법」, 「지식재산 기본법」, 「저작권법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
          「개인정보 보호법」 등 대한민국 관련법령과 상관습에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
          <br />
          3. 문화재단은 이용자의 이용약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 아이컨택 웹사이트의 초기 서비스 화면(전면)에 그 적용일자 최소 7일 전부터
          적용일자 전일까지 공지합니다. 개정된 약관은 공지와 동시에 그 효력이 발생됩니다.
          <br />
          4. 문화재단은 이용자가 본 약관 내용에 동의하는 것을 조건으로 이용자에게 서비스를 제공할 것이며, 이용자가 본 약관의 내용에 동의하는 경우, 아이컨택의 서비스 제공 행위 및 이용자의
          서비스 사용 행위에는 본 약관이 우선적으로 적용될 것입니다.
          <br />
          5. 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 후에도 거부의사를 표시 하지 아니하고 서비스를 계속 사용할 경우
          약관의 변경 사항에 동의한 것으로 간주됩니다.
          <br />
          6. 이 약관에 동의하는 것은 정기적으로 아이컨택 웹사이트를 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의
          피해는 문화재단에서 책임지지 않습니다.
          <br />
          <br />
          제3조 (용어의 정의)
          <br />

          본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <br />

          1. 이용자 : 본 약관에 동의하고 회원으로 가입하여 아이컨택에서 제공하는 서비스를 받는 개인 또는 단체를 말합니다.
          <br />
          2. 이용계약 : 서비스 이용과 관련하여 문화재단과 이용자 간에 체결하는 계약을 말합니다.
          <br />
          3. 가 입 : 아이컨택에서 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료시키는 행위를 말합니다.
          <br />
          4. 회 원 : 아이컨택에서 회원가입에 필요한 이용자(개인 또는 단체)정보를 제공하여 회원 등록을 한 자로서, 아이컨택의 정보 및 서비스를 이용할 수 있는 자를 말합니다.
          <br />
          5. 회원 정보 : 아이컨택 서비스 이용자의 개인(단체) 정보와 재단 지원 사업 신청·지원수혜 실적 정보 등 아이컨택에 등록된 정보 일체를 말합니다.
          <br />
          6. 이용자번호(E-mail) : 이용자의 식별과 이용자가 서비스 이용을 위하여 이용자가 선정하고 아이컨택이 관리하는 메일주소를 말합니다.
          <br />
          7. 비밀번호(PW) : 이용자가 아이컨택에 등록한 등록회원과 동일인 여부를 확인하고 통신상의 자신의 회원정보 보호를 위하여 이용자 자신이 선정한 영문자와 숫자의 조합을 말합니다.
          <br />
          8. 탈퇴(해지) : 회원이 아이컨택의 이용계약을 종료시키는 행위를 말합니다.
          <br />
          9. 관리기관 : 아이컨택을 구축·관리·운영하는 문화재단을 말합니다.
          <br />
          10. 사용기관 : 재단지원사업의 지원신청·수혜 실적 관리 행정업무 처리를 위하여 아이컨택을 사용하는 문화재단을 포함, 문화재단과 아이컨택 사용에 관한 업무협약(MOU)을
          체결한 기관을 말합니다.
          <br />
          <br />
          제4조 (약관 외 준칙)
          <br />

          본 약관에 명시되지 않은 사항은 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래 기본법」, 「전자서명법」, 「지식재산 기본법」, 「저작권법」, 「정보통신망 이용촉진 및 정보보호 등에
          관한 법률」, 「개인정보 보호법」 등 대한민국 관련법령과 상관습 규정에 의합니다.
          <br />


          제2장 이용계약의 체결
          <br />

          제5조 (이용계약의 성립)
          <br />

          1. 이용계약은 이용자가 본 이용약관 내용에 대한 동의와 이용신청에 대하여 사용기관의 이용승낙으로 성립합니다.
          <br />
          2. 본 이용약관에 대한 동의와 이용신청은 약관동의 절차에 "동의합니다"를 선택하고 "회원가입" 단추를 누름으로써 의사 표시를 합니다.
          <br />

          제6조 (회원가입 및 탈퇴(해지))
          <br />

          1. 회원가입은 이용자가 웹사이트를 통해 아이컨택에서 제공하는 소정의 가입신청 양식에서 요구하는 사항을 기록하여 가입을 완료하는 것으로 성립됩니다.
          <br />

          2. E-mail을 도용하여 아이컨택 서비스를 이용할 경우에는 관련 법령에 의거 처벌 받을 수 있습니다.
          <br />
          3. 회원가입 이후 아이컨택에서 제공하는 서비스를 제공받을 의사가 없을 경우에는 언제든지 회원탈퇴(해지)를 할 수 있습니다.
          <br />
          4. 사용기관은 다음 각 호에 해당하는 회원가입에 대하여는 가입을 취소 할 수 있습니다.
          <br />
          1) 다른 사람(단체)의 명의를 사용하여 신청하였을 때
          <br />
          2) 회원가입 신청서의 내용을 허위로 기재하였거나 신청하였을 때
          <br />
          3) 사회의 안녕 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때
          <br />
          4) 다른 사람(단체)의 아이컨택 서비스 이용을 방해하거나 그 정보를 도용하는 등의 행위를 하였을 때
          <br />
          5) 아이컨택을 이용하여 법령과 본 약관이 금지하는 행위를 하는 경우
          <br />
          6). 기타 아이컨택이 정한 회원가입 요건이 미비 되었을 때
          <br />
          5. 사용기관은 다음 각 항에 해당하는 경우 그 사유가 해소될 때까지 회원가입을 유보할 수 있습니다.
          <br />
          1) 서비스 관련 제반 용량이 부족한 경우
          <br />
          2) 기술상 장애 사유가 있는 경우
          <br />
          {' '}
          <br />
          제7조 (이용자E-mail 부여 및 변경 등)
          <br />
          1. 사용기관은 이용고객에 대하여 약관에 정하는 바에 따라 이용자E-mail를 아이컨택을 통하여 부여합니다.
          <br />
          2. 이용자E-mail은 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경 하고자 하는 경우에는 해당E-mail을 해지하고 재가입해야 합니다.
          <br />
          3. 아이컨택의 이용자E-mail은 이용자 본인의 동의하에 ‘사용기관’, 정부부처, 그 소속·산하 기관 등의 정보제공 사이트 이용자E-mail와 연결될 수 있습니다.

          4. 이용자E-mail은 다음 각 호에 해당하는 경우에는 이용자 또는 사용기관의 요청으로 변경할 수 있습니다.
          <br />
          1) 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우
          <br />
          2) 기타 합리적인 사유가 있는 경우
          <br />
          5. 서비스 이용자E-mail 및 비밀번호의 관리책임은 이용자에게 있습니다. 이를 소홀히 관리하여 발생하는 서비스 이용 상의 손해 또는 제 3자에 의한 부정이용 등에 대한 책임은 이용자에게
          있으며 사용기관은 그에 대한 책임을 일절 지지 않습니다.
          <br />
          6. 이용자는 등록사항에 변경이 있는 경우, 즉시 개인(단체)정보 수정 등 기타 방법으로 사용기관에 그 변경사항을 알려야 합니다.
          <br />
          7. 기타 이용자 개인정보 관리 및 변경 등에 관한 사항은 사용기관이 정하는 바에 의합니다.
          <br />
          {' '}
          <br />
          제8조 (회원 정보 수집·사용·관리·보호에 대한 동의)
          {' '}
          <br />

          1. 이용자의 회원 정보는 「개인정보 보호법」 및 아이컨택에 관한 사용기관의 「개인정보 처리방침」에 의해 보호됩니다.
          {' '}
          <br />

          2. 아이컨택의 회원 정보는 다음과 같이 수집·사용·관리·보호됩니다.
          {' '}
          <br />

          1) 회원 정보의 수집 : 아이컨택은 이용자의 아이컨택 서비스 가입 시 이용자가 제공하는 정보와 지원금의 지원신청·지원수혜 실적을 통하여 이용자의 회원 정보를
          수집합니다.
          {' '}
          <br />

          2) 회원 정보의 사용 : (1) 사용기관은 아이컨택의 서비스 제공과 관련해서 수집된 이용자의 회원 정보 등에 대하여 ‘관리기관’, 업무협약을 체결한 다수의 ‘사용기관’과
          아이컨택의 운영을 위하여 함께 공유 사용하며, 이용자의 승낙 없이 제3자(단, 아이컨택의 운영을 위하여 업무협약 (MOU)을 체결한 ‘사용기관’은 제3자가 아닌 계약 당사자로
          본다)에게 누설, 배포하지 않습니다.
          {' '}
          <br />
          {' '}
          다만 다음과 같은 경우에 법이 허용하는 범위 내에서 회원 정보를 제3자에게 제공할 수 있습니다. (2) 사용기관은 ‘사용기관’별 지원신청·지원수혜 실적
          등이 있는 경우에 한하여 ‘관리기관’, 업무협약을 체결한 다수의 ‘사용기관’ 소유의 홈페이지 회원가입, 소식지(뉴스레터) 발송, 지원신청·지원수혜 통계 생성·관리·공유 등 관련업무 처리를
          목적으로 ‘사용기관’별 이용자의 회원 정보를 ‘관리기관’, 업무협약을 체결한 다수의 ‘사용기관’에 제공 합니다.
          {' '}
          <br />

          3) 회원 정보의 관리 : 이용자는 개인(단체)정보의 보호 및 관리를 위하여 아이컨택 서비스의 개인(단체)정보관리에서 수시로 이용자 본인의 개인(단체)정보를 수정/삭제할 수 있습니다.
          수신되는 정보 중 불필요하다고 생각되는 부분도 변경/조정할 수 있습니다.
          <br />
          4) 회원 정보의 보호 : 이용자의 개인(단체)정보는 오직 이용자 본인만이 열람/수정/삭제 할 수 있으며, 이는 전적으로 이용자의 E-mail와 비밀번호에 의해 관리되고 있습니다. 따라서
          타인에게 이용자 본인의 E-mail와 비밀번호를 알려주어서는 아니 되며, 작업 종료 시에는 반드시 로그아웃 하고, 웹 브라우저의 창을 닫아야 합니다.(이는 타인과 컴퓨터를 공유하는 인터넷
          카페나 도서관 같은 공공장소에서 컴퓨터를 사용하는 경우에 이용자 본인의 정보보호를 위하여 필요한 사항입니다)
          <br />
          3. 이용자가 아이컨택의 본 약관에 따라 이용신청을 하는 것은 사용기관이 본 약관에 따라 신청서에 기재된 회원 정보를 수집·사용·관리· 보호하는 것에 동의하는 것으로 간주됩니다.
          <br />
          {' '}
          <br />
          제9조 (이용자의 정보 보안)
          {' '}
          <br />

          1. 이용자가 아이컨택의 회원 가입 절차를 완료하는 순간부터 이용자는 입력한 정보를 선량한 관리자로서의 주의 의무를 다하여 관리하여야 합니다. 이용자가 본인의 E-mail와 비밀번호를
          소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 이용자에게 책임이 있습니다.
          <br />
          2. E-mail와 비밀번호에 관한 모든 관리의 책임은 이용자 본인에게 있으며, 이용자의 E-mail나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 사용기관에 신고하여야
          합니다. 신고를 하지 않음으로 인해 발생하는 모든 책임은 이용자 본인에게 있습니다.
          <br />
          3) 회원 정보의 관리 :

          이용자는 개인(단체)정보의 보호 및 관리를 위하여 아이컨택 서비스의 개인(단체)정보관리에서 수시로 이용자 본인의 개인(단체)정보를 수정/삭제할 수 있습니다. 수신되는 정보 중
          불필요하다고 생각되는 부분도 변경/조정할 수 있습니다.
          <br />
          3. 이용자는 아이컨택 서비스의 사용 종료 시마다 정확히 로그아웃(Log-out)해야 하며, 로그아웃 하지 아니하여 제3자가 이용자에 관한 정보를 도용하는 등 이용자의 귀책사유로
          인하여 노출된 E-mail, 비밀번호, 회원정보 등 모든 정보에 대해서 일체의 책임을 지지 않습니다.
          <br />
          4. 사용기관은 관계 법령이 정하는 바에 따라 이용자의 회원정보를 보호하기 위해 노력합니다. 이용자의 회원정보 보호 및 사용에 대하여는 관계법령 및 아이컨택에 대한 사용기관의
          개인정보취급방침에 의합니다.
          <br />
          5. 아이컨택에서 단순히 링크된 제3자 제공의 서비스에 대하여는 아이컨택에 고지된 개인정보취급방침이 적용되지 않습니다.
          <br />
          <br />
          제3장 서비스의 제공 및 이용
          <br />

          제10조 (서비스 이용시간)
          <br />

          1. 서비스 이용시간은 관리기관의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
          <br />
          2. 제 1항의 이용시간 중 정기점검 등의 필요로 인하여 관리기관이 정한 날 또는 시간은 예외로 합니다.
          <br />
          <br />
          제11조 (서비스의 제공 및 변경 )
          <br />

          1. 아이컨택이 제공하는 서비스는 아래와 같으며, 그 변경될 서비스의 내용을 이용자에게 공지하고 아래에서 정한 서비스를 변경하여 제공할 수 있습니다.
          <br />

          1) 문화재단이 자체 개발·운영하거나 ‘사용기관’ 등 다른 기관과의 협의 등을 통해 제공하는 일체의 서비스
          <br />
          <br />
          제12조 (서비스의 중지 및 중지에 대한 공지)
          <br />

          1. 사용기관은 사전 예측이 불가능한 국가 비상사태, 정전, 타인(PC통신회사, 기간통신사업자 등)의 고의·과실로 인한 시스템 중단 등의 경우, 서비스의 전부 또는 일부를 제한하거나 중지할
          수 있습니다. 이 경우, 사전 공지가 불가능하며 사후 아이컨택 웹사이트에 서비스 중지사유 및 일시를 공지합니다.
          <br />
          2. 아이컨택의 서버 및 설비 점검 등의 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만 이 경우 서비스 중지 최소 7일 전 아이컨택 웹사이트에 서비스
          중지사유 및 일시를 공지한 후 서비스를 제한·중지할 수 있으며, 이 기간 동안 이용자가 공지내용을 인지하지 못한 데 대하여 관리기관은 책임을 부담 하지 아니합니다.
          <br />
          3. 관리기관의 사정으로 서비스를 영구적으로 중단하여야 할 경우에는 제2항에 의거합니다. 다만, 이 경우 사전 공지기간은 90일로 합니다.
          <br />
          4. 관리기관은 서비스를 특정범위로 분할하여 각 범위별로 이용가능시간을 별도로 지정할 수 있습니다. 다만 이 경우 그 내용을 공지합니다.
          <br />
          <br />
          제13조 (정보 제공 및 홍보물 게재)
          <br />
          1. 사용기관은 서비스를 운영함에 있어서 각종 정보를 홈페이지에 게재하는 방법, 전자우편이나 서신우편 발송 등으로 이용자에게 제공할 수 있습니다.
          <br />
          2. 사용기관은 서비스에 적절하다고 판단되거나 공익성이 있는 홍보물을 게재할 수 있습니다.
          <br />
          <br />
          제14조 (아이컨택 게시물의 관리·운영)
          <br />
          1. 이용자가 게시한 게시물의 내용에 대한 권리는 이용자에게 있습니다.
          <br />
          2. 사용기관은 이용자가 게시한 게시물, 게시내용에 대하여 다음의 경우에 해당된다고 판단되는 경우, 사전 통지 없이 이를 삭제하거나 이동 또는 등록을 거부할 수 있습니다.
          <br />

          1) 본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우
          <br />
          2) 본인 이외 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
          <br />
          3) 공공질서 및 미풍양속에 위반되는 내용인 경우
          <br />
          4) 범죄적 행위에 결부된다고 인정되는 내용일 경우
          <br />
          5) 본인 이외 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
          <br />
          6) 기타 관계 법령에 위배되는 경우
          <br />
          3. 이용자의 게시물이 타인의 저작권을 침해함으로써 발생하는 민·형사상의 책임은 전적으로 이용자 본인이 부담하여야 합니다.
          <br />
          <br />
          제15조 (서비스 이용제한)
          <br />

          1. 이용자가 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가 있다고 의심할 만한 합리적인 사유가 발생할 경우 사용기관은 이용자의 본 서비스 사용을 일부 또는 전부 중지할 수
          있으며, 이로 인해 발생하는 불이익에 대해 책임을 부담하지 아니합니다.
          <br />
          2. 사용기관은 이용자 본인이 본 약관 제 17 조(회원의 의무)등 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지 하거나 위 이용자의 접속을 금지할 수
          있습니다.
          <br />
          <br />

          제4장 계약 당사자의 의무와 책임
          <br />

          제16조 (사용기관의 의무)
          <br />

          1. 사용기관은 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 지속적·안정적으로 서비스를 제공하기 위해 노력할 의무가 있습니다.
          <br />
          2. 사용기관은 이용자의 회원 정보를 본인의 승낙 없이 타인에게 누설, 배포하지 않습니다. 다만, 「개인정보 보호법」 제18조(개인정보의 이용·제공 제한) 등 관계법령에 의하여 관계 국가기관
          등의 요구가 있는 경우에는 그러하지 아니합니다.
          {' '}
          <br />

          3. 문화재단은 이용자가 안전하게 아이컨택 서비스를 이용할 수 있도록 이용자의 회원 정보 보호를 위한 보안시스템을 갖추어 시스템을 안전하게 운영하도록 노력합니다.
          <br />

          4. 사용기관은 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.
          <br />
          <br />

          제17조 (회원의 의무)
          <br />

          1. 회원 가입 시에 요구되는 정보는 정확하게 기입하여야 합니다. 또한 이미 제공된 이용자에 대한 정보가 정확한 정보가 되도록 유지, 갱신하여야 하며, 회원은 자신의 E-mail 및
          비밀번호를 제3자가 이용하게 해서는 안 됩니다.
          <br />

          2. 회원은 문화재단의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.
          <br />
          3. 회원은 아이컨택의 서비스를 이용하여 얻은 정보를 문화재단의 사전승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 기타의 방법으로 사용 하거나 이를 타인에게 제공할 수
          없습니다.
          {' '}
          <br />

          4. 회원은 아이컨택의 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안 됩니다.
          <br />
          1) 다른 회원의 비밀번호와 E-mail를 도용하여 부정 사용하는 행위
          <br />
          2) 저속, 음란, 모욕적, 위협적이거나 타인의 Privacy를 침해할 수 있는 내용을 전송, 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위
          <br />
          3) 서비스를 통하여 전송된 내용의 출처를 위장하는 행위
          <br />
          4) 법률, 계약에 의해 이용할 수 없는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위
          <br />
          5) 타인의 특허, 상표, 영업비밀, 저작권, 기타 지식재산권을 침해하거나 그 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위
          <br />
          6) 사용기관의 승인을 받지 아니한 광고, 판촉물, 스팸메일, 행운의 편지, 피라미드 조직 및 기타 다른 형태의 권유를 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위
          <br />

          7) 다른 이용자의 개인(단체)정보를 수집 또는 저장하는 행위
          <br />
          8) 범죄행위를 목적으로 하거나 기타 범법행위와 관련된 행위
          <br />
          9) 선량한 풍속, 기타 사회질서를 해하는 행위
          <br />
          10) 타인의 명예를 훼손하거나 모욕하는 행위
          <br />
          11) 해킹행위 또는 컴퓨터 바이러스의 유포행위
          <br />
          12) 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위
          <br />
          13) 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위
          <br />

          14) 아이컨택에 게시된 정보를 변경하는 행위
          <br />

          15) 기타 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제44조의 7(불법정보의 유통금지 등)과 관계법령 등에 위배되는 행위
          <br />
          <br />

          제5장 손해배상 및 기타사항
          <br />

          제18조 (아이컨택의 소유권)
          <br />

          1. 아이컨택이 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 문화재단에 그 소유권이 있습니다.
          단, ‘사용기관’의 로고, 사업공고 등은 사용기관에 있습니다.
          <br />

          2. 이용자는 문화재단 등이 명시적으로 승인한 경우를 제외하고는 전항의 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 재라이센스, 담보권 설정 행위,
          상업적 이용 행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락하지 않습니다.
          <br />
          <br />

          제19조 (양도)
          <br />

          회원이 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여하고자 할 경우에는 ‘사용기관’에게 사전에 서면으로 통보하여야 하며, ‘사용기관’은 이를 면밀히 검토하여
          아이컨택의 운영에 있어서 오류·장애를 발생시키는 사유가 되지 않는다고 판단될 경우 이에 대한 승인 통보와 시스템을 이용가능 하도록 조치합니다. 단, E-mail 및 비밀번호는 변경을
          요할 수 있습니다.
          {' '}
          <br />

          <br />

          제20조 (손해배상)
          <br />

          1. 사용기관은 고의 또는 중과실로 회원에게 손해를 끼친 경우, 손해에 대하여 배상할 책임이 있습니다.
          <br />

          2. 회원이 본 약관을 위반하여 사용기관에 손해를 끼친 경우, 회원은 아이컨택의 해당 손해에 대한 배상할 책임이 있습니다.
          <br />

          3. 사용기관은 무료로 제공되는 서비스와 관련하여 사용기관의 고의 또는 중대한 과실이 없는 한 사용기관은 손해배상을 하지 않습니다.
          <br />
          <br />
          제21조 (면책조항)

          1. 문화재단은 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
          <br />
          2. 문화재단은 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에 대하여 문화재단의 고의 또는 중대한 과실이 없는 한 책임이
          면제됩니다.
          <br />

          3. 문화재단은 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서 문화재단의 고의 또는 중대한 과실이 없는 한
          책임이 면제 됩니다.
          <br />

          4. 문화재단은 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 이용자가 회원 정보 및 전자우편 주소 등을 부실하게 기재하거나 미기재하여 손해가 발생한 경우에 대해서 문화재단의 고의
          또는 중대한 과실이 없는 한 책임이 면제 됩니다.
          <br />

          5. 문화재단은 서비스에 표출된 어떠한 의견이나 정보에 대해 확신이나 대표할 의무가 없으며 회원이나 제3자에 의해 표출된 의견을 승인하거나 반대하거나 수정하지 않습니다. 아이컨택은
          이용자 또는 제3자가 웹사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서는 문화재단의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
          <br />

          6. 문화재단은 회원 간 또는 회원과 제3자간에 서비스를 매개로 하여 물품거래 혹은 금전적 거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는
          이익에 관하여 문화재단의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
          <br />

          7. 문화재단은 이용자가 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않으며, 회원이 본 서비스에 게재한 정보, 자료, 사실의 신뢰도 등
          내용에 관하여 문화재단의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
          <br />

          8. 문화재단은 서비스 이용과 관련하여 이용자에게 발생한 손해 중 이용자의 고의, 과실에 의한 손해에 대하여 책임을 부담하지 아니합니다.
          <br />
          9. 문화재단은 아이컨택이 제공한 서비스가 아닌 가입자 또는 기타 사용기관 또는 유관기관이 제공하는 서비스의 내용상의 정확성, 완전성 및 질에 대하여 보장하지 않습니다. 따라서
          문화재단은 이용자가 위 내용을 이용함으로 인하여 입게 된 모든 종류의 손실이나 손해에 대하여 책임을 부담하지 아니합니다. 또한 문화재단은 이용자가 서비스를 이용하며 타 이용자로 인해 입게
          되는 정신적 피해에 대하여 보상할 책임을 지지 않으며 문화재단의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
          <br />
          <br />
          제22조 (관할법원)
          <br />

          1. 본 서비스 이용과 관련하여 발생한 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의
          주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
          <br />
          2. 문화재단 또는 사용기관과 이용자 간에 제기된 소송에는 대한민국법을 적용합니다.
          <br />
          부 칙
          <br />
          1. (시행일) 본 약관은 2020년 4월 1일부터 시행됩니다.
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>

    </Container>
  );
};


export default ServiceTerm;
