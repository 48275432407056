import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Firebase from "../../Context/Firebase";
import * as Functions from "../../Utill/Functions";

const useStyles = makeStyles({
  wrap: {
    overflow: "auto",
    width: "100%",
    height: "100%",
    backgroundColor: "#151515bd",
    position: "fixed",
    left: "0",
    top: "0",
    zIndex: "99999999999",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  bannerWrap: {
    maxWidth: "800px",
    maxHeight: "800px",
    width: "100%",
    height: "100%",
  },
  bannerControlBtnWrap: {
    display: "flex",
    backgroundColor: "#fff",
    color: "#000",
    flexDirection: "row-reverse",
  },
  oneDayCloseDiv: {
    textAlign: "center",
    width: "30%",
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#bd2a2a",
      color: "#fff",
      fontWeight: "bold",
    },
  },
  closeBtnDiv: {
    textAlign: "center",
    padding: "10px",
    width: "30%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#bd2a2a",
      color: "#fff",
      fontWeight: "bold",
    },
  },
});

const Banner = () => {
  const classes = useStyles();
  const firebase = useContext(Firebase);
  const [isBannerActive, setIsBannerActive] = useState(false);
  const [displayBannerList, setDisplayBannerList] = useState([]);
  useEffect(() => {
    if (typeof document !== "undefined" && document.cookie) {
      const isMainPopUp = Functions.getCookie("mainPopUp");
      if (!isMainPopUp) {
        setIsBannerActive(false);
      }
    } else {
      const today = firebase.firestore.Timestamp.fromDate(new Date());
      firebase
        .firestore()
        .collection("Banners")
        .where("bannerEndDate", ">=", today)
        .get()
        .then((result1) => {
          const resultBannerEndDate = [];
          result1.forEach((doc) => {
            resultBannerEndDate.push(doc.data());
          });

          const todayFirstTime = new Date(
            Functions.printDateDash(new Date())
          ).getTime();
          const onDisplayBannerList = resultBannerEndDate.map((item) => {
            const startDate = new Date(
              Functions.printDateDash(item.bannerStartDate.toDate())
            ).getTime();
            const endDate = new Date(
              Functions.printDateDash(item.bannerEndDate.toDate())
            ).getTime();
            if (startDate <= todayFirstTime && endDate >= todayFirstTime) {
              return item;
            }
          });
          if (onDisplayBannerList.length) {
            setIsBannerActive(true);
            setDisplayBannerList(onDisplayBannerList);
          }
        });
    }
  }, []);
  const oneDayClose = () => {
    Functions.setCookie("mainPopUp", true, 1);
    setDisplayBannerList(null);
    setIsBannerActive(false);
  };
  const closeBanner = () => {
    setDisplayBannerList(null);
    setIsBannerActive(false);
  };
  return isBannerActive ? (
    <div className={classes.wrap}>
      <div className={classes.bannerWrap}>
        {displayBannerList &&
          displayBannerList.map((item) =>
            item.bannerLink ? (
              <div key={item.id}>
                <a href={item.bannerLink} target="_new">
                  <img
                    src={item.BannerImgUrl}
                    style={{ width: "100%" }}
                    alt={item.bannerImgName}
                  />
                </a>
              </div>
            ) : (
              <div key={item.id}>
                <img
                  src={item.BannerImgUrl}
                  style={{ width: "100%" }}
                  alt={item.bannerImgName}
                />
              </div>
            )
          )}
        <div className={classes.bannerControlBtnWrap}>
          <div className={classes.closeBtnDiv} onClick={() => closeBanner()}>
            닫기{" "}
          </div>
          <div className={classes.oneDayCloseDiv} onClick={() => oneDayClose()}>
            하루동안 보지않기
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Banner;
