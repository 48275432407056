import React from "react";
import PopupBanner from "../ConsultantList/Banner";
import Banner from "./Banner";
import Search from "./Search";
import Content from "./Content";

const Index = () => (
  <>
    <PopupBanner />
    <Banner />
    <Search />
    <Content />
  </>
);

export default Index;
