import React from 'react';

const Main = () => (
  <div>
    Main
    Main
  </div>
);


export default Main;
