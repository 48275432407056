import axios from 'axios';
import * as siteConfig from '../Constants/SiteConfig';
import * as firebase from 'firebase/app';

// export const sendEmail = () => {
//   console.log('called sendEmail');
// };
// export const sendSms = () => {
//   console.log('called sendSms');
// };


export const sendEmail = async (CategoryNum, values) => {
  let data;
  const commonTargetEmails = [values.consultantEmail, values.artistEmail];
  const allTargetEmails = [values.consultantEmail, values.artistEmail, siteConfig.adminEmailAddress];

  switch (CategoryNum) {
    case 1:
      data = {
        targetEmail: values.email,
        subject: '[부산문화재단] 아이컨택 회원가입을 축하드립니다.',
        html: `<img src='${siteConfig.sendEmailImgURL[CategoryNum]}' /> <br/> 위 메일은 발신전용메일입니다. 서비스와 관련된 문의는 아이컨택 홈페이지 > 로그인 > 문의내역을 이용해주세요.  <a href="https://www.icontact.kr"> 아이컨택 바로가기 </a>`,
      };
      break;
    case 2:
      data = {
        targetEmail: commonTargetEmails,
        subject: `[부산문화재단] 예술인 ${values.artistName}님이 컨설턴트 ${values.consultantName}님께 컨설팅요청을 하였습니다.`,
        html: `<img src='${siteConfig.sendEmailImgURL[CategoryNum]}'/><br/> 위 메일은 발신전용메일입니다. 서비스와 관련된 문의는 아이컨택 홈페이지 > 로그인 > 문의내역을 이용해주세요.  <a href="https://www.icontact.kr"> 아이컨택 바로가기 </a>`,
      };
      break;

    case 3:
      data = {
        targetEmail: commonTargetEmails,
        subject: `[부산문화재단] 예술인 ${values.artistName}님이 컨설턴트 ${values.consultantName}님께 한 컨설팅요청을 취소하였습니다.`,
        html: `<img src='${siteConfig.sendEmailImgURL[CategoryNum]}'/> <br/> 위 메일은 발신전용메일입니다. 서비스와 관련된 문의는 아이컨택 홈페이지 > 로그인 > 문의내역을 이용해주세요. <br/> <a href="https://www.icontact.kr"> 아이컨택 바로가기 </a> `,
      };
      break;

    case 4:
      data = {
        targetEmail: commonTargetEmails,
        subject: `[부산문화재단] 컨설턴트 ${values.consultantName}님께서 예술인 ${values.artistName}님의 컨설팅요청을 거절하였습니다.`,
        html: `<img src='${siteConfig.sendEmailImgURL[CategoryNum]}' /> <br/> 위 메일은 발신전용메일입니다. 서비스와 관련된 문의는 아이컨택 홈페이지 > 로그인 > 문의내역을 이용해주세요. <br/> <a href="https://www.icontact.kr"> 아이컨택 바로가기 </a>`,
      };
      break;

    case 5:
      data = {
        targetEmail: allTargetEmails,
        subject: `[부산문화재단] 컨설턴트 ${values.consultantName}님께서 예술인 ${values.artistName}님의 컨설팅요청 수락하였습니다.`,
        html: `<img src='${siteConfig.sendEmailImgURL[CategoryNum]}' /><br/> 위 메일은 발신전용메일입니다. 서비스와 관련된 문의는 아이컨택 홈페이지 > 로그인 > 문의내역을 이용해주세요. <br/> <a href="https://www.icontact.kr"> 아이컨택 바로가기 </a>`,
      };
      break;

    case 6:
      data = {
        targetEmail: allTargetEmails,
        subject: `[부산문화재단] 컨설턴트 ${values.consultantName}님과 예술인 ${values.artistName}님의 컨설팅이 중단되었습니다.`,
        html: `<img src='${siteConfig.sendEmailImgURL[CategoryNum]}' /> <br/> 위 메일은 발신전용메일입니다. 서비스와 관련된 문의는 아이컨택 홈페이지 > 로그인 > 문의내역을 이용해주세요.<br/>  <a href="https://www.icontact.kr"> 아이컨택 바로가기 </a>`,
      };
      break;

    case 7:
      data = {
        targetEmail: allTargetEmails,
        subject: `[부산문화재단] 컨설턴트 ${values.consultantName}님과 예술인 ${values.artistName}님의 컨설팅이 완료되었습니다.`,
        html: `<img src='${siteConfig.sendEmailImgURL[CategoryNum]}' /> <br/> 위 메일은 발신전용메일입니다. 서비스와 관련된 문의는 아이컨택 홈페이지 > 로그인 > 문의내역을 이용해주세요.<br/>  <a href="https://www.icontact.kr"> 아이컨택 바로가기 </a>`,
      };
      break;

    case 8:
      data = {
        targetEmail: allTargetEmails,
        subject: `[부산문화재단] 컨설턴트 ${values.consultantName}님과 예술인 ${values.artistName}님의 컨설팅이 최종완료되었습니다.`,
        html: `<img src='${siteConfig.sendEmailImgURL[CategoryNum]}' /> <br/> 위 메일은 발신전용메일입니다. 서비스와 관련된 문의는 아이컨택 홈페이지 > 로그인 > 문의내역을 이용해주세요.<br/>  <a href="https://www.icontact.kr"> 아이컨택 바로가기 </a>`,
      };
      break;

    default:
      return false;
  }

  await axios({
    url: `${siteConfig.functionUrl}/sendMail`,
    method: 'post',
    data: {
      data,
    },
  });
};


export const sendSms = (CategoryNum, values) => {
  let data;
  switch (CategoryNum) {
    case 1:
      data = {
        phoneNum: [values.artistPhone],
        title: '부산문화재단 아이컨택 알림',
        content: `${values.artistName}님이 요청하신 컨설팅을 ${values.consultantName}님이 수락하셨습니다.\n담당 컨설턴트에게 연락하셔서 컨설팅 일정을 잡아주세요.\n${values.consultantName}님 연락처 ${values.consultantPhone} `,
      };
      break;
    case 2:
      data = {
        phoneNum: [values.artistPhone],
        title: '부산문화재단 아이컨택 알림',
        content: `${values.artistName}님과 ${values.consultantName}님의 컨설팅이 완료되었습니다.\n컨설팅 설문조사를 진행해주세요.\n${siteConfig.siteDomain}/survey/${values.consultingId}  `,
      };
      break;
    case 3:
      data = {
        phoneNum: [values.consultantPhone],
        title: '부산문화재단 아이컨택 알림',
        content: `${values.artistName}님이 ${values.consultantName}님에게 컨설팅요청을 하였습니다.\n마이페이지 > 나의컨설팅에서 확인해주세요.\n${siteConfig.siteDomain}/login`,
      };
      break;

    case 4:
      data = {
        phoneNum: [siteConfig.adminPhoneNumber],
        title: '부산문화재단 아이컨택 알림',
        content: `컨설턴트 ${values.consultantName}님이 결과보고서를 재업로드하였습니다.(담당 예술인명 ${values.artistName})`,
      };
      break;
    case 5:
      data = {
        phoneNum: [values.consultantPhone],
        title: '부산문화재단 아이컨택 알림',
        content: `[ 컨설팅 결과보고서 수정요청 ] ${values.consultantName}님! ${values.requestMessage}`,
      };
      break;


    default:
      return false;
  }

  axios({
    url: `${siteConfig.functionUrl}/sendSms`,
    method: 'post',
    data: {
      data,
    },
  });
};
