import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField } from 'formik-material-ui';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as Yup from 'yup';
import { Field, Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';
import * as SiteConfig from '../../Constants/SiteConfig';
import * as Routes from '../../Constants/Routes';
import FirebaseContext from '../../Context/Firebase';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';
import SubLink from './SubLink';
import JoinPageLink from './JoinPageLink';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: SiteConfig.mainColor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('이메일 형식으로 입력해주세요')
      .required('이메일을 입력해주세요.'),
    password: Yup.string()
      .required('비밀번호를 입력해주세요'),
  });

  const onSubmit = (values) => {
    setIsLoading(true);
    const { email, password } = values;

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase.auth().signInWithEmailAndPassword(email, password)
          .then(() => {
            firebase.firestore().collection('UsersCollection')
              .doc(email)
              .update({
                latestLoginDate: new Date(),
              });
            history.push(Routes.INDEX);
          })
          .catch((e) => {
            console.log('loginError', e);
            setIsLoading(false);
            window.alert('일치하는 계정이 없거나 비밀번호가 유효하지 않습니다.');
          });
      })
      .catch((error) => {
        console.log('loginErrorCode', error.code);
        console.log('loginErrorMessage', error.message);
      });
  };

  return (
    <Box style={{ minHeight: '100vh', height: '100%' }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <FingerprintIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>

          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form className={classes.form}>
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="이메일을 입력해주세요"
                  name="email"
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="비밀번호를 입력해주세요"
                  type="password"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size="large"
                >

                  로그인
                </Button>
              </Form>
            )}
          </Formik>
          <SubLink
            viewComponent={props.viewComponent}
            setViewComponent={props.setViewComponent}
          />
        </div>
        <JoinPageLink />
        <LoadingOverlay
          isLoading={isLoading}
        />
      </Container>
    </Box>
  );
};


export default Login;
