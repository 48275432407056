import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import ImageUploader from "react-images-upload";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { MenuItem } from "@material-ui/core";
import { Field, Formik, Form } from "formik";
import imageCompression from "browser-image-compression";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import * as Yup from "yup";
import Box from "@material-ui/core/Box";
import * as Routes from "../../Constants/Routes";
import FirebaseContext from "../../Context/Firebase";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import * as SelectItems from "../ConsultantJoin/SelectItem";
import * as Functions from "../../Utill/Functions";
import WithdrawalModal from "./WithdrawalModal";
import * as SiteConfig from "../../Constants/SiteConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: SiteConfig.mainColor,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

const validationSchema = Yup.object().shape({
  email: Yup.string().email("이메일 형식으로 입력해주세요").required("이메일을 입력해주세요"),
  name: Yup.string().required("이름을 입력해주세요"),
  phone: Yup.number().typeError("연락처는 숫자만 입력해주세요.").required("연락처를 입력해주세요"),
  age: Yup.string()
    .required('연령대를 선택해주세요')
    .oneOf(['10대', '20대', '30대', '40대', '50대', '60대', '70대이상'], '연령대를 선택해주세요'),
  briefHistory1: Yup.string().required("주요 경력을 한가지 입력해주세요."),
  briefHistory2: Yup.string().notRequired(),
  briefHistory3: Yup.string().notRequired(),
  profileContent: Yup.string().required("상세 프로필 내용을 입력해주세요"),
  consultingMainCatepory: Yup.string()
    .required("컨설팅 분야를 선택해주세요")
    .oneOf(["C1", "C2", "C3", "C4", "C5", "C6", "C7"], "컨설팅 분야를 선택해주세요"),
  consultingSubCatepory: Yup.string()
    .required("컨설팅 세부분야를 선택해주세요")
    .oneOf(
      [
        "CG1",
        "CG2",
        "CG3",
        "CG4",
        "CG5",
        "CG6",
        "CG7",
        "CG8",
        "CG9",
        "CG10",
        "CG11",
        "CG12",
        "CG13",
        "CG14",
        "CG15",
        "CG16",
        "CG17",
        "CG18",
        "CG19",
        "CG20",
        "CG21",
        "CG22",
      ],
      "컨설팅 세부분야를 선택해주세요"
    ),

  // consultingGenre: Yup.string()
  //   .required('컨설팅 분야(대분류)를 선택해주세요')
  //   .oneOf(['CG1', 'CG2', 'CG3', 'CG4', 'CG5', 'CG6', 'CG7', 'CG8', 'CG9', 'CG10', 'CG11', 'CG12'], '컨설팅 분야(대분류)를 선택해주세요'),
});

const ConsultantMembership = (props) => {
  const classes = useStyles();
  const [isModal, setIsModal] = useState(false);
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState([]);
  const history = useHistory();

  const openModal = () => {
    setIsModal(true);
  };
  const closeModal = () => {
    setIsModal(false);
  };

  const initialValues = {
    email: props.authUser.email,
    name: props.authUser.name,
    phone: props.authUser.phone,
    age: props.authUser.age,

    consultingMainCatepory: props.authUser.consultingMainCatepory ?? "",
    consultingSubCatepory: props.authUser.consultingSubCatepory ?? "",

    briefHistory1: props.authUser.briefHistory ? props.authUser.briefHistory[0] : "",
    briefHistory2: props.authUser.briefHistory ? props.authUser.briefHistory[1] : "",
    briefHistory3: props.authUser.briefHistory ? props.authUser.briefHistory[2] : "",
    profileContent: props.authUser.profileContent,
    // consultingGenre: props.authUser.consultingGenre,
    // C1: props.authUser.consultingArea.C1,
    // C2: props.authUser.consultingArea.C2,
    // C3: props.authUser.consultingArea.C3,
    // C4: props.authUser.consultingArea.C4,
    // C5: props.authUser.consultingArea.C5,
    // C6: props.authUser.consultingArea.C6,
    // C7: props.authUser.consultingArea.C7,
    // C8: props.authUser.consultingArea.C8,
    // C9: props.authUser.consultingArea.C9,
  };
  const onDropimg = (uploadImg) => {
    setImg(uploadImg[0]);
  };

  const uploadImg = async (values) => {
    if (img === undefined) {
      return false;
    }

    try {
      const compressedFile = await imageCompression(img, { maxWidthOrHeight: 640 });
      const randomKeys = Functions.RandomKey(8);
      const uploadTask = firebase.storage().ref(`images/${randomKeys}`).put(compressedFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.log(error);
        },
        () => {
          const profileImgName = uploadTask.snapshot.ref.name;
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            firebase
              .firestore()
              .collection("UsersCollection")
              .doc(values.email)
              .set({ profileImgUrl: downloadURL, profileImgName }, { merge: true });
            const deletePreProfileImgName = firebase.storage().ref(`images/${props.authUser.profileImgName}`);
            deletePreProfileImgName
              .delete()
              .then((r) => {
                console.log(r);
              })
              .catch((e) => {
                console.log(e);
              });
          });
        }
      );
    } catch (error) {
      console.log("imgUploadError", error);
    }
  };
  const onSubmit = (values) => {
    setIsLoading(true);
    const {

      email,
      phone,
      age,
      briefHistory1,
      briefHistory2,
      briefHistory3,
      profileContent,
      consultingMainCatepory,
      consultingSubCatepory,
    } = values;

    // const consultingAreaArray = [];
    // if (C1) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C1);
    // }
    // if (C2) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C2);
    // }
    // if (C3) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C3);
    // }
    // if (C4) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C4);
    // }
    // if (C5) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C5);
    // }
    // if (C6) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C6);
    // }
    // if (C7) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C7);
    // }
    // if (C8) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C8);
    // }
    // if (C9) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C9);
    // }

    const briefHistoryArr = [];
    briefHistoryArr.push(briefHistory1, briefHistory2, briefHistory3)
    const briefHistory = briefHistoryArr.filter(Boolean)

 console.log(briefHistory)
    firebase
      .firestore()
      .collection("UsersCollection")
      .doc(email)
      .update({
        phone: phone.replace(/ /gi, ""),
        age,
        consultingMainCatepory,
        consultingSubCatepory,
        // profileSummary,
        briefHistory,
        profileContent,
        // consultingGenre,
        // consultingAreaText: consultingAreaArray.join('. '),
        // consultingArea: {
        //   C1: values.C1,
        //   C2: values.C2,
        //   C3: values.C3,
        //   C4: values.C4,
        //   C5: values.C5,
        //   C6: values.C6,
        //   C7: values.C7,
        //   C8: values.C8,
        //   C9: values.C9,
        // },
      })
      .then(() => {
        uploadImg(values);
        window.alert("회원정보가 정상적으로 수정되었습니다.");
        setIsLoading(false);
        if (props.adminMode) {
          history.push(Routes.ADMIN);
        } else {
          history.push(Routes.INDEX);
        }
      })
      .catch((e) => {
        console.log("membershipUpdateError", e);
      });
  };

  return (
    <Box mb={10}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <HowToRegIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Consultant Membership
          </Typography>
          <Typography variant="body2">컨설턴트 회원정보수정 페이지</Typography>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {() => (
              <Form className={classes.form}>
                <Field
                  disabled
                  component={TextField}
                  variant="outlined"
                  name="email"
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth
                />
                <Field disabled component={TextField} variant="outlined" name="name" type="text" label="이름" margin="normal" fullWidth />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="phone"
                  type="text"
                  label="연락처"
                  margin="normal"
                  fullWidth
                />
                <Field disabled={false} component={TextField} select name="age" variant="outlined" margin="normal" label="연령대" fullWidth>
                  {SelectItems.ages.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>

                <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="consultingMainCatepory"
                  label="컨설팅 분야"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                >
                  {SelectItems.consultingMainCatepory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="consultingSubCatepory"
                  label="컨설팅 세부분야"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                >
                  {SelectItems.consultingSubCatepory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>

                {/* <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="consultingGenre"
                  label="컨설팅 분야(대분류)"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                >
                  {SelectItems.consultingGenre.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>

                <div className="consultantJoinCheckboxTitle">
                  컨설팅 분야(소분류/다중선택 가능)
                </div>
                <div>
                  {SelectItems.consultingArea.map((option) => (
                    <Field
                      key={option.value}
                      disabled={false}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name={option.value}
                      Label={{ label: option.label }}
                    />
                  ))}
                </div> */}

                {props.authUser.profileImgUrl && <img src={`${props.authUser.profileImgUrl}`} alt="profileIMG" style={{ width: "100%" }} />}

                <ImageUploader
                  label=""
                  withPreview
                  withIcon={false}
                  fileSizeError="파일 크기용량은 10MB 이하로 업로드 해주세요."
                  buttonText="프로필이미지 등록/수정하기"
                  onChange={onDropimg}
                  singleImage
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={10485760}
                />
                <Field
                  disabled={false}
                  component={TextField}
                  name="briefHistory1"
                  variant="outlined"
                  type="text"
                  label="주요 경력 1"
                  fullWidth
                  margin="normal"
                />
                <Field
                  disabled={false}
                  component={TextField}
                  name="briefHistory2"
                  variant="outlined"
                  type="text"
                  label="주요 경력 2 (선택) "
                  fullWidth
                  margin="normal"
                />
                <Field
                  disabled={false}
                  component={TextField}
                  name="briefHistory3"
                  variant="outlined"
                  type="text"
                  label="주요 경력 3 (선택) "
                  fullWidth
                  margin="normal"
                />
                <Field
                  disabled={false}
                  component={TextField}
                  name="profileContent"
                  variant="outlined"
                  type="text"
                  label="상세 프로필"
                  fullWidth
                  multiline
                  maxRows={50}
                  margin="normal"
                />

                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} size="large">
                  컨설턴트 회원정보 수정하기
                </Button>
              </Form>
            )}
          </Formik>
          <Button onClick={() => openModal()}>계정탈퇴</Button>
        </div>
        {isModal && <WithdrawalModal loginedUserInfo={props.authUser} isModal={isModal} closeModal={closeModal} />}
        <LoadingOverlay isLoading={isLoading} />
      </Container>
    </Box>
  );
};

export default ConsultantMembership;
