import React, { useContext, useEffect, useState } from "react";
import { container } from "../../assets/jss/material-kit-pro-react";
import { makeStyles } from "@material-ui/core/styles";
import mainBgImg from "../../assets/img/main_bg.svg";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import FirebaseContext from "../../Context/Firebase";
import RequestConsultingModal from "../ConsultantList/RequestConsultingModal";
import sampleImg from "../../assets/img/samplePeople.jpg";
import * as siteConfig from "../../Constants/SiteConfig";

const useStyles = makeStyles((theme) => ({
  container: {
    ...container,
    width: "100%",
    height: "997px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "717px",
      padding: 0,
    },
  },
  contentContainer: {
    height: "100%",
    padding: "100px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "37px 0 45px 0",
    },
  },
  bg: {
    backgroundImage: `url(${mainBgImg})`,
    backgroundPosition: "center",
    display: "flex",
  },
  title: {
    fontSize: "48px",
    fontWeight: "bold",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    lineHeight: "normal",
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },
  subTitle: {
    fontSize: "14px",
    color: "#E0E0E0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "65px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "32px",
    },
  },
  listContainer: {
    // display: "grid",
    // gridTemplateColumns: "repeat(3, 1fr)",
    // gap: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      position: "absolute",
      left: 0,
    },
  },
  listItem: {
    background: "#fff",
    borderRadius: "20px",
    height: "500px",
  },
  swiperMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  swiperDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  slideImgContainer: {
    height: "315px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
  },
  slideTxtContainer: {
    padding: "26px",
    height: "285px",
    [theme.breakpoints.down("sm")]: { height: "300px" },
  },
}));

const Content = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [initDate, setInitData] = useState([]);
  const firebase = useContext(FirebaseContext);

  SwiperCore.use([Navigation, Autoplay]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const unsubscribe = firebase
      .firestore()
      .collection("UsersCollection")
      .where("position", "==", "CONSULTANT")
      .where("isAccountActive", "==", true)
      .where("isConsultantOfTheMonth", "==", true)
      .onSnapshot((result) => {
        const resultData = [];
        result.forEach((doc) => {
          const docData = doc.data();
          resultData.push({
            ...docData,
            id: doc.id,
          });
        });
        const sortedData = resultData.sort(() => Math.random() - Math.random());
        setInitData(sortedData);
        setIsLoading(false);
      });

    return () => {
      unsubscribe();
    };
  }, [firebase]);

  useEffect(() => {
    if (initDate.length === 0) return;
    // console.log("@@@initDate", initDate);
  }, [initDate]);

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModal(true);
  };
  const closeModal = () => {
    setSelectedItem(null);
    setIsModal(true);
  };

  const slideComponent = (item, idx) => (
    <SwiperSlide className={classes.desktopSlide} key={item.id}>
      <div>
        <div
          style={{
            // minWidth: "342px",
            overflow: "hidden",
            borderRadius: 20,
            // border: "1px solid #E0E0E0",
            // marginBottom: 44,
            backgroundColor: "white",
          }}
          // onClick={() => openModal(item)}
        >
          <div className={classes.slideImgContainer}>
            <img
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
              src={item.profileImgUrl ? item.profileImgUrl : sampleImg}
              alt={`${item.name} 프로필 이미지`}
            />
          </div>
          <div className={classes.slideTxtContainer}>
            <div
              style={{
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "36px",
                letterSpacing: "-2px",
                marginBottom: "10px",
              }}
            >
              {item.name}
            </div>
            <div
              style={{
                height: "88px",
                // margin: "3px 0",
                overflow: "hidden",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#212121",
              }}
            >
              {item.briefHistory?.map((str) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "start",
                  }}
                >
                  <p style={{ width: "12px", margin: 0 }}>•</p>
                  <p
                    style={{
                      margin: "0",
                      marginBottom: 6,
                      wordBreak: "break-all",
                      width: "calc(100% - 12px)",
                    }}
                  >
                    {str}
                  </p>
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
                flexWrap: "wrap",
                margin: "10px 0px 30px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "29px",
                  padding: "10px 12px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  borderRadius: "100px",
                  backgroundColor: "#6FBA2C",
                  fontSize: 13,
                  fontWeight: 700,
                  lineHeight: 22,
                  color: "white",
                }}
              >
                {"# " +
                  siteConfig.consultingSubCatepory[item.consultingSubCatepory]}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                margin: "10px 0px",
                justifyContent: "right",
                fontSize: 13,
                fontWeight: 700,
                color: "#BDBDBD",
              }}
            >
              <p
                style={{
                  padding: "10px 5px",
                  fontSize: 13,
                  fontWeight: 700,
                  color: "#BDBDBD",
                  cursor: "pointer",
                }}
                onClick={() => openModal(item)}
              >
                {" "}
                {"바로가기 " + ">"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  );

  return (
    <div className={classes.bg}>
      <div className={classes.container}>
        {/* <img src={mainBgImg} alt="main_bg" style={{ width: "100%" }} /> */}
        <div className={classes.contentContainer}>
          <div className={classes.title}>
            <span>이달의 컨설턴트</span>
          </div>
          <div className={classes.subTitle}>
            <span>활발한 컨설팅 활동을 해주신&nbsp;</span>
            <span>이달의 컨설턴트를 소개합니다.</span>
          </div>
          <div className={classes.listContainer}>
            <Swiper
              className={`${classes.swiperDesktop} ${classes.swiper}`}
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={16}
              slidesPerView={3}
              // navigation
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              // pagination={{ clickable: true }}
              // scrollbar={{ draggable: true }}
              onSwiper={(swiper) => {}}
              onSlideChange={(e) => {}}
            >
              {!isLoading &&
                (initDate.length > 0 ? (
                  initDate.map((item, idx) => {
                    if (idx > 2) return;
                    return slideComponent(item, idx);
                  })
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      padding: "30px 0",
                    }}
                  >
                    등록된 컨설턴트가 없습니다.
                  </div>
                ))}
            </Swiper>
            <Swiper
              className={`${classes.swiperMobile} ${classes.swiper}`}
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={16}
              slidesPerView={1.2}
              centeredSlides={true}
              // navigation
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              // pagination={{ clickable: true }}
              // scrollbar={{ draggable: true }}
              onSwiper={(swiper) => {}}
              onSlideChange={(e) => {}}
            >
              {!isLoading &&
                (initDate.length > 0 ? (
                  initDate.map((item, idx) => {
                    if (idx > 2) return;
                    return slideComponent(item, idx);
                  })
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      padding: "30px 0",
                    }}
                  >
                    등록된 컨설턴트가 없습니다.
                  </div>
                ))}
            </Swiper>
          </div>
        </div>
      </div>

      {isModal && (
        <RequestConsultingModal
          selectedItem={selectedItem}
          isModal={isModal}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default Content;
