import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import Typography from "@material-ui/core/Typography";
import styles from "../../assets/jss/material-kit-react/views/componentsSections/javascriptStyles";
import Button from "../../Components/initComponents/CustomButtons/Button";
import Card from "../../Components/initComponents/Card/Card";
import CardBody from "../../Components/initComponents/Card/CardBody";
import Firebase from "../../Context/Firebase";
import featuresStyle from "../ConsultantList/featuresStyle";
import * as SendNotification from "../../Utill/SendNotification";

const useStyles = makeStyles({
  ...styles,
  modal: {
    ...styles.modal,
    width: "900px",
  },
  iconLocation: {
    position: "relative",
    top: "7px",
    marginRight: "7px",
  },
});
const featureStyles = makeStyles(featuresStyle);
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

Transition.displayName = "Transition";

const ReRequestConsutingReportModal = (props) => {
  const { isModal, closeModal, selectedItem } = props;
  const classes = useStyles();
  const featureClasses = featureStyles();
  const firebase = useContext(Firebase);

  const validationSchema = Yup.object().shape({
    reRequestConsultingReportMessage: Yup.string()
      .max(1000, "1000자 이내로 입력해주세요.")
      .required(" 컨설팅 보고서 재업로드 요청 메시지를 입력해주세요."),
  });

  const onSubmit = (value) => {
    firebase
      .firestore()
      .collection("ConsultingList")
      .doc(selectedItem.id)
      .set(
        {
          reRequestConsultingReportMessage:
            value.reRequestConsultingReportMessage,
          ReRequestUploadConsultingReportState: 1,
        },
        { merge: true }
      )
      .then(() => {
        const targetSendInfo = {
          consultantPhone: selectedItem.consultantPhone,
          consultantName: selectedItem.consultantName,
          requestMessage: value.reRequestConsultingReportMessage,
        };
        SendNotification.sendSms(5, targetSendInfo);
        closeModal();
      });
  };

  return (
    selectedItem && (
      <>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={isModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => closeModal()}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => closeModal()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
          </DialogTitle>

          <DialogContent
            id="classic-modal-slide-description"
            className={featureClasses.modalBody}
          >
            <Card profile className="boxShadowNone">
              <CardBody>
                <Typography variant="h6">
                  컨설팅 보고서 재업로드 요청메시지를 입력하시면 해당 컨설턴트의
                  휴대폰으로 문자가 발송됩니다.
                </Typography>
              </CardBody>
            </Card>

            <Formik
              initialValues={{
                reRequestConsultingReportMessage:
                  selectedItem.reRequestConsultingReportMessage || "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {() => (
                <Form>
                  <Field
                    disabled={false}
                    component={TextField}
                    name="reRequestConsultingReportMessage"
                    placeholder="컨설팅보고서 재업로드 요청메시지"
                    type="textarea"
                    label="컨설팅보고서 재업로드 요청메시지"
                    id="outlined-multiline-flexible"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                  />

                  <Box p={2} className="tac">
                    <Button color="primary" type="submit">
                      컨설팅보고서 재업로드 요청메시지 발송하기
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => closeModal()} color="primary" simple>
              닫기
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default ReRequestConsutingReportModal;
