import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import styles from "../../assets/jss/material-kit-react/views/componentsSections/javascriptStyles";
import Button from "../../Components/initComponents/CustomButtons/Button";
import Card from "../../Components/initComponents/Card/Card";
import CardBody from "../../Components/initComponents/Card/CardBody";
import Firebase from "../../Context/Firebase";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import featuresStyle from "../ConsultantList/featuresStyle";
import * as SendNotification from "../../Utill/SendNotification";

const useStyles = makeStyles({
  ...styles,
  modal: {
    ...styles.modal,
    width: "900px",
  },
  iconLocation: {
    position: "relative",
    top: "7px",
    marginRight: "7px",
  },
});
const featureStyles = makeStyles(featuresStyle);
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

Transition.displayName = "Transition";

const ConsultingCeaseModal = (props) => {
  const { isModal, closeModal, selectedItem } = props;
  const classes = useStyles();
  const featureClasses = featureStyles();
  const [isLoading, setIsLoading] = useState(false);
  const firebase = useContext(Firebase);

  const validationSchema = Yup.object().shape({
    consultingCeaseReason: Yup.string()
      .max(1000, "1000자 이내로 입력해주세요.")
      .required("컨설팅 중단사유를 입력해주세요."),
  });

  const onSubmit = (value) => {
    setIsLoading(true);
    firebase
      .firestore()
      .collection("ConsultingList")
      .doc(selectedItem.id)
      .update({
        reRequestConsulting: true,
        consultingState: 5,
        consultingCeaseReason: value.consultingCeaseReason,
      })
      .then(() => {
        // 컨설턴트의 진행중인 컨설팅건수 -1 하기
        firebase
          .firestore()
          .collection("UsersCollection")
          .doc(selectedItem.consultantEmail)
          .update({
            ongoingConsultingCase: firebase.firestore.FieldValue.increment(-1),
          })
          .then(() => {
            // 에술인의 진행중인 컨설팅 건수 -1 하고 요청 건수 -1 하기
            firebase
              .firestore()
              .collection("UsersCollection")
              .doc(selectedItem.artistEmail)
              .update({
                ongoingConsultingCase:
                  firebase.firestore.FieldValue.increment(-1),
                ongoingRequestCase: firebase.firestore.FieldValue.increment(-1),
              })
              .then(() => {
                setIsLoading(false);
                const sendEmailInfo = {
                  artistEmail: selectedItem.artistEmail,
                  artistName: selectedItem.artistName,
                  consultantEmail: selectedItem.consultantEmail,
                  consultantName: selectedItem.consultantName,
                };
                SendNotification.sendEmail(6, sendEmailInfo);
                closeModal();
              });
          });
      });
  };

  return (
    selectedItem && (
      <>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={isModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => closeModal()}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => closeModal()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
          </DialogTitle>

          <DialogContent
            id="classic-modal-slide-description"
            className={featureClasses.modalBody}
          >
            <Card profile className="boxShadowNone">
              <CardBody>
                <Typography variant="h6">
                  <PersonIcon className={classes.iconLocation} />
                  컨설턴트 - {selectedItem.consultantName}
                </Typography>
                <Typography variant="h6">
                  <PersonIcon className={classes.iconLocation} />
                  예술인 - {selectedItem.artistName}
                </Typography>
              </CardBody>
            </Card>

            <Formik
              initialValues={{
                consultingCeaseReason: selectedItem.consultingCeaseReason || "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {() => (
                <Form>
                  <Field
                    disabled={false}
                    component={TextField}
                    name="consultingCeaseReason"
                    placeholder="컨설팅 중단사유를 입력해주세요."
                    type="textarea"
                    label="컨설팅 중단사유를 입력해주세요."
                    id="outlined-multiline-flexible"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                  />

                  <Box p={2} className="tac">
                    <Typography variant="body2">
                      중단된 컨설팅은 재진행시킬 수 없습니다.
                    </Typography>
                    <Button color="primary" type="submit">
                      컨설팅 중단하기
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => closeModal()} color="primary" simple>
              닫기
            </Button>
          </DialogActions>
        </Dialog>
        <LoadingOverlay isLoading={isLoading} />
      </>
    )
  );
};

export default ConsultingCeaseModal;
