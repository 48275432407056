
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './assets/scss/material-kit-react.scss';
import './assets/css/custom.css';

// 실서버 배포시 체크사항
// sendnotification체크
// siteConfig.js의 functionUrl 체크

ReactDOM.render(<App />, document.getElementById('root'));
