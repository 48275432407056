import React from "react";
import { makeStyles, useMediaQuery, Hidden } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import Looks3Icon from "@material-ui/icons/Looks3";
import Looks4Icon from "@material-ui/icons/Looks4";
import Looks5Icon from "@material-ui/icons/Looks5";
import Looks6Icon from "@material-ui/icons/Looks6";
import GridItem from "../../Components/initComponents/Grid/GridItem";
import GuideStyle from "./GuideStyle";
import GridContainer from "../../Components/initComponents/Grid/GridContainer";
import InfoArea from "../../Components/initComponents/InfoArea/InfoArea";
import bgImg from "../../assets/img/bg2.svg";
import WebIcon from "@material-ui/icons/Web";
import instruction_title_1 from "../../assets/img/instruction_title_1.jpg";
import instruction_title_2 from "../../assets/img/instruction_title_2.jpg";
import instruction_1 from "../../assets/img/instruction_1.jpg";
import instruction_2 from "../../assets/img/instruction_2.jpg";
import mobile_instruction_1 from "../../assets/img/mobile_instruction_1.jpg";
import mobile_instruction_2 from "../../assets/img/mobile_instruction_2.jpg";
import instruction_description from "../../assets/img/instruction_description.jpg";

const useStyles = makeStyles(GuideStyle);

const MainScreen = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <Box

    // style={{
    //   backgroundImage: `url(${bgImg})`,
    //   backgroundPosition: 'top right',
    //   backgroundSize: 'cover',
    // }}
    >
      <div className={classes.container}>
        <Box mb={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Hidden mdUp>
                <h3
                  style={{
                    fontWeight: 700,
                    color: "#212121",
                    fontSize: "32px",
                  }}
                >
                  이용안내
                </h3>
                <div style={{ width: "100%" }}>
                  <img
                    src={instruction_description}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              </Hidden>

              <Hidden smDown>
                <h3
                  style={{
                    fontWeight: 700,
                    color: "#212121",
                    fontSize: "48px",
                    paddingTop: "80px",
                  }}
                >
                  이용안내
                </h3>
                <div>
                  <h5 className={classes.description}>
                    아이컨택은 부산 예술인이 원하는 컨설턴트를 선택하여 컨설팅을
                    받을 수 있는 웹사이트입니다. <br />
                    예술인은 사이트를 통해 컨설팅을 신청할 수 있으며, 정확한
                    상담을 위해 컨설팅 신청시 요청사항을 자세히 적어주셔야
                    합니다. <br />
                    컨설턴트의 승낙이 완료되면 컨설팅 일자 및 장소 확정 후 1:1
                    상담을 받을 수 있으며, 컨설팅은 1회 진행됩니다. <br />
                  </h5>
                </div>
              </Hidden>
            </GridItem>
          </GridContainer>
        </Box>
        <GridContainer>
          <Hidden mdUp>
            <GridItem>
              <div style={{ width: "100%", marginBottom: "20%" }}>
                <img
                  src={mobile_instruction_1}
                  alt=""
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ width: "100%", marginBottom: "20%" }}>
                <img
                  src={mobile_instruction_2}
                  alt=""
                  style={{ width: "100%" }}
                />
              </div>
            </GridItem>
          </Hidden>
          <Hidden smDown>
            <GridItem>
              <div style={{ marginBottom: "5%" }}>
                <img
                  src={instruction_title_1}
                  alt=""
                  style={{ width: "35%", marginRight: "5%" }}
                />
                <img src={instruction_1} alt="" style={{ width: "60%" }} />
              </div>
            </GridItem>
            <GridItem>
              <div style={{ marginBottom: "10%" }}>
                <img
                  src={instruction_title_2}
                  alt=""
                  style={{ width: "35%", marginRight: "5%" }}
                />
                <img src={instruction_2} alt="" style={{ width: "60%" }} />
              </div>
            </GridItem>
          </Hidden>

          {/* <GridItem xs={12} sm={12} md={4}>
              <div>
                <img
                  src={isMobile?mobile_instruction_title_1:instruction_title_1}
                  alt=""
                  style={{ width: "100%" }}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={8} md={8}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={instruction1} alt="" style={{ width: "80%" }} />
              </div>
            </GridItem> */}
        </GridContainer>
      </div>
    </Box>
  );
};

export default MainScreen;
