
import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';


const SubLink = (props) => (
  <>
    {
      props.viewComponent === 'LOGIN'
        ? (
          <>
            <Grid container>
              <Grid item xs>
                <Button onClick={() => props.setViewComponent('FINDAUTH')}>
                  계정찾기
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => props.setViewComponent('FINDPASSWORD')}>
                  비밀번호찾기
                </Button>
              </Grid>
            </Grid>
          </>
        )
        : ''
    }

    {
      props.viewComponent === 'FINDAUTH'
        ? (
          <>
            <Grid container>
              <Grid item xs>
                <Button onClick={() => props.setViewComponent('LOGIN')}>
                  로그인하기
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => props.setViewComponent('FINDPASSWORD')}>
                  비밀번호찾기
                </Button>
              </Grid>
            </Grid>
          </>
        )
        : ''
    }
    {
      props.viewComponent === 'FINDPASSWORD'
        ? (
          <>
            <Grid container>
              <Grid item xs>
                <Button onClick={() => props.setViewComponent('LOGIN')}>
                  로그인하기
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => props.setViewComponent('FINDAUTH')}>
                  계정찾기
                </Button>
              </Grid>
            </Grid>
          </>
        )
        : ''
    }
  </>
);

export default SubLink;
