import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import * as Routes from "../../Constants/Routes";
import AuthenticationContext from "../../Context/Authentication";
import styles from "../../assets/jss/material-kit-react/views/componentsSections/navbarsStyle";
import GridContainer from "../initComponents/Grid/GridContainer";
import GridItem from "../initComponents/Grid/GridItem";
import Header from "../initComponents/Header/Header";
import CustomDropdown from "../initComponents/CustomDropdown/CustomDropdown";
import Button from "../initComponents/CustomButtons/Button";
import FirebaseContext from "../../Context/Firebase";
import iconLock from "../../assets/img/ic_lock.svg";
import iconLogin from "../../assets/img/ic_login.svg";
import iconLogout from "../../assets/img/ic_logout.svg";
import iconSetting from "../../assets/img/ic_setting.svg";
import iconClose from "../../assets/img/ic_close.svg";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  navLinkIcon: {
    display: "none",
    marginRight: "10px",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  closeBtn: {
    position: "absolute",
    top: "60px",
    left: "-52px",
    cursor: "pointer",
  },
}));

const SiteHeader = () => {
  const classes = useStyles();
  const authUser = useContext(AuthenticationContext);
  const firebase = useContext(FirebaseContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const [pathName, setPathName] = useState("");

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ padding: 0 }}>
            <Header
              mobileOpen={mobileOpen}
              setMobileOpen={setMobileOpen}
              color="transparent"
              leftLinks={
                <LeftMenuLinks
                  mobileOpen={mobileOpen}
                  setMobileOpen={setMobileOpen}
                  authUser={authUser}
                  classes={classes}
                  firebase={firebase}
                  pathName={pathName}
                />
              }
              rightLinks={
                <MenuLinks
                  mobileOpen={mobileOpen}
                  setMobileOpen={setMobileOpen}
                  authUser={authUser}
                  classes={classes}
                  firebase={firebase}
                  pathName={pathName}
                />
              }
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

const LeftMenuLinks = (props) => {
  const { authUser, classes, firebase, mobileOpen, setMobileOpen, pathName } =
    props;
  const history = useHistory();
  const signOut = () => {
    firebase.auth().signOut();
    history.push("/");
  };
  return (
    <>
      <IconButton
        color="inherit"
        aria-label="close drawer"
        onClick={() => {
          setMobileOpen(!mobileOpen);
        }}
        className={classes.closeBtn}
      >
        <img src={iconClose} alt={iconClose} style={{ width: "24px" }} />
      </IconButton>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <div
            onClick={() => setMobileOpen(!mobileOpen)}
            className={classes.mobileRight}
          >
            <Link to={Routes.CONSULTANTLIST}>
              <Button
                color="transparent"
                className={
                  pathName === Routes.CONSULTANTLIST
                    ? `${classes.navLink} ${classes.linkSelected}`
                    : classes.navLink
                }
              >
                컨설턴트 목록 바로가기
              </Button>
            </Link>
          </div>
        </ListItem>
        <ListItem className={classes.listItem}>
          <div
            onClick={() => setMobileOpen(!mobileOpen)}
            className={classes.mobileRight}
          >
            <Link to={Routes.GUIDE}>
              <Button
                color="transparent"
                className={
                  pathName === Routes.GUIDE
                    ? `${classes.navLink} ${classes.linkSelected}`
                    : classes.navLink
                }
              >
                이용안내
              </Button>
            </Link>
          </div>
        </ListItem>
        <ListItem className={classes.listItem}>
          <div
            onClick={() => setMobileOpen(!mobileOpen)}
            className={classes.mobileRight}
          >
            <Link to={Routes.FAQ}>
              <Button
                color="transparent"
                className={
                  pathName === Routes.FAQ
                    ? `${classes.navLink} ${classes.linkSelected}`
                    : classes.navLink
                }
              >
                자주묻는질문
              </Button>
            </Link>
          </div>
        </ListItem>
        {authUser && (
          <>
            <ListItem className={classes.listItem}>
              <CustomDropdown
                left
                hoverColor="transparent"
                buttonText="마이페이지"
                buttonProps={{
                  className:
                    pathName === Routes.MYCONSULTING ||
                    pathName === Routes.MYINQUIRY ||
                    pathName === Routes.MEMBERSHIP ||
                    pathName === Routes.CHANGEPASSWORD
                      ? `${classes.navLink} ${classes.linkSelected}`
                      : classes.navLink,
                  color: "transparent",
                }}
                dropdownList={[
                  <Link to={Routes.MYCONSULTING}>
                    <div onClick={() => setMobileOpen(!mobileOpen)}>
                      나의 컨설팅
                    </div>
                  </Link>,
                  <Link to={Routes.MYINQUIRY}>
                    <div onClick={() => setMobileOpen(!mobileOpen)}>
                      나의 문의내역
                    </div>
                  </Link>,
                  { divider: true },
                  <Link to={Routes.MEMBERSHIP}>
                    <div onClick={() => setMobileOpen(!mobileOpen)}>
                      회원정보 수정
                    </div>
                  </Link>,
                  <Link to={Routes.CHANGEPASSWORD}>
                    <div onClick={() => setMobileOpen(!mobileOpen)}>
                      비밀번호 변경
                    </div>
                  </Link>,
                ]}
              />
            </ListItem>
          </>
        )}
      </List>
    </>
  );
};

const MenuLinks = (props) => {
  const { authUser, classes, firebase, mobileOpen, setMobileOpen, pathName } =
    props;
  const history = useHistory();
  const signOut = () => {
    firebase.auth().signOut();
    history.push("/");
  };
  return (
    <List className={`${classes.list} ${classes.mobileList}`}>
      {authUser ? (
        <>
          <ListItem className={classes.listItem}>
            <div
              onClick={() => setMobileOpen(!mobileOpen)}
              className={classes.mobileRight}
            >
              <Link to={Routes.INDEX} onClick={() => signOut()}>
                <Button
                  color="transparent"
                  className={`${classes.navLink} ${classes.rightNavLink}`}
                >
                  <img
                    src={iconLogout}
                    alt="logout"
                    className={classes.navLinkIcon}
                  />
                  로그아웃
                </Button>
              </Link>
            </div>
          </ListItem>
          {authUser.userLevel > 9 && (
            <ListItem className={classes.listItem}>
              <div
                onClick={() => setMobileOpen(!mobileOpen)}
                className={classes.mobileRight}
              >
                <Link to={Routes.ADMIN}>
                  <Button
                    color="transparent"
                    className={
                      pathName === Routes.ADMIN
                        ? `${classes.navLink} ${classes.linkSelected} ${classes.rightNavLink}`
                        : `${classes.navLink} ${classes.rightNavLink}`
                    }
                  >
                    <img
                      src={iconSetting}
                      alt="login"
                      className={classes.navLinkIcon}
                    />
                    관리자
                  </Button>
                </Link>
              </div>
            </ListItem>
          )}
        </>
      ) : (
        <>
          <ListItem className={classes.listItem}>
            <div
              onClick={() => setMobileOpen(!mobileOpen)}
              className={classes.mobileRight}
            >
              <Link to={Routes.LOGIN}>
                <Button
                  color="transparent"
                  className={
                    pathName === Routes.LOGIN
                      ? `${classes.navLink} ${classes.linkSelected} ${classes.rightNavLink}`
                      : `${classes.navLink} ${classes.rightNavLink}`
                  }
                >
                  <img
                    src={iconLogin}
                    alt="login"
                    className={classes.navLinkIcon}
                  />
                  로그인
                </Button>
              </Link>
            </div>
          </ListItem>
          <ListItem className={classes.listItem}>
            <div
              onClick={() => setMobileOpen(!mobileOpen)}
              className={classes.mobileRight}
            >
              <Link to={Routes.ARTISTJOIN}>
                <Button
                  color="transparent"
                  className={
                    pathName === Routes.ARTISTJOIN
                      ? `${classes.navLink} ${classes.linkSelected} ${classes.rightNavLink}`
                      : `${classes.navLink} ${classes.rightNavLink}`
                  }
                >
                  <img
                    src={iconLock}
                    alt="lock"
                    className={classes.navLinkIcon}
                  />
                  회원가입
                </Button>
              </Link>
            </div>
          </ListItem>
        </>
      )}
    </List>
  );
};

export default SiteHeader;
