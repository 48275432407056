import React, { useEffect, useState } from 'react';
import Login from './Login';
import FindAuth from './FindAuth';
import FindPassword from './FindPassword';

const LoginIndex = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [viewComponent, setViewComponent] = useState('LOGIN');
  switch (viewComponent) {
    case 'LOGIN':
      return (
        <Login
          setViewComponent={setViewComponent}
          viewComponent={viewComponent}
        />
      );
    case 'FINDAUTH':
      return (
        <FindAuth
          setViewComponent={setViewComponent}
          viewComponent={viewComponent}
        />
      );
    case 'FINDPASSWORD':
      return (
        <FindPassword
          setViewComponent={setViewComponent}
          viewComponent={viewComponent}
        />
      );
    default:
  }
};

export default LoginIndex;
