export const ages = [
  {
    value: '10대',
    label: '10대',
  },
  {
    value: '20대',
    label: '20대',
  },
  {
    value: '30대',
    label: '30대',
  },
  {
    value: '40대',
    label: '40대',
  },
  {
    value: '50대',
    label: '50대',
  },
  {
    value: '60대',
    label: '60대',
  },
  {
    value: '70대이상',
    label: '70대이상',
  },

];

export const artistArea = [
  {
    value: 'A1',
    label: '시각예술',
  },
  {
    value: 'A2',
    label: '공연예술',
  },
  {
    value: 'A3',
    label: '문학',
  },

];

export const IsartistCertification = [
  {
    value: true,
    label: '예술인활동증명 완료 ',
  },
  {
    value: false,
    label: '예술인활동증명 미완료',
  },
];
