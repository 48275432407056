import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import Typography from "@material-ui/core/Typography";

import * as Routes from "../../Constants/Routes";
import styles from "../../assets/jss/material-kit-react/views/componentsSections/javascriptStyles";
import Button from "../../Components/initComponents/CustomButtons/Button";
import Card from "../../Components/initComponents/Card/Card";
import CardHeader from "../../Components/initComponents/Card/CardHeader";
import sampleImg from "../../assets/img/samplePeople.jpg";
import CardBody from "../../Components/initComponents/Card/CardBody";
import AuthenticationContext from "../../Context/Authentication";
import Firebase from "../../Context/Firebase";
import * as siteConfig from "../../Constants/SiteConfig";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import featuresStyle from "./featuresStyle";
import * as SendNotification from "../../Utill/SendNotification";
import GridContainer from "../../Components/initComponents/Grid/GridContainer";
import GridItem from "../../Components/initComponents/Grid/GridItem";
import { useMediaQuery } from "@material-ui/core";
import { consultingMainCatepory } from "../ConsultantJoin/SelectItem";

const useStyles = makeStyles({
  ...styles,
  dialogContainer: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "unset",
    },
  },
});
const featureStyles = makeStyles(featuresStyle);
const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

Transition.displayName = "Transition";

const RequestConsultingModal = (props) => {
  const { isModal, closeModal, selectedItem } = props;
  const history = useHistory();
  const authUser = useContext(AuthenticationContext);
  const firebase = useContext(Firebase);
  const classes = useStyles();
  const featureClasses = featureStyles();
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    artActiveStartYear: Yup.string().max(30, "30자 이내로 입력해주세요.").required("예술활동 시작연도를 입력해주세요."),
    artActivePeriod: Yup.string().max(30, "30자 이내로 입력해주세요.").required("예술활동 기간을 입력해주세요."),
    expectMatchingDate: Yup.string().max(100, "100자 이내로 입력해주세요.").required("컨설팅 희망날짜를 입력해주세요."),
    consultingRequestContent: Yup.string().max(1000, "1000자 이내로 입력해주세요.").required("컨설팅 요청 메시지를 입력해주세요."),
  });

  const goLoginWithSaveNowItem = () => {
    sessionStorage.setItem("selectedItem", JSON.stringify(selectedItem));
    history.push(Routes.LOGIN);
  };
  const onSubmit = (value) => {
    setIsLoading(true);

    firebase
      .firestore()
      .collection("UsersCollection")
      .doc(authUser.email)
      .get()
      .then((nowUserInfo) => {
        const loginedUserInfo = nowUserInfo.data();
        if (!loginedUserInfo.isAccountActive) {
          alert("현재 귀하의 계정은 비활성화 상태입니다.\n관리자에게 문의해주세요.");
          return false;
        }

        // 이미 진행중인 컨설팅건수가 있을 경우 리턴
        firebase
          .firestore()
          .collection("ConsultingList")
          .where("artistEmail", "==", loginedUserInfo.email)
          .where("consultantEmail", "==", selectedItem.email)
          .where("reRequestConsulting", "==", false)
          .where("consultingState", "<", 7)
          .get()
          .then((result) => {
            if (result.docs.length > 0) {
              alert("해당 컨설턴트와 현재 진행중인 컨설팅이 존재합니다.");
              setIsLoading(false);
              return false;
            }

            // 컨설팅 요청 횟수를 초과하였을 경우 리턴
            if (loginedUserInfo.ongoingRequestCase >= siteConfig.artistRequestConsultingLimitCase) {
              setIsLoading(false);
              alert("컨설팅 요청 제한 횟수를 초과하였습니다.");
              setIsLoading(false);
              return false;
            }
            // 컨설팅리스트에 추가
            firebase
              .firestore()
              .collection("ConsultingList")
              .add({
                consultingState: 1,
                artistEmail: loginedUserInfo.email,
                artistName: loginedUserInfo.name,
                artistPhone: loginedUserInfo.phone,
                artistArea: loginedUserInfo.artistArea,
                consultantEmail: selectedItem.email,
                consultantName: selectedItem.name,
                consultantPhone: selectedItem.phone,
                consultingMainCatepory: selectedItem.consultingMainCatepory,
                consultingSubCatepory: selectedItem.consultingSubCatepory,
                consultingGenre: selectedItem.consultingSubCatepory,
                consultingAreaText: "",
                consultingRequestDate: new Date(),
                consultingRequestContent: value.consultingRequestContent,
                artActiveStartYear: value.artActiveStartYear,
                artActivePeriod: value.artActivePeriod,
                expectMatchingDate: value.expectMatchingDate,
                reRequestConsulting: false,
                consultingRequestRejectReason: "",
                consultingStartDate: "",
                consultingCeaseReason: "",
                consultingEndDate: "",
                consultingResultReportUrl: "",
                consultingResultReportFileName: "",
                consultingSurveyResult: "",
              })
              .then((r) => {
                firebase.firestore().collection("ConsultingList").doc(r.id).set(
                  {
                    id: r.id,
                  },
                  { merge: true }
                );
                firebase
                  .firestore()
                  .collection("UsersCollection")
                  .doc(loginedUserInfo.email)
                  .update({
                    ongoingRequestCase: firebase.firestore.FieldValue.increment(+1),
                  });
                const sendInfo = {
                  artistEmail: loginedUserInfo.email,
                  consultantEmail: selectedItem.email,
                  artistName: loginedUserInfo.name,
                  consultantName: selectedItem.name,
                };
                const sendSmsInfo = {
                  artistName: loginedUserInfo.name,
                  consultantName: selectedItem.name,
                  consultantPhone: selectedItem.phone,
                };
                SendNotification.sendEmail(2, sendInfo);
                SendNotification.sendSms(3, sendSmsInfo);
                alert("컨설팅 요청을 완료하였습니다.\n마이페이지 > 나의 컨설팅에서 확인하실 수 있습니다.");
                setIsLoading(false);
                closeModal();
              });
          });
      });
  };

  return (
    selectedItem && (
      <>
        <Dialog
          className={classes.dialogContainer}
          open={isModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => closeModal()}
          // aria-labelledby="classic-modal-slide-title"
          // aria-describedby="classic-modal-slide-description"
        >
          <div
            style={{
              display: "flex",
              maxHeight: "500px",
              maxWidth: "800px",
              // display: "block",
            }}
          >
            {!isMobile && (
              <div
                style={{
                  display: "flex",
                  maxHeight: "500px",
                  width: "40%",
                  // display: "block",
                }}
              >
                <div
                  style={{
                    maxHeight: "500px",
                    // width: "45%",
                    display: "block",
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={selectedItem.profileImgUrl ? selectedItem.profileImgUrl : sampleImg}
                    alt={`${selectedItem.name} 프로필 이미지`}
                  />
                </div>
              </div>
            )}

            <div style={isMobile ? { width: "auto" } : { width: "60%" }}>
              <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => closeModal()}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                style={{
                  maxHeight: "460px",
                  width: "100%",
                  padding: "0px 50px",
                }}
              >
                <div style={{}}>
                  <div
                    style={{
                      fontSize: "32px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "48px",
                      letterSpacing: "-2px",
                      paddingBottom: "5px",
                    }}
                  >
                    {selectedItem.name}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      flexWrap: "wrap",
                      marginBottom: "30px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "29px",
                        padding: "10px 12px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "100px",
                        backgroundColor: "#6FBA2C",
                        fontSize: 13,
                        fontWeight: 700,
                        lineHeight: 22,
                        color: "white",
                      }}
                    >
                      {"# " + siteConfig.consultingSubCatepory[selectedItem.consultingSubCatepory]}
                    </div>
                  </div>
                  <div
                    style={{
                      // height: 117,
                      margin: "12px 0 18px 0",
                      // overflow: "hidden",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "22px",
                      color: "#212121",
                    }}
                  >
                    {selectedItem.profileContent.split("\n").map((line) => (
                      <p>{line}</p>
                    ))}
                  </div>
                </div>
                {authUser && authUser.position === "ARTIST" && (
                  <Formik
                    initialValues={{
                      consultingRequestContent: "",
                      artActiveStartYear: "",
                      artActivePeriod: "",
                      expectMatchingDate: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ submitForm }) => (
                      <Form>
                        <Field
                          disabled={false}
                          component={TextField}
                          variant="outlined"
                          name="artActiveStartYear"
                          type="text"
                          label="예술활동 시작연도(예:2019년)"
                          margin="normal"
                          fullWidth
                        />
                        <Field
                          disabled={false}
                          component={TextField}
                          variant="outlined"
                          name="artActivePeriod"
                          type="text"
                          label="예술활동 기간(예: 6개월, 1년, 3년)"
                          margin="normal"
                          fullWidth
                        />
                        <Field
                          disabled={false}
                          component={TextField}
                          variant="outlined"
                          name="expectMatchingDate"
                          type="text"
                          label="컨설팅 희망날짜와 시간"
                          margin="normal"
                          fullWidth
                        />
                        <Field
                          disabled={false}
                          component={TextField}
                          name="consultingRequestContent"
                          placeholder="컨설팅 요청 메시지를 입력해주세요"
                          type="textarea"
                          label="컨설팅 요청 내용"
                          id="outlined-multiline-flexible"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          multiline
                          rows={3}
                        />

                        <Box p={2} className="tac">
                          <Typography variant="body2">
                            컨설팅 요청과 관련된 다양한 정보(예술인의 현상황, 컨설팅 방향 등)를 입력해주세요.
                          </Typography>
                          <Typography variant="body2">동시에 진행가능한 컨설팅 요청건은 최대 2건입니다.</Typography>
                          <Button style={{ backgroundColor: "#6FBA2C" }} onClick={submitForm}>
                            컨설팅 요청하기
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                )}
                {!authUser && (
                  <>
                    <Box p={1} className="tac">
                      <Typography variant="body2">컨설팅요청을 위해 로그인해주세요.</Typography>

                      <Button color="primary" onClick={goLoginWithSaveNowItem}>
                        로그인하기
                      </Button>
                    </Box>
                  </>
                )}
              </DialogContent>
            </div>
          </div>

          {/* <DialogActions className={classes.modalFooter}>
            <Button onClick={() => closeModal()} color="primary" simple>
              닫기
            </Button>
          </DialogActions> */}
        </Dialog>
        <LoadingOverlay isLoading={isLoading} />
      </>
    )
  );
};

export default RequestConsultingModal;
