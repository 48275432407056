import React, { useContext, useState } from 'react';
import MaterialTable from 'material-table';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RemoveIcon from '@material-ui/icons/Remove';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import Button from '@material-ui/core/Button';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import * as Function from '../../../Utill/Functions';
import AuthenticationContext from '../../../Context/Authentication';
import * as siteConfig from '../../../Constants/SiteConfig';
import ConsultantDetailModal from './ConsultantDetailModal';

export default function ConsultantDataTable(props) {
  const { displayConsulting, } = props;
  const [isModal, setIsModal] = useState(false);
  const authUser = useContext(AuthenticationContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const [consultingRejectArea, setConsultingRejectArea] = useState(false);
  const [consultingReportArea, setConsultingReportArea] = useState(false);

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModal(true);
  };
  const closeModal = () => {
    setSelectedItem(null);
    setConsultingRejectArea(false);
    setConsultingReportArea(false);
    setIsModal(false);
  };

  return (
    <>
      <MaterialTable
        title={`${authUser.name}님 컨설팅리스트`}
        style={{
          minWidth: '700px',
        }}
        icons={{
          Add: AddCircleIcon,
          Check: CheckBoxIcon,
          Clear: ClearIcon,
          Delete: DeleteIcon,
          DetailPanel: AddCircleIcon,
          Edit: EditIcon,
          Export: GetAppIcon,
          Filter: FilterListIcon,
          FirstPage: FirstPageIcon,
          LastPage: LastPageIcon,
          NextPage: NavigateNextIcon,
          PreviousPage: NavigateBeforeIcon,
          ResetSearch: ClearIcon,
          Search: SearchIcon,
          SortArrow: ArrowDownwardIcon,
          ThirdStateCheck: RemoveIcon,
          ViewColumn: ViewColumnIcon,
        }}
        localization={{ ...siteConfig.tableSetLable, header: { actions: '상세 ' } }}
        columns={[
          {
            title: '진행상황',
            field: 'consultingState',
            defaultSort: 'desc',
            lookup: siteConfig.consultingProcess,
            cellStyle: {
              textAlign: 'left',
            },
          },
          {
            title: '요청일자',
            field: 'consultingRequestDate',
            render: (data) => Function.printDate(data.consultingRequestDate.toDate()),
            cellStyle: {
              textAlign: 'left',
            },
          },
          {
            title: '예술인',
            field: 'artistName',
            cellStyle: {
              textAlign: 'left',
            },
          },
          {
            title: '연락처',
            field: 'artistPhone',
            cellStyle: {
              textAlign: 'left',
            },
          },
          {
            title: '활동분야',
            field: 'artistArea',
            lookup: { A1: '시각예술', A2: '공연예술', A3: '문학' },
            cellStyle: {
              textAlign: 'left',
            },
          },

        ]}
        data={displayConsulting}
        components={{
          Action: (props) => (
            <Button
              onClick={(event) => props.action.onClick(event, props.data)}
              color="primary"
              startIcon={<ExpandLessOutlinedIcon />}
              variant="outlined"
              style={{ width: '130px' }}
              size="medium"
            >
              자세히 보기
            </Button>
          ),
        }}
        actions={[
          {
            icon: ViewColumnIcon,
            tooltip: '자세히 보기',
            onClick: (event, rowData) => openModal(rowData),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            color: '#000',
            fontSize: '15px',
          },
        }}
      />
      {isModal
      && (
        <ConsultantDetailModal
          selectedItem={selectedItem}
          consultingRejectArea={consultingRejectArea}
          setConsultingRejectArea={setConsultingRejectArea}
          consultingReportArea={consultingReportArea}
          setConsultingReportArea={setConsultingReportArea}
          isModal={isModal}
          closeModal={closeModal}
        />
      )}

    </>
  );
}
