import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import * as Function from '../../Utill/Functions';
import * as SelectItem from './ConsultingRejectReason';

const useStyles = makeStyles({
  titleCell: {
    paddingLeft: '48px',
    width: '220px',
  },
  iconLocation: {
    position: 'relative',
    top: '7px',
    marginRight: '7px',
  },
});

const CommonConsultingProcessData = (props) => {
  const { displayConsulting } = props;
  const classes = useStyles();
  return (
    <>
      <TableRow>
        <TableCell className={classes.titleCell}>
          <DateRangeIcon className={classes.iconLocation} />
          예술인활동 시작연도
        </TableCell>
        <TableCell>{displayConsulting.artActiveStartYear}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell className={classes.titleCell}>
          <DateRangeIcon className={classes.iconLocation} />
          예술인활동 기간
        </TableCell>
        <TableCell>{displayConsulting.artActivePeriod}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell className={classes.titleCell}>
          <DateRangeIcon className={classes.iconLocation} />
          컨설팅 희망일시
        </TableCell>
        <TableCell>{displayConsulting.expectMatchingDate}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell className={classes.titleCell}>
          <DateRangeIcon className={classes.iconLocation} />
          컨설팅 요청일자
        </TableCell>
        <TableCell>{Function.printDate(displayConsulting.consultingRequestDate.toDate())}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.titleCell}>
          <TextsmsOutlinedIcon className={classes.iconLocation} />
          컨설팅 요청내용
        </TableCell>
        <TableCell>{displayConsulting.consultingRequestContent}</TableCell>
      </TableRow>

      {displayConsulting.consultingRequestRejectReason
        ? (
          <TableRow>
            <TableCell className={classes.titleCell}>
              <ErrorOutlineOutlinedIcon color="error" className={classes.iconLocation} />
              컨설팅요청 거절사유
            </TableCell>
            <TableCell>{SelectItem.rejectReason[displayConsulting.consultingRequestRejectReason - 1].label}</TableCell>
          </TableRow>
        ) : ''}

      {displayConsulting.consultingCeaseReason
        ? (
          <TableRow>
            <TableCell className={classes.titleCell}>
              <ErrorOutlineOutlinedIcon color="error" className={classes.iconLocation} />
              컨설팅 중단사유
            </TableCell>
            <TableCell>{displayConsulting.consultingCeaseReason}</TableCell>
          </TableRow>
        ) : ''}

      {displayConsulting.consultingStartDate
        ? (
          <TableRow>
            <TableCell className={classes.titleCell}>
              <DateRangeIcon className={classes.iconLocation} />
              컨설팅 시작일
            </TableCell>
            <TableCell>{Function.printDate(displayConsulting.consultingStartDate.toDate())}</TableCell>
          </TableRow>
        ) : ''}

      {displayConsulting.consultingEndDate
        ? (
          <TableRow>
            <TableCell className={classes.titleCell}>
              <DateRangeIcon className={classes.iconLocation} />
              컨설팅 종료일
            </TableCell>
            <TableCell>{Function.printDate(displayConsulting.consultingEndDate.toDate())}</TableCell>
          </TableRow>
        ) : ''}
    </>
  );
};

export default CommonConsultingProcessData;
