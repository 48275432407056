import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import RemoveIcon from "@material-ui/icons/Remove";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import * as Routes from "../../Constants/Routes";
import * as siteConfig from "../../Constants/SiteConfig";
import * as Function from "../../Utill/Functions";
import FirebaseContext from "../../Context/Firebase";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import { Button } from "@material-ui/core";
import BestConsultantModal from "./BestConsultantModal";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: siteConfig.mainColor,
    },
    secondary: {
      main: siteConfig.mainColor,
    },
  },
  "& .MuiPaper-root-392 ": {
    width: "100%",
  },
});
const MembersManage = () => {
  const history = useHistory();
  const firebase = useContext(FirebaseContext);
  const [memberList, setMemberList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    const subscribe = firebase
      .firestore()
      .collection("UsersCollection")
      .orderBy("registrationDate", "desc")
      .onSnapshot((result) => {
        const resultData = [];
        result.forEach((doc) => {
          resultData.push(doc.data());
        });
        setMemberList(resultData);
        setIsLoading(false);
      });
    return () => subscribe();
  }, []);

  return isLoading ? (
    <LoadingOverlay isLoading={isLoading} />
  ) : (
    <>
      <div style={{ display: "flex" }}>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          onClick={() => setIsModal(true)}
          style={{ display: "flex", margin: "20px 0px" }}
        >
          이달의컨설턴트
        </Button>
      </div>
      <MuiThemeProvider theme={theme} style={{ width: "90%" }}>
        <MaterialTable
          title="회원관리"
          columns={[
            {
              title: "포지션",
              field: "position",
              editable: "never",
              lookup: { ARTIST: "예술인", CONSULTANT: "컨설턴트" },
              width: 100,
            },
            {
              title: "예술인분야",
              editable: "never",
              field: "artistArea",
              lookup: { A1: "시각예술", A2: "공연예술", A3: "문학" },
              width: 130,
            },
            {
              title: "예술인 활동증명",
              editable: "never",
              field: "IsArtistCertification",
              lookup: { true: "완료", false: "미완료" },
              width: 160,
            },
            {
              title: "컨설팅 분야",
              editable: "never",
              field: "consultingMainCatepory",
              lookup: {
                C1: "법률",
                C2: "회계",
                C3: "노무",
                C4: "홍보",
                C5: "상담",
                C6: "문화예술",
                C7: "법무",
              },
              width: 130,
            },
            {
              title: "컨설팅 세부분야",
              editable: "never",
              field: "consultingSubCatepory",
              lookup: {
                CG1: "법률",
                CG2: "법률 - 저작권",
                CG3: "회계",
                CG4: "노무",
                CG5: "홍보",
                CG6: "상담 - 심리",
                CG7: "상담 - 성희롱성폭력",
                CG8: "기획",
                CG9: "기획 - 문학",
                CG10: "기획 - 시각",
                CG11: "기획 - 공연",
                CG12: "기획 - 영화",
                CG13: "기획 - 문화일반",
                CG14: "창작",
                CG15: "창작 - 문학",
                CG16: "창작 - 시각",
                CG17: "창작 - 공연",
                CG18: "창작 - 영화",
                CG19: "창작 - 문화일반",
                CG20: "창업",
                CG21: "기술",
                CG22: "법무",
              },
              width: 130,
            },
            {
              title: "이름",
              editable: "onUpdate",
              field: "name",
              width: 100,
            },
            {
              title: "이메일",
              editable: "never",
              field: "email",
            },
            {
              title: "요청중인 컨설팅(건)",
              editable: "never",
              type: "numeric",
              field: "ongoingRequestCase",
              width: 110,
              filtering: false,
            },
            {
              title: "요청취소 컨설팅(건)",
              editable: "onUpdate",
              type: "numeric",
              field: "cancelConsultingRequestCase",
              width: 110,
              filtering: false,
            },
            {
              title: "진행중인 컨설팅(건)",
              editable: "never",
              type: "numeric",
              field: "ongoingConsultingCase",
              width: 110,
              filtering: false,
            },
            {
              title: "완료된 컨설팅(건)",
              editable: "never",
              type: "numeric",
              field: "completedConsultingCase",
              width: 110,
              filtering: false,
            },
            {
              title: "연락처",
              editable: "never",
              field: "phone",
              width: 100,
            },
            {
              title: "연령대",
              editable: "never",
              field: "age",
              width: 100,
              filtering: false,
            },
            {
              title: "계정상태",
              editable: "onUpdate",
              field: "isAccountActive",
              width: 110,
              filtering: false,
              lookup: { true: "활성", false: "비활성" },
            },
            {
              title: "탈퇴여부",
              editable: "never",
              field: "isWithdrawUser",
              filtering: false,
              lookup: { true: "탈퇴회원", false: "정상회원" },
              width: 110,
            },
            {
              title: "가입일",
              editable: "never",
              filtering: false,
              render: (data) => Function.printDateDash(data.registrationDate.toDate()),
            },
            {
              title: "최근 로그인",
              editable: "never",
              filtering: false,
              render: (data) => Function.printDateDash(data.latestLoginDate.toDate()),
            },
            {
              title: "(전)컨설팅분야",
              editable: "never",
              field: "consultingGenre",
              lookup: {
                CG1: "시각예술",
                CG2: "공연예술",
                CG3: "문학",
                CG4: "문화일반",
                CG5: "기타",
              },
              width: 130,
            },
            {
              title: "(전)컨설팅내용",
              editable: "never",
              field: "consultingAreaText",
              width: 130,
            },
          ]}
          data={memberList}
          options={{
            search: true,
            pageSize: 20,
            pageSizeOptions: [5, 10, 20, 30, 50, 100, 200],
            exportButton: true,
            grouping: true,
            filtering: true,
          }}
          actions={[
            {
              icon: () => <AddCircleIcon />,
              tooltip: "회원정보 전체보기",
              onClick: (event, rowData) => history.push(`${Routes.USERINFOEDIT}?email=${rowData.email}`),
            },
          ]}
          localization={{
            ...siteConfig.tableSetLable,
            grouping: { placeholder: "정렬하고자 하는 컬럼을 드래그해주세요." },
            toolbar: {
              exportName: " CSV파일로 다운로드",
              nRowsSelected: "{0} 건이 선택되었습니다.",
            },
            header: { actions: "편집" },
          }}
          icons={{
            Add: AddCircleIcon,
            Check: CheckBoxIcon,
            Clear: ClearIcon,
            Delete: DeleteIcon,
            DetailPanel: AddCircleIcon,
            Edit: EditIcon,
            Export: GetAppIcon,
            Filter: SearchIcon,
            FirstPage: FirstPageIcon,
            LastPage: LastPageIcon,
            NextPage: NavigateNextIcon,
            PreviousPage: NavigateBeforeIcon,
            ResetSearch: ClearIcon,
            Search: SearchIcon,
            SortArrow: ArrowDownwardIcon,
            ThirdStateCheck: RemoveIcon,
            ViewColumn: ViewColumnIcon,
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                if (newData.position === "ARTIST") {
                  firebase
                    .firestore()
                    .collection("UsersCollection")
                    .doc(newData.email)
                    .update({
                      name: newData.name,
                      phone: newData.phone,
                      cancelConsultingRequestCase: Number(newData.cancelConsultingRequestCase),
                      isAccountActive: newData.isAccountActive === "true" || newData.isAccountActive === true,
                    })
                    .then(() => {
                      resolve();
                    })
                    .catch(() => {
                      reject();
                    });
                } else {
                  firebase
                    .firestore()
                    .collection("UsersCollection")
                    .doc(newData.email)
                    .update({
                      name: newData.name,
                      phone: newData.phone,
                      isAccountActive: newData.isAccountActive === "true" || newData.isAccountActive === true,
                    })
                    .then(() => {
                      resolve();
                    })
                    .catch(() => {
                      reject();
                    });
                }
              }),
          }}
        />
      </MuiThemeProvider>
      {isModal && <BestConsultantModal isModal={isModal} setIsModal={setIsModal} />}
    </>
  );
};

export default MembersManage;
