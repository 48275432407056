import { defaultFont } from "../../material-kit-react";

import tooltip from "../tooltipsStyle";

const headerLinksStyle = (theme) => ({
  list: {
    ...defaultFont,
    fontSize: "18px",
    fontWeight: "bold",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit",
    [theme.breakpoints.down("md")]: {
      marginTop: "36px",
    },
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        // backgroundColor: "#e5e5e5",
      },
    },
  },
  mobileList: {
    [theme.breakpoints.down("md")]: {
      // marginTop: "auto",
      width: "calc(100% - 48px)",
      position: "absolute",
      bottom: "20px",
      display: "flex",
      flexDirection: "row-reverse",
    },
  },
  listItemText: {
    padding: "0 !important",
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "700",
    fontSize: "18px",
    fontFamily: "'Noto Sans KR', sans-serif !important",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)",
    },
    [theme.breakpoints.down("md")]: {
      // width: "calc(100% - 30px)",
      // marginLeft: "15px",
      // marginBottom: "8px",
      // marginTop: "8px",
      textAlign: "right",
      "& > span:first-child": {
        justifyContent: "flex-end",
      },
      padding: "18.5px 15px 21.5px 15px",
    },
  },
  rightNavLink: {
    fontSize: "16px",
    fontWeight: "400",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      fontWeight: "700",
    },
  },
  mobileRight: {
    [theme.breakpoints.down("md")]: {
      textAlign: "right",
    },
  },
  linkSelected: {
    color: "#6FBA2C !important",
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    top: "4px",
  },
  registerNavLink: {
    top: "3px",
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  navLinkActive: {
    color: "inherit",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "3px",
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
      padding: "10px 20px",
    },
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px",
  },
});

export default headerLinksStyle;
