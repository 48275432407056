import React, { useContext, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField } from 'formik-material-ui';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as Yup from 'yup';
import { Field, Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';
import * as SiteConfig from '../../Constants/SiteConfig';
import FirebaseContext from '../../Context/Firebase';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';
import SubLink from './SubLink';
import JoinPageLink from './JoinPageLink';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: SiteConfig.mainColor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

const FindAuth = (props) => {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('이름을 입력해주세요'),
    phone: Yup.number()
      .required('휴대폰번호를 입력해주세요')
      .typeError('휴대폰번호는 숫자만 입력해주세요.'),
    email: Yup.string()
      .email('이메일 형식으로 입력해주세요')
      .required('이메일을 입력해주세요'),
  });


  const onSubmit = (values) => {
    setIsLoading(true);
    const { name, phone, email } = values;
    firebase.firestore().collection('UsersCollection')
      .where('name', '==', name)
      .where('phone', '==', phone)
      .where('email', '==', email)
      .get()
      .then((result) => {
        if (result.docs.length > 0) {
          result.forEach((doc) => {
            firebase.auth().sendPasswordResetEmail(doc.data().email).then(() => {
              setIsLoading(false);
              window.alert('입력하신 이메일 계정으로 비밀번호 재설정 메일이 전송되었습니다.');
            }).catch(() => {
              setIsLoading(false);
              window.alert('오류가 발생하였습니다.');
            });
          });
        } else {
          setIsLoading(false);
          window.alert('입력하신 정보와 일치하는 계정이 없습니다.');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <Box style={{ minHeight: '100vh', height: '100%' }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Find Password
          </Typography>
          <Typography variant="body2">
            비밀번호 찾기
          </Typography>
          <Formik
            initialValues={{ email: '', name: '', phone: '' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form className={classes.form}>
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="이메일을 입력해주세요"
                  name="email"
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="이름을 입력해주세요"
                  name="name"
                />

                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="연락처를 입력해주세요"
                  name="phone"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size="large"
                >
                  비밀번호 찾기
                </Button>
              </Form>
            )}
          </Formik>
          <SubLink
            viewComponent={props.viewComponent}
            setViewComponent={props.setViewComponent}
          />
        </div>
        <JoinPageLink />
        <LoadingOverlay
          isLoading={isLoading}
        />
      </Container>
    </Box>
  );
};


export default FindAuth;
