import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import RemoveIcon from "@material-ui/icons/Remove";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import moment from "moment";
import { CsvBuilder } from "filefy";
import * as siteConfig from "../../Constants/SiteConfig";
import * as Function from "../../Utill/Functions";
import FirebaseContext from "../../Context/Firebase";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import "moment/locale/ko";
import ConsultingManageDetail from "./ConsultingMangeDetail";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: siteConfig.mainColor,
    },
    secondary: {
      main: siteConfig.mainColor,
    },
  },
});
const ConsultingManage = () => {
  const firebase = useContext(FirebaseContext);
  const [consultingList, setConsultingList] = useState(null);
  const [initData, setInitData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const today = moment(new Date());
  const firstDay = moment(new Date()).startOf("month");
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(today);
  const [termKeyword, setTermKeyword] = React.useState("");

  const viewAlldata = () => {
    setIsLoading(true);
    setConsultingList(initData);
    setIsLoading(false);
  };

  const searchTerm = () => {
    if (termKeyword === "") {
      alert("검색기준을 선택해주세요");
    } else {
      setIsLoading(true);
      const fromDate = firebase.firestore.Timestamp.fromDate(startDate.toDate());
      const toDate = firebase.firestore.Timestamp.fromDate(endDate.toDate());
      firebase
        .firestore()
        .collection("ConsultingList")
        .orderBy(termKeyword, "desc")
        .where(termKeyword, ">=", fromDate)
        .where(termKeyword, "<=", toDate)
        .get()
        .then((result) => {
          const resultData = [];
          result.forEach((doc) => {
            resultData.push(doc.data());
          });
          setConsultingList(resultData);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = firebase
      .firestore()
      .collection("ConsultingList")
      .onSnapshot((result) => {
        const resultData = [];
        result.forEach((doc) => {
          resultData.push({
            ...doc.data(),
            consultingRequestDateSort: doc.data().consultingRequestDate.toDate().getTime(),
          });
        });

        setConsultingList(resultData);
        setInitData(resultData);
        setIsLoading(false);
      });
    return () => unsubscribe();
  }, []);

  const handleChange = (event) => {
    setTermKeyword(event.target.value);
  };
  const exportCsv = (allColumns, allData) => {
    const columns = allColumns.filter((columnDef) => columnDef["export"] !== false);
    const exportedData = allData.map((rowData) =>
      columns.map((columnDef) => {
        switch (columnDef.title) {
          case "컨설팅 진행상황":
            return siteConfig.consultingProcess[rowData[columnDef.field]];
            break;
          case "컨설팅 요청날짜":
            return Function.printDateDash(rowData.consultingRequestDate.toDate());
            break;
          case "컨설팅 시작일":
            return rowData.consultingStartDate && Function.printDateDash(rowData.consultingStartDate.toDate());
            break;
          case "컨설팅 종료일":
            return rowData.consultingEndDate && Function.printDateDash(rowData.consultingEndDate.toDate());
            break;
          case "컨설팅 분야":
            return siteConfig.consultingSubCatepory[rowData[columnDef.field]];
            break;
          case "예술인 분야":
            return siteConfig.ArtistAreaToText[rowData[columnDef.field]];
            break;
          default:
            return rowData[columnDef.field];
        }
      })
    );
    new CsvBuilder("consultingData" + moment().format("YYYY-MM-DDTHHmmss"))
      .setColumns(columns.map((columnDef) => columnDef.title))
      .addRows(exportedData)
      .exportFile();
  };

  return isLoading ? (
    <LoadingOverlay isLoading={isLoading} />
  ) : (
    <>
      <div>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="ko">
          <Grid container justify="flex-end" spacing={4}>
            <Grid item>
              <FormControl className="dateCategoryItem">
                <InputLabel id="demo-simple-select-label">기간검색</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={termKeyword} onChange={handleChange}>
                  <MenuItem value="consultingRequestDate">컨설팅 요청일</MenuItem>
                  <MenuItem value="consultingStartDate">컨설팅 시작일</MenuItem>
                  <MenuItem value="consultingEndDate">컨설팅 종료일</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD(dd)"
                margin="normal"
                id="date-picker-from"
                label="시작 날짜"
                value={startDate}
                onChange={setStartDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD(dd)"
                margin="normal"
                id="date-picker-to"
                label="종료 날짜"
                value={endDate}
                onChange={setEndDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item>
              <button className="consultingSearchBtn mt25 mr10" onClick={() => searchTerm()}>
                검색하기
              </button>
              <button className="consultingSearchBtn mt25" onClick={() => viewAlldata()}>
                전체보기
              </button>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title="컨설팅관리"
          columns={[
            {
              title: "컨설팅 진행상황",
              field: "consultingState",
              lookup: siteConfig.consultingProcess,
            },
            {
              title: "컨설턴트",
              field: "consultantName",
            },
            {
              title: "예술인",
              field: "artistName",
            },
            {
              title: "컨설팅 요청날짜",
              render: (data) => Function.printDateDash(data.consultingRequestDate.toDate()),
              field: "consultingRequestDateSort",
              defaultSort: "asc",
            },
            {
              title: "컨설팅 시작일",
              field: "consultingStartDate",
              render: (data) => data.consultingStartDate && Function.printDateDash(data.consultingStartDate.toDate()),
            },
            {
              title: "컨설팅 종료일",
              field: "consultingEndDate",
              render: (data) => data.consultingEndDate && Function.printDateDash(data.consultingEndDate.toDate()),
            },
            {
              title: "컨설팅 분야",
              editable: "never",
              field: "consultingMainCatepory",
              lookup: {
                C1: "법률",
                C2: "회계",
                C3: "노무",
                C4: "홍보",
                C5: "상담",
                C6: "문화예술",
                C7: "법무", 
              },
              width: 130,
            },
            {
              title: "컨설팅 세부분야",
              editable: "never",
              field: "consultingSubCatepory",
              lookup: {
                CG1: "법률",
                CG2: "법률 - 저작권",
                CG3: "회계",
                CG4: "노무",
                CG5: "홍보",
                CG6: "상담 - 심리",
                CG7: "상담 - 성희롱성폭력",
                CG8: "기획",
                CG9: "기획 - 문학",
                CG10: "기획 - 시각",
                CG11: "기획 - 공연",
                CG12: "기획 - 영화",
                CG13: "기획 - 문화일반",
                CG14: "창작",
                CG15: "창작 - 문학",
                CG16: "창작 - 시각",
                CG17: "창작 - 공연",
                CG18: "창작 - 영화",
                CG19: "창작 - 문화일반",
                CG20: "창업",
                CG21: "기술",
                CG22: "법무",
              },
              width: 130,
            },
            {
              title: "예술인 분야",
              field: "artistArea",
              lookup: { A1: "시각예술", A2: "공연예술", A3: "문학" },
              width: 130,
            },
            {
              title: "(전)컨설팅 분야",
              field: "consultingGenre",
              lookup: {
                CG1: "창작분야 전문상담",
                CG2: "예술기획",
                CG3: "창작기술지원",
                CG4: "예술창업",
                CG5: "홍보 및 마케팅",
                CG6: "저작권",
                CG7: "법률",
                CG8: "회계",
                CG9: "성희롱 성폭력",
                CG10: "민간컨설턴트",
                CG11: "노무",
                CG12: "심리상담",
              },
            },
            {
              title: "(전)컨설팅 내용",
              field: "consultingAreaText",
            },
          ]}
          data={consultingList}
          options={{
            search: true,
            pageSize: 20,
            pageSizeOptions: [5, 10, 20, 30, 50, 200],
            exportButton: true,
            grouping: true,
            filtering: true,
            exportCsv,
            sorting: true,
          }}
          localization={{
            ...siteConfig.tableSetLable,
            grouping: { placeholder: "정렬하고자 하는 컬럼을 드래그해주세요." },
            toolbar: { exportName: " CSV파일로 다운로드", nRowsSelected: "{0} 건이 선택되었습니다." },
            header: { actions: "편집" },
          }}
          icons={{
            Add: AddCircleIcon,
            Check: CheckBoxIcon,
            Clear: ClearIcon,
            Delete: DeleteIcon,
            DetailPanel: AddCircleIcon,
            Edit: EditIcon,
            Export: GetAppIcon,
            Filter: SearchIcon,
            FirstPage: FirstPageIcon,
            LastPage: LastPageIcon,
            NextPage: NavigateNextIcon,
            PreviousPage: NavigateBeforeIcon,
            ResetSearch: ClearIcon,
            Search: SearchIcon,
            SortArrow: ArrowDownwardIcon,
            ThirdStateCheck: RemoveIcon,
            ViewColumn: ViewColumnIcon,
          }}
          detailPanel={(rowData) => <ConsultingManageDetail item={rowData} />}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </MuiThemeProvider>
    </>
  );
};

export default ConsultingManage;
