import React, { useContext, useEffect, useState } from 'react';
import AuthenticationContext from '../../Context/Authentication';
import ArtistMyPage from './Artist/ArtistMyPage';
import ConsultantMyPage from './Consultant/ConsultantMyPage';
import FirebaseContext from '../../Context/Firebase';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';

const Index = () => {
  const authUser = useContext(AuthenticationContext);
  const firebase = useContext(FirebaseContext);

  const [displayConsulting, setDisplayConsulting] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const authUserEmailCategory = authUser.position === 'ARTIST' ? 'artistEmail' : 'consultantEmail';


  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const unsubscribe = firebase.firestore().collection('ConsultingList')
      .where(authUserEmailCategory, '==', authUser.email)
      .onSnapshot((result) => {
        const resultData = [];
        result.forEach((doc) => {
          const docData = doc.data();
          resultData.push({
            ...docData,
            id: doc.id,
          });
        });
        setDisplayConsulting(resultData);
        setIsLoading(false);
      });
    return () => {
      unsubscribe();
    };
  }, []);


  return isLoading ? (
    <LoadingOverlay
      isLoading={isLoading}
    />
  ) : (authUser && authUser.position === 'ARTIST' && authUserEmailCategory === 'artistEmail'
    ? <ArtistMyPage displayConsulting={displayConsulting} />
    : <ConsultantMyPage displayConsulting={displayConsulting} />
  );
};
export default Index;
