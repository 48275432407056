export const ages = [
  {
    value: "10대",
    label: "10대",
  },
  {
    value: "20대",
    label: "20대",
  },
  {
    value: "30대",
    label: "30대",
  },
  {
    value: "40대",
    label: "40대",
  },
  {
    value: "50대",
    label: "50대",
  },
  {
    value: "60대",
    label: "60대",
  },
  {
    value: "70대이상",
    label: "70대이상",
  },
];

// 대분류
export const consultingGenre = [
  {
    value: "CG1",
    label: "창작분야 전문상담",
  },
  {
    value: "CG2",
    label: "예술기획",
  },
  {
    value: "CG3",
    label: "창작기술지원",
  },
  {
    value: "CG4",
    label: "예술창업",
  },
  {
    value: "CG5",
    label: "홍보 및 마케팅",
  },
  {
    value: "CG6",
    label: "저작권",
  },
  {
    value: "CG7",
    label: "법률",
  },
  {
    value: "CG8",
    label: "회계",
  },
  {
    value: "CG9",
    label: "성희롱 성폭력",
  },
  {
    value: "CG10",
    label: "민간컨설턴트",
  },
  {
    value: "CG11",
    label: "노무",
  },
  {
    value: "CG12",
    label: "심리상담",
  },
];

// 소분류
export const consultingArea = [
  {
    value: "C1",
    label: "시각예술",
  },
  {
    value: "C2",
    label: "공연예술",
  },
  {
    value: "C3",
    label: "문학",
  },
  {
    value: "C4",
    label: "문화일반",
  },
  {
    value: "C5",
    label: "기타",
  },
];

export const consultingMainCatepory = [
  {
    value: "C1",
    label: "법률",
  },
  {
    value: "C2",
    label: "회계",
  },
  {
    value: "C3",
    label: "노무",
  },
  {
    value: "C4",
    label: "홍보",
  },
  {
    value: "C5",
    label: "상담",
  },
  {
    value: "C6",
    label: "문화예술",
  },
  {
    value: "C7",
    label: "법무",
  },
];

export const consultingSubCatepory = [
  {
    value: "CG1",
    label: "법률",
  },
  {
    value: "CG2",
    label: "법률 - 저작권",
  },
  {
    value: "CG3",
    label: "회계",
  },
  {
    value: "CG4",
    label: "노무",
  },
  {
    value: "CG5",
    label: "홍보",
  },
  {
    value: "CG6",
    label: "상담 - 심리",
  },
  {
    value: "CG7",
    label: "상담 - 성희롱성폭력",
  },
  {
    value: "CG8",
    label: "기획",
  },
  {
    value: "CG9",
    label: "기획 - 문학",
  },
  {
    value: "CG10",
    label: "기획 - 시각",
  },
  {
    value: "CG11",
    label: "기획 - 공연",
  },
  {
    value: "CG12",
    label: "기획 - 영화",
  },
  {
    value: "CG13",
    label: "기획 - 문화일반",
  },
  {
    value: "CG14",
    label: "창작",
  },
  {
    value: "CG15",
    label: "창작 - 문학",
  },
  {
    value: "CG16",
    label: "창작 - 시각",
  },
  {
    value: "CG17",
    label: "창작 - 공연",
  },
  {
    value: "CG18",
    label: "창작 - 영화",
  },
  {
    value: "CG19",
    label: "창작 - 문화일반",
  },
  {
    value: "CG20",
    label: "창업",
  },
  {
    value: "CG21",
    label: "기술",
  },
  {
    value: "CG22",
    label: "법무",
  },
];
