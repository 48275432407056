import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import Typography from "@material-ui/core/Typography";
import styles from "../../assets/jss/material-kit-react/views/componentsSections/javascriptStyles";
import Button from "../../Components/initComponents/CustomButtons/Button";
import Card from "../../Components/initComponents/Card/Card";
import CardBody from "../../Components/initComponents/Card/CardBody";
import Firebase from "../../Context/Firebase";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import featuresStyle from "../ConsultantList/featuresStyle";

const useStyles = makeStyles({
  ...styles,
  modal: {
    ...styles.modal,
    width: "900px",
  },
});
const featureStyles = makeStyles(featuresStyle);
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

Transition.displayName = "Transition";

const QnaAnswerModal = (props) => {
  const { isModal, closeModal, selectedItem } = props;
  const classes = useStyles();
  const featureClasses = featureStyles();
  const [isLoading, setIsLoading] = useState(false);
  const firebase = useContext(Firebase);

  const validationSchema = Yup.object().shape({
    questionAnswer: Yup.string()
      .max(1000, "1000자 이내로 입력해주세요.")
      .required("문의사항에 대한 답글을 입력해주세요."),
  });

  const onSubmit = (value) => {
    setIsLoading(true);
    closeModal();

    firebase
      .firestore()
      .collection("InquiryList")
      .doc(selectedItem.id)
      .update({
        isAnswer: true,
        questionAnswer: value.questionAnswer,
      })
      .then(() => {
        firebase
          .firestore()
          .collection("UsersCollection")
          .doc(selectedItem.questionUserEmail)
          .collection("MyInquiryList")
          .doc(selectedItem.id)
          .update({
            isAnswer: true,
            questionAnswer: value.questionAnswer,
          });
      });
    setIsLoading(false);
  };

  return (
    selectedItem && (
      <>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={isModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => closeModal()}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => closeModal()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
          </DialogTitle>

          <DialogContent
            id="classic-modal-slide-description"
            className={featureClasses.modalBody}
          >
            <Card profile className="boxShadowNone">
              <CardBody>
                <Typography variant="h5">
                  {selectedItem.questionUserName}
                </Typography>
                <pre className={featureClasses.cardDescription}>
                  {selectedItem.questionText}
                </pre>
              </CardBody>
            </Card>

            <Formik
              initialValues={{
                questionAnswer: selectedItem.questionAnswer || "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {() => (
                <Form>
                  <Field
                    disabled={false}
                    component={TextField}
                    name="questionAnswer"
                    placeholder="문의사항에 대한 답글을 작성해주세요"
                    type="textarea"
                    label="문의사항에 대한 답글을 작성해주세요"
                    id="outlined-multiline-flexible"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                  />
                  <Box p={2} className="tac">
                    <Button color="primary" type="submit">
                      답글등록하기
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => closeModal()} color="primary" simple>
              닫기
            </Button>
          </DialogActions>
        </Dialog>
        <LoadingOverlay isLoading={isLoading} />
      </>
    )
  );
};

export default QnaAnswerModal;
