import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  TextField,
} from 'formik-material-ui';
import {
  MenuItem,
} from '@material-ui/core';
import {
  Field, Formik, Form,
} from 'formik';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import * as siteConfig from '../../Constants/SiteConfig';
import * as Routes from '../../Constants/Routes';
import FirebaseContext from '../../Context/Firebase';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';
import * as SelectItems from '../ArtistJoin/SelectItem';
import WithdrawalModal from './WithdrawalModal';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: siteConfig.mainColor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));


const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('이메일 형식으로 입력해주세요')
    .required('이메일을 입력해주세요'),
  name: Yup.string()
    .required('이름을 입력해주세요'),
  phone: Yup.number()
    .typeError('연락처는 숫자만 입력해주세요.')
    .required('연락처를 입력해주세요'),
  age: Yup.string()
    .required('연령대를 선택해주세요')
    .oneOf(['10대', '20대', '30대', '40대', '50대', '60대', '70대이상'], '연령대를 선택해주세요'),
  artistArea: Yup.string()
    .required('활동분야를 선택해주세요')
    .oneOf(['A1', 'A2', 'A3'], '예술인 분야를 선택해주세요'),
  IsArtistCertification: Yup.string()
    .required('예술인활동증명 완료여부를 선택해주세요 '),
});


const ArtistMembership = (props) => {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [isModal, setIsModal] = useState(false);

  const openModal = () => {
    setIsModal(true);
  };
  const closeModal = () => {
    setIsModal(false);
  };

  const initialValues = {
    email: props.authUser.email,
    name: props.authUser.name,
    phone: props.authUser.phone,
    age: props.authUser.age,
    artistArea: props.authUser.artistArea,
    IsArtistCertification: props.authUser.IsArtistCertification,
  };
  const onSubmit = (values) => {
    setIsLoading(true);
    const {
      email, phone, age, artistArea, IsArtistCertification,
    } = values;
    firebase.firestore().collection('UsersCollection')
      .doc(email)
      .update({
        phone: phone.replace(/ /gi, ''),
        age,
        artistArea,
        IsArtistCertification,
      })
      .then(() => {
        window.alert('회원정보가 정상적으로 수정되었습니다.');
        setIsLoading(false);
        if (props.adminMode) {
          history.push(`${Routes.ADMIN}?tab=2`);
        } else {
          history.push(Routes.INDEX);
        }
      })
      .catch((e) => {
        console.log('membershipUpdateError', e);
      });
  };

  return (
    <Box mb={10}>
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <HowToRegIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Artist Membership
          </Typography>
          <Typography variant="body2">
            예술인 회원정보수정 페이지
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form className={classes.form}>
                <Field
                  disabled
                  component={TextField}
                  variant="outlined"
                  name="email"
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled
                  component={TextField}
                  variant="outlined"
                  name="name"
                  type="text"
                  label="이름"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="phone"
                  type="text"
                  label="연락처"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="age"
                  variant="outlined"
                  margin="normal"
                  label="연령대"
                  fullWidth
                >
                  {SelectItems.ages.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>

                <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="artistArea"
                  label="활동분야"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                >
                  {SelectItems.artistArea.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>

                <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="IsArtistCertification"
                  label="예술인활동증명 유무"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                >
                  {SelectItems.IsartistCertification.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size="large"
                >
                  예술인 회원정보 수정하기
                </Button>
              </Form>
            )}
          </Formik>
          <Button onClick={() => openModal()}>
            계정탈퇴
          </Button>
        </div>

        {isModal
        && (
          <WithdrawalModal
            loginedUserInfo={props.authUser}
            isModal={isModal}
            closeModal={closeModal}
          />
        )}

        <LoadingOverlay
          isLoading={isLoading}
        />
      </Container>
    </Box>
  );
};


export default ArtistMembership;
