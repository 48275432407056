import React, {
  useContext,
} from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Button from '../../../Components/initComponents/CustomButtons/Button';
import FirebaseContext from '../../../Context/Firebase';
import AuthenticationContext from '../../../Context/Authentication';
import CommonConsultingProcessData from '../CommonConsultingProcessData';
import * as Routes from '../../../Constants/Routes';
import * as SendNotification from '../../../Utill/SendNotification';
import * as siteConfig from '../../../Constants/SiteConfig';


const useStyles = makeStyles({
  titleCell: {
    paddingLeft: '48px',
    width: '220px',
  },
  iconLocation: {
    position: 'relative',
    top: '7px',
    marginRight: '7px',
  },
});


export default function ArtistDetailTable(props) {
  const classes = useStyles();
  const {
    displayConsulting,
    closeModal,
  } = props;
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthenticationContext);


  const cancelRequest = () => {
    firebase.firestore().collection('UsersCollection')
      .doc(authUser.email)
      .get()
      .then((result) => {
        const loginedUserInfo = result.data();
        if (loginedUserInfo.cancelConsultingRequestCase >= siteConfig.cancelConsultingRequestLimitCase) {
          alert('컨설팅 요청 취소 제한 건수를 초과 하였습니다. 관리자에게 문의해주세요.');
          return false;
        }
        if (window.confirm('컨설팅 요청을 취소하시겠습니까?\n컨설팅 요청 취소는 최대 5회까지 가능합니다.')) {
          firebase.firestore().collection('ConsultingList')
            .doc(displayConsulting.id)
            .update({
              consultingState: 2,
              reRequestConsulting: true,
            });

          firebase.firestore().collection('UsersCollection')
            .doc(displayConsulting.artistEmail)
            .update({
              ongoingRequestCase: firebase.firestore.FieldValue.increment(-1),
              cancelConsultingRequestCase: firebase.firestore.FieldValue.increment(+1),
            });

          const sendInfo = {
            artistEmail: loginedUserInfo.email,
            consultantEmail: displayConsulting.consultantEmail,
            artistName: loginedUserInfo.name,
            consultantName: displayConsulting.consultantName,
          };
          SendNotification.sendEmail(3, sendInfo);
          closeModal();
        } else {
          return false;
        }
      });
  };


  return (
    <>
      <TableContainer component={Paper}>
        <Table
          style={{
            maxWidth: '450px',
            minWidth: '430px',
            width: '100%',
            overflow: 'auto',
          }}
          aria-label="simple table"
        >
          <TableBody>
            <TableRow style={{ border: 'none' }}>
              <TableCell className={classes.titleCell}>
                <AccountBoxIcon className={classes.iconLocation} />
                담당 컨설턴트
              </TableCell>
              <TableCell>
                {displayConsulting.consultantName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.titleCell}>
                <HowToRegOutlinedIcon className={classes.iconLocation} />
                컨설팅 분야
              </TableCell>
              <TableCell>
                {siteConfig.ConsultingGenreToText[displayConsulting.consultingGenre]}
                <br/>
                {displayConsulting.consultingAreaText}

              </TableCell>
            </TableRow>
            <CommonConsultingProcessData
              displayConsulting={displayConsulting}
            />

            {displayConsulting.consultingState === 1
              ? (
                <TableRow>
                  <TableCell className={classes.titleCell}>
                    <CancelIcon className={classes.iconLocation} />
                    컨설팅 요청취소
                  </TableCell>
                  <TableCell>
                    <Button color="rose" onClick={cancelRequest}>
                      취소하기
                    </Button>
                  </TableCell>
                </TableRow>
              )
              : ''}

            {displayConsulting.consultingState === 6 && displayConsulting.consultingSurveyResult === ''
              ? (
                <TableRow>
                  <TableCell className={classes.titleCell}>
                    <BorderColorIcon className={classes.iconLocation} />
                    설문조사
                  </TableCell>
                  <TableCell>
                    <Link to={`${Routes.SURVEY}/${displayConsulting.id}`}>
                      <Button className="blinking" color="facebook">
                        설문조사하기
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              )
              : ''}
            {displayConsulting.consultingSurveyResult
          && (
            <TableRow>
              <TableCell className="myConTbTitle" align="center">설문조사</TableCell>
              <TableCell>
                <Button color="facebook">
                  설문조사완료
                </Button>
              </TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
}
