import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";

import styles from "../../assets/jss/material-kit-react/views/componentsSections/javascriptStyles";
import Button from "../../Components/initComponents/CustomButtons/Button";
import Card from "../../Components/initComponents/Card/Card";
import CardHeader from "../../Components/initComponents/Card/CardHeader";
import CardBody from "../../Components/initComponents/Card/CardBody";

import featuresStyle from "../ConsultantList/featuresStyle";

const useStyles = makeStyles(styles);
const featureStyles = makeStyles(featuresStyle);
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

Transition.displayName = "Transition";

const PersonalTermModal = (props) => {
  const { isModal, setIsModal } = props;

  const classes = useStyles();
  const featureClasses = featureStyles();

  return (
    <>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={isModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setIsModal(false)}
          >
            <Close className={classes.modalClose} />
          </IconButton>
        </DialogTitle>

        <DialogContent
          id="classic-modal-slide-description"
          className={featureClasses.modalBody}
        >
          <Card profile className="boxShadowNone">
            <CardHeader image>
              <div className={featureClasses.cardTitleAbsolute} />
            </CardHeader>
            <CardBody>
              <h4 className="fb">
                개인정보처리방침
                <br />
                개인정보 수집 및 이용에 대한 동의
              </h4>

              <br />
              <p
                style={{
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                (부산예술인복지지원센터 아이컨택 개인정보 처리방침)
                <br />
                부산예술인복지지원센터(이하‘센터’)는 「개인정보보호법」 제
                30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을
                안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록
                다음과 같이 처리방침을 수립·공개합니다. 이 방침은 별도의 설명이
                없는 한 (재)부산문화재단 개인정보 처리방침에 의거하여
                적용됩니다.
                <br />
                <br />
                <br />
                제1조(개인정보 처리목적)
                <br />
                1. 센터는 개인정보를 센터 내 진행되는 서비스 제공의 목적으로
                수집·보유하고 있습니다.
                <br />
                2. 수집한 개인정보는 수집 목적 이외의 용도로는 사용되지 않으며,
                이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라
                별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                <br />
                <br />
                <br />
                제2조(개인정보 처리 및 보유기간)
                <br />
                1. 센터는 개인정보 보호법 및 관련 법령에서 정하는 보유·이용기간
                또는 정보주체로부터 개인정보를 수집 시 동의받은 개인정보
                보유·이용기간 내에서 개인정보를 처리합니다.
                <br />
                2. 센터에서 보유하고 있는 개인정보 처리 및 보유기간의 조회
                방법은 다음과 같습니다.
                <br />
                <span>
                  ▷개인정보 처리 및 보유기간:{" "}
                  <a
                    className={classes.link}
                    target="_blank"
                    href="https://www.privacy.go.kr/front/wcp/dcl/per/personalInfoFileSrhList.do"
                  >
                    개인정보파일명 보기
                  </a>
                </span>
                <br />
                <br />
                제3조(처리하는 개인정보 항목)
                <br />
                1. 센터는 개인정보 수집·보유 목적에 필요한 최소한의 정보를
                수집·처리하고 있습니다.
                <br />
                2. 센터에서 운영하는 서비스의 이용과정에서 법령의 규정과
                정보주체의 동의에 의해서만 개인정보를 수집·보유하고 있으며,
                수집된 개인정보 항목의 열람방법은 다음과 같습니다.
                <br />
                <span>
                  ▷개인정보 처리 및 보유기간:{" "}
                  <a
                    className={classes.link}
                    target="_blank"
                    href="https://www.privacy.go.kr/front/wcp/dcl/per/personalInfoFileSrhList.do"
                  >
                    개인정보파일명 보기
                  </a>
                </span>
                <br />
                <br />
                제4조(개인정보 파기)
                <br />
                1. 센터는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
                불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
                <br />
                2. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                <br />
                ① 파기절차
                <br />
                센터는 파기사유가 발생한 개인정보를 선정하고 파기계획을
                수립하여, 개인정보 보호책임자의 승인을 받아 개인정보를
                파기합니다. 개인정보 보호책임자는 개인정보 파기 시행 후 파기
                결과를 확인하고 개인정보파일 파기 관리대장을 작성합니다.
                <br />
                ② 파기방법
                <br />
                센터는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할
                수 없도록 파기하며, 종이 문서에 기록된 개인정보는 분쇄기로
                분쇄하거나 소각하여 파기합니다.
                <br />
                <br />
                <br />
                제5조(개인정보 열람청구)
                <br />
                1. 정보주체는 센터에 대한 개인정보 열람 청구를 아래의 부서에 할
                수 있으며, 센터는 정보주체의 개인정보 열람청구가 신속하게
                처리되도록 노력하겠습니다.
                <br />
                ▶ 개인정보 열람청구 접수·처리부서
                <br />
                접수 부서명 : 예술지원팀(예술인복지지원센터)
                <br />
                접수 담당자 : 개인정보 처리자
                <br />
                접수 연락처 : 051-745-7239
                <br />
                <span>
                  2. 정보주체는 제1항의 열람청구 접수·처리부서 이외에,
                  개인정보보호위원회의‘개인정보보호 포털’ 웹사이트
                  <a
                    className={classes.link}
                    target="_blank"
                    href="https://www.privacy.go.kr"
                  >
                    (www.privacy.go.kr)
                  </a>
                  를 통하여서도 개인정보 열람청구를 하실 수 있습니다.
                  <br />▶ 개인정보보호위원회 개인정보보호 포털 ▷ 개인서비스 ▷
                  정보주체 권리행사 ▷개인정보 열람등요구 신청(본인확인을 위하여
                  아이핀(I-PIN) 등이 있어야 함)
                </span>
                <br />
                <br />
                제6조(권익침해 구제방법)
                <br />
                1. 정보주체는 아래의 기관을 통하여 개인정보 침해에 대한
                피해구제, 상담 등을 신청하실 수 있습니다.
                <br />
                아래의 기관은 센터 및 재단과는 별개의 기관으로서, 센터의
                자체적인 개별정보 불만 처리, 피해구제 결과에 만족하지 못하시거나
                보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
                <br />
                <br />
                <br />
                ▷개인정보 침해신고센터 (개인정보보호위원회, 한국인터넷진흥원
                운영)
                <br />
                소관업무 : 개인정보 침해사실 신고, 상담 신청
                <br />
                홈페이지 : privacy.kisa.or.kr
                <br />
                전화 : (국번없이) 118
                <br />
                ▷개인정보 분쟁조정위원회
                <br />
                소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
                <br />
                홈페이지 : www.kopico.go.kr
                <br />
                전화 : (국번없이) 1833-6972
                <br />
                ▷대검찰청 : (국번없이) 1301, www.spo.go.kr
                <br />
                ▷경찰청 : (국번없이) 182, ecrm.cyber.go.kr
                <br />
                <br />
                <br />
                제7조(개인정보 보호책임자)
                <br />
                센터는 개인정보를 보호하고 개인정보 관련 상담과 불만⋅피해구제
                또는 고충 등을 처리하기 위하여 아래와 같이 개인정보 처리자를
                지정하고 있습니다.
                <br />
                <br />
                <table className={classes.table}>
                  <tr className={classes.table}>
                    <th className={classes.table}>구 분</th>
                    <th className={classes.table}>부서(직위) · 성명</th>
                    <th className={classes.table}>연락처</th>
                  </tr>
                  <tr className={classes.table}>
                    <td className={classes.table}>개인정보 보호책임자</td>
                    <td className={classes.table}>기획경영실장 명지정</td>
                    <td rowspan="2" className={classes.table}>
                      전화: 051-745-7220,7223
                      <br />
                      메일: wldms2465@bscf.or.kr
                    </td>
                  </tr>
                  <tr className={classes.table}>
                    <td className={classes.table}>개인정보 보호책임자</td>
                    <td className={classes.table}>기획홍보팀 사원 한지은</td>
                  </tr>
                  <tr className={classes.table}>
                    <td className={classes.table}>개인정보 처리자</td>
                    <td className={classes.table}>예술지원팀 과장 구선미</td>
                    <td className={classes.table}>
                      전화: 051-745-7239
                      <br />
                      메일: reve0801@bscf.or.kr
                    </td>
                  </tr>
                </table>
                <br />
              </p>
            </CardBody>
          </Card>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setIsModal(false)} color="primary" simple>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PersonalTermModal;
