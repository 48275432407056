import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import AuthenticationContext from '../../Context/Authentication';
import Firebase from '../../Context/Firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function InquiryList(props) {
  const { displayQna } = props;
  const classes = useStyles();
  const authUser = useContext(AuthenticationContext);
  const firebase = useContext(Firebase);
  const deleteItem = (item) => {
    firebase.firestore().collection('InquiryList')
      .doc(item)
      .delete()
      .then(() => {
        firebase.firestore().collection('UsersCollection')
          .doc(authUser.email)
          .collection('MyInquiryList')
          .doc(item)
          .delete()
          .then((r) => {
            console.log('deleteInquiry', r);
          });
      });
  };
  return (
    <div className={classes.root}>

      {
        displayQna && (
          <>
            {displayQna.map((item) => (
              <div key={item.docId}>
                <Alert
                  className="grayborder"
                  severity="info"
                  icon={<HelpOutlineIcon color="action" />}
                  variant="outlined"
                  action={(
                    <DeleteOutlineIcon className="mr10 pointer gray" onClick={() => deleteItem(item.docId)} />
                  )}
                >
                  <AlertTitle>{item.questionText}</AlertTitle>
                  {
                    item.questionAnswer
                      ? <p>{item.questionAnswer}</p>
                      : <p>답변 대기중입니다.</p>
                  }

                </Alert>
              </div>
            ))}
          </>
        )
      }


    </div>
  );
}
