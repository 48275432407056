import React, { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Box from '@material-ui/core/Box';

import BannerAddModal from './BannerAddModal';
import BannerEditModal from './BannerEditModal';
import GridItem from '../../Components/initComponents/Grid/GridItem';
import GridContainer from '../../Components/initComponents/Grid/GridContainer';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';
import FirebaseContext from '../../Context/Firebase';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  controlBtn: {
    background: 'none',
    padding: '10px 20px',
    fontSize: '12px',
    marginRight: '10px',
    marginTop: '5px',
    cursor: 'pointer',
  },

}));

const BannerManage = () => {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const unsubscribe = firebase.firestore().collection('Banners')
      .onSnapshot((result) => {
        const resultData = [];
        result.forEach((doc) => {
          const docData = doc.data();
          resultData.push({
            ...docData,
            id: doc.id,
          });
        });

        setBannerList(resultData);
        setIsLoading(false);
      });
    return () => {
      unsubscribe();
    };
  }, []);

  const closeEditModal = () => {
    setIsEditModal(false);
    setSelectedItem(null);
  };
  const openEditModal = (targetItem) => {
    setSelectedItem(targetItem);
    setIsEditModal(true);
  };

  const deltetBanner = (value) => {
    setIsLoading(true);
    firebase.firestore().collection('Banners')
      .doc(value)
      .delete()
      .then(() => {
        setIsLoading(false);
      });
  };

  return !isLoading && (
    <>
      <div className={classes.container}>
        <GridContainer>
          {bannerList && bannerList.length > 0
            ? (
              bannerList.map((item) => (
                <GridItem xs={12} sm={6} md={3} key={item.id}>
                  <img src={item.BannerImgUrl} style={{ width: '100%' }} alt={item.BannerImgName} />
                  <button className={classes.controlBtn} onClick={() => deltetBanner(item.id)}>삭제</button>
                  <button className={classes.controlBtn} onClick={() => openEditModal(item)}>편집</button>
                </GridItem>
              ))
            )
            : (
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                현재 등록된 배너가 없습니다.
              </GridItem>
            )}
        </GridContainer>
      </div>
      <Box m={5} style={{ textAlign: 'center' }}>
        <Button variant="contained" color="Secondary" size="large" onClick={() => setIsModal(true)}>
          메인배너 등록하기
        </Button>
      </Box>
      {isModal
      && (
      <BannerAddModal
        isModal={isModal}
        setIsModal={setIsModal}
      />
      )}
      {isEditModal
      && (
        <BannerEditModal
          isModal={isEditModal}
          closeModal={closeEditModal}
          selectedItem={selectedItem}
        />
      )}
      <LoadingOverlay
        isLoading={isLoading}
      />
    </>
  );
};

export default BannerManage;
