import React, {useState, useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import GroupIcon from '@material-ui/icons/Group';
import {useLocation} from 'react-router-dom';
import StorageIcon from '@material-ui/icons/Storage';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Main from './Main';
import MembersManage from './MembersManage';
import ConsultingManage from './ConsultingManage';
import QnaManage from './QnaManage';
import BannerManage from './BannerManage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Index = () => {
  const query = useQuery();
  const classes = useStyles();
  const [nowMenu, setNowMenu] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (query.get('tab')) {
      setNowMenu(parseInt(query.get('tab')));
    } else {
      setNowMenu(3);
    }
    setIsLoading(false);
  }, [])
  return !isLoading && (
    <>
      {/*<Button*/}
      {/*  variant="contained"*/}
      {/*  color="primary"*/}
      {/*  size="medium"*/}
      {/*  className={classes.button}*/}
      {/*  startIcon={<DashboardIcon />}*/}
      {/*  onClick={() => setNowMenu(1)}*/}
      {/*>*/}
      {/*  메인*/}
      {/*</Button>*/}
      <Button
        variant="contained"
        color="primary"
        size="medium"
        className={classes.button}
        startIcon={<StorageIcon/>}
        onClick={() => setNowMenu(3)}
      >
        컨설팅 관리
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        className={classes.button}
        startIcon={<GroupIcon/>}
        onClick={() => setNowMenu(2)}
      >
        회원관리
      </Button>

      <Button
        variant="contained"
        color="primary"
        size="medium"
        className={classes.button}
        startIcon={<LibraryBooksIcon/>}
        onClick={() => setNowMenu(4)}
      >
        문의내역 관리
      </Button>

      <Button
        variant="contained"
        color="primary"
        size="medium"
        className={classes.button}
        startIcon={<StorageIcon/>}
        onClick={() => setNowMenu(5)}
      >
        배너 관리
      </Button>

      {/* <Button */}
      {/*  variant="contained" */}
      {/*  color="primary" */}
      {/*  size="medium" */}
      {/*  className={classes.button} */}
      {/*  startIcon={<SettingsApplicationsIcon />} */}
      {/*  onClick={() => setNowMenu(5)} */}
      {/* > */}
      {/*  사이트 설정관리 */}
      {/* </Button> */}

      <div className={classes.root}>
        <CssBaseline/>
        <main className={classes.content}>
          {nowMenu === 1 && <Main/>}
          {nowMenu === 2 && <MembersManage/>}
          {nowMenu === 3 && <ConsultingManage/>}
          {nowMenu === 4 && <QnaManage/>}
          {nowMenu === 5 && <BannerManage/>}
          {/* {nowMenu === 5 && <SiteSettingManage />} */}
        </main>
      </div>
    </>
  );
};


export default Index;
