import React, { useContext, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { MenuItem } from "@material-ui/core";
import { Field, Formik, Form, ErrorMessage } from "formik";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import * as Yup from "yup";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import Danger from "../../Components/initComponents/Typography/Danger";
import * as SiteConfig from "../../Constants/SiteConfig";
import FirebaseContext from "../../Context/Firebase";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import * as SelectItems from "./SelectItem";
import * as SendNotification from "../../Utill/SendNotification";
import * as Routes from "../../Constants/Routes";
import PersonalTermModal from "./PersonalTermModal";
import ServiceTermModal from "./ServiceTermModal";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: SiteConfig.mainColor,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  checkbox: {
    display: "flex",
    height: 20,
    margin: 15,
  },
  terms: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const initialValues = {
  email: "",
  name: "",
  phone: "",
  age: "",
  password: "",
  confirmPassword: "",
  artistArea: "",
  IsArtistCertification: "",
  serviceTerm: false,
  personalInformationTerm: false,
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("이메일 형식으로 입력해주세요")
    .required("이메일을 입력해주세요"),
  name: Yup.string().required("이름을 입력해주세요"),
  phone: Yup.number()
    .typeError("연락처는 숫자만 입력해주세요.")
    .required("연락처를 입력해주세요"),
  age: Yup.string()
    .required("연령대를 선택해주세요")
    .oneOf(
      ["10대", "20대", "30대", "40대", "50대", "60대", "70대이상"],
      "연령대를 선택해주세요"
    ),
  password: Yup.string()
    .min(6, "비밀번호는 6자 이상 입력해주세요")
    .max(15, "비밀번호는 15자 이하로 입력해주세요")
    .required("비밀번호를 입력해주세요"),
  confirmPassword: Yup.string()
    .required("비밀번호를 재입력해주세요")
    .oneOf([Yup.ref("password")], "입력하신 비밀번호와 일치하지 않습니다."),
  artistArea: Yup.string()
    .required("활동분야를 선택해주세요")
    .oneOf(["A1", "A2", "A3"], "예술인 분야를 선택해주세요"),
  IsArtistCertification: Yup.string().required(
    "예술인활동증명 완료여부를 선택해주세요 "
  ),
  serviceTerm: Yup.boolean().oneOf([true], "서비스 이용약관에 동의해주세요"),
  personalInformationTerm: Yup.boolean().oneOf(
    [true],
    "개인정보처리방침에 동의해주세요"
  ),
});

const ArtistJoin = () => {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isPersonalTermModal, setIsPersonalTermModal] = useState(false);
  const [isServiceTermModal, setIsServiceTermModal] = useState(false);
  const [isConsontantActiveTermModal, setIsConsontantActiveTermModal] =
    useState(false);
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onSubmit = (values) => {
    setIsLoading(true);
    const {
      name,
      email,
      password,
      phone,
      age,
      artistArea,
      IsArtistCertification,
    } = values;
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch(({ code: errorCode }) => {
        setIsLoading(false);
        if (errorCode === "auth/email-already-in-use") {
          window.alert("이미 가입된 이메일 계정이거나 탈퇴한 계정입니다.");
        } else if (errorCode === "auth/weak-password") {
          window.alert("입력하신 비밀번호가 적합하지 않습니다.");
        } else if (errorCode === "auth/invalid-email") {
          window.alert("입력하신 이메일 주소가 유효하지 않습니다.");
        } else {
          window.alert("오류가 발생하였습니다. 관리자에게 문의해주세요");
        }
      })
      .then((userCredentials) => {
        userCredentials.user.updateProfile({ displayName: name }).then(() => {
          firebase
            .firestore()
            .collection("UsersCollection")
            .doc(email)
            .set({
              uid: userCredentials.user.uid,
              name,
              email,
              phone: phone.replace(/ /gi, ""),
              age,
              position: "ARTIST",
              artistArea,
              IsArtistCertification,
              cancelConsultingRequestCase: 0,
              ongoingRequestCase: 0,
              ongoingConsultingCase: 0,
              completedConsultingCase: 0,
              isAccountActive: true,
              registrationDate: new Date(),
              latestLoginDate: new Date(),
              isWithdrawUser: false,
              withdrawalReason: "",
              userLevel: 1,
            })
            .then(() => {
              SendNotification.sendEmail(1, values);
              setIsLoading(false);
              firebase.auth().signInWithEmailAndPassword(email, password).then(()=>{
                window.location.reload();
              })
            })
            .catch((error) => {
              console.log("JoinError", error);
            });
        });
      })
      .catch(() => {});
  };
  const goConsultantJoin = () => {
    const isConfirm = window.confirm(
      "컨설턴트 회원가입 페이지로 이동하시겠습니까?"
    );
    if (isConfirm) history.push(Routes.CONSULTANTJOIN);
  };

  return (
    <Box mb={10}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <HowToRegIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Artist Sign Up
          </Typography>
          <Typography variant="body2">예술인 회원가입 페이지</Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form className={classes.form}>
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="email"
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="name"
                  type="text"
                  label="이름"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="phone"
                  type="tel"
                  label="연락처"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="password"
                  type="password"
                  label="비밀번호"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="confirmPassword"
                  type="password"
                  label="비밀번호재입력"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="age"
                  variant="outlined"
                  margin="normal"
                  label="연령대"
                  fullWidth
                >
                  {SelectItems.ages.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>

                <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="artistArea"
                  label="활동분야"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                >
                  {SelectItems.artistArea.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>

                <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="IsArtistCertification"
                  label="예술인활동증명 유무"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                >
                  {SelectItems.IsartistCertification.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>

                <div className={classes.checkbox}>
                  <Field
                    disabled={false}
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="serviceTerm"
                    // Label={{ label: "서비스이용약관 동의(필수)" }}
                  />
                  <span
                    className={classes.terms}
                    onClick={() => setIsServiceTermModal(true)}
                  >
                    서비스이용약관 동의(필수)
                  </span>

                  <Danger>
                    <ErrorMessage name="serviceTerm" />
                  </Danger>
                </div>
                <div className={classes.checkbox}>
                  <Field
                    disabled={false}
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="personalInformationTerm"
                    // Label={{ label: "개인정보처리방침 동의(필수)" }}
                  />
                  <span
                    className={classes.terms}
                    onClick={() => setIsPersonalTermModal(true)}
                  >
                    개인정보처리방침 동의(필수)
                  </span>

                  <Danger>
                    <ErrorMessage name="personalInformationTerm" />
                  </Danger>
                </div>
                <Typography variant="body2">
                  잠깐! 정확한 이메일계정과 휴대폰 번호를 입력하셨나요?
                  <br /> 컨설팅 진행과 관련된 알림 수신을 위해 정확한 정보를
                  입력해주세요!
                </Typography>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size="large"
                >
                  예술인 회원가입하기
                </Button>
                <Button
                  fullWidth
                  color="primary"
                  className={classes.submit}
                  onClick={goConsultantJoin}
                >
                  컨설턴트 회원가입 바로가기
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <LoadingOverlay isLoading={isLoading} />

        {isPersonalTermModal && (
          <PersonalTermModal
            isModal={isPersonalTermModal}
            setIsModal={setIsPersonalTermModal}
          />
        )}
        {isServiceTermModal && (
          <ServiceTermModal
            isModal={isServiceTermModal}
            setIsModal={setIsServiceTermModal}
          />
        )}
      </Container>
    </Box>
  );
};

export default ArtistJoin;
