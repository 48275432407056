import React, { useState } from "react";
import {
  makeStyles,
  useMediaQuery,
  Hidden,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import GridItem from "../../Components/initComponents/Grid/GridItem";
import FaqStyle from "./FaqStyle";
import GridContainer from "../../Components/initComponents/Grid/GridContainer";

import InfoArea from "../../Components/initComponents/InfoArea/InfoArea";
import bgImg from "../../assets/img/bg2.svg";
import WebIcon from "@material-ui/icons/Web";
import instruction_title_1 from "../../assets/img/instruction_title_1.jpg";
import instruction_title_2 from "../../assets/img/instruction_title_2.jpg";
import instruction_1 from "../../assets/img/instruction_1.jpg";
import instruction_2 from "../../assets/img/instruction_2.jpg";
import mobile_instruction_1 from "../../assets/img/mobile_instruction_1.jpg";
import mobile_instruction_2 from "../../assets/img/mobile_instruction_2.jpg";
import instruction_description from "../../assets/img/instruction_description.jpg";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(FaqStyle);

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell align="center" width="10%" component="th" scope="row">
          {row[0]}
        </TableCell>
        <TableCell className={classes.content} align="left">
          {row[1]}
        </TableCell>
        <TableCell width="10%">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={1}
        ></TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse style={{}} in={open} timeout="auto" unmountOnExit>
            <Box style={{ paddingLeft: "12px",paddingRight: "10%", marginBottom: "50px"}}>
              <p style={{}}> 
                {row[2]}
              </p>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const faqData = [
  [
    7,
    "컨설팅 이후 해야 하는 일이 있나요?",
    "컨설팅 완료 후에는 휴대전화 문자로 발송된 만족도 조사를 제출해주셔야 합니다.",
  ],
  [
    6,
    "컨설팅 신청을 여러 번 해도 괜찮나요?",
    "예술인의 경우 1인당 연간 2회로 신청을 한정하고 있습니다. 신청 횟수를 초과할 시, 개별 연락을 통해 신청 철회를 요청받을 수 있습니다.",
  ],
  [
    5,
    "컨설팅은 언제, 어디서 이뤄지나요?",
    "정해진 컨설팅 날짜 및 장소는 없습니다. 시간과 장소는 예술인과 컨설턴트가 논의해 서 정하면 됩니다.",
  ],
  [
    4,
    "컨설팅 신청을 했습니다. 이후 과정이 어떻게 되나요?",
    "컨설턴트가 컨설팅 요청을 수락하기를 기다려야 합니다. 컨설턴트가 요청 받은 컨설팅 내용을 확인 후, 컨설팅을 수락합니다.",
  ],
  [
    3,
    "‘아이컨택’은 어떻게 이용 가능한가요?",
    "먼저 아이컨택 홈페이지에 회원가입을 해야 합니다. 회원가입 후 컨설턴트 목록에서 원 하는 컨설턴트를 선택합니다. 컨설턴트 상세 페이지 맨 아래 컨설팅 신청란이 있습니다. 신청에 필요한 내용을 기입 후, ‘신청하기’를 누르면 컨설팅을 신청한 상태가 됩니다.",
  ],
  [
    2,
    "‘아이컨택’에서는 어떤 분야의 상담이 가능한가요?",
    "법무, 법률, 회계, 노무, 홍보, 심리상담 그리고 문화예술 분야의 상담이 가능합니다. 문화예 술의 경우 창작(문학, 시각, 공연, 영화, 문화일반), 기획(문학, 시각, 공연, 영화, 문화일 반), 창업, 기술로 세분화되어 있습니다.",
  ],
  [
    1,
    "예술인 컨설팅 매칭사업 ‘아이컨택’이 뭔가요?",
    "예술인 컨설팅 매칭사업 ‘아이컨택’은 부산 예술인이 예술가로 살며 겪는 다양한 의문 점을 희망하는 전문가에게 직접 컨설팅 받을 수 있는 1(예술인):1(컨설턴트) 컨설팅 매칭 사업입니다.",
  ],
];

const MainScreen = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <Box

    // style={{
    //   backgroundImage: `url(${bgImg})`,
    //   backgroundPosition: 'top right',
    //   backgroundSize: 'cover',
    // }}
    >
      <div className={classes.container}>
        <Box mb={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Hidden mdUp>
                <h3
                  style={{
                    fontWeight: 700,
                    color: "#212121",
                    fontSize: "32px",
                  }}
                >
                  자주묻는질문
                </h3>
                <div>
                  <h5 className={classes.description}>
                    자주묻는 질문들을 모아두었습니다. 궁금한 점을 찾아보세요.{" "}
                    <br />
                  </h5>
                </div>
              </Hidden>

              <Hidden smDown>
                <h3
                  style={{
                    fontWeight: 700,
                    color: "#212121",
                    fontSize: "48px",
                    paddingTop: "80px",
                  }}
                >
                  자주묻는질문
                </h3>
                <div>
                  <h5 className={classes.description}>
                    자주묻는 질문들을 모아두었습니다. 궁금한 점을 찾아보세요.{" "}
                    <br />
                  </h5>
                </div>
              </Hidden>
            </GridItem>
          </GridContainer>
        </Box>
        <GridContainer>
          <TableContainer component={Paper} style={{marginBottom:'164px'}}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">번호</TableCell>
                  <TableCell align="center">제목</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {faqData.map((row) => (
                  <Row row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </GridContainer>
      </div>
    </Box>
  );
};

export default MainScreen;
