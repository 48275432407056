import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GridContainer from '../../../Components/initComponents/Grid/GridContainer';
import GridItem from '../../../Components/initComponents/Grid/GridItem';
import CustomTabs from '../../../Components/initComponents/CustomTabs/CustomTabs';

import SubBanner from '../../../Components/SubBanner/Index';
import styles from '../../../assets/jss/material-kit-react/views/componentsSections/tabsStyle';
import bgImg from '../../../assets/img/bg2.svg';
import ArtistDataTable from './ArtistDataTable';

const useStyles = makeStyles({
  ...styles,
  section: {
    ...styles.section,
    padding: '15px 0',
    marginTop: '50px',
    backgroundImage: `url(${bgImg})`,
    backgroundPosition: 'top right',
    backgroundSize: 'cover',
    background: 'transparent',
    minHeight: '60vh',
  },
  titleWrap: {
    marginBottom: '35px',
    textAlign: 'right',
  },
  text: {
    fontSize: '16px',
    fontWeight: '400',
  },
});

const ArtistMyPage = (props) => {
  const classes = useStyles();
  const { displayConsulting } = props;

  return (
    <>
      <SubBanner />
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="nav-tabs">
            <div className={classes.titleWrap}>
              <h5 className={classes.siteInfo}>
                <span className="naviInfoFirstChar">M</span>
                AIN
                <ArrowRightIcon fontSize="small" className="naviInfoIcon" />
                MY CONSULTING
              </h5>
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTabs
                  headerColor="rose"
                  tabs={[
                    {
                      tabName: '나의 컨설팅',
                      tabIcon: InsertDriveFileIcon,
                      tabContent: (
                        <div style={{ overflow: 'auto' }}>
                          <ArtistDataTable
                            displayConsulting={displayConsulting}
                          />
                        </div>
                      ),
                    },
                    {
                      tabName: '컨설팅 진행상황 안내',
                      tabIcon: ListAltIcon,
                      tabContent: (
                        <>
                          <p className={classes.text}>
                            1. 컨설팅 요청 중 : 예술인이 컨설팅받고자 하는 컨설턴트에게 컨설팅을 요청한 상태입니다.
                          </p>
                          <p className={classes.text}>
                            2. 컨설팅 요청 보류 : 컨설팅 요청을 한 예술인이 컨설팅요청을 취소한 상태입니다.
                          </p>
                          <p className={classes.text}>
                            3. 컨설팅 요청 거절 : 컨설팅 요청을 받은 컨설턴트가 컨설팅 요청을 거절한 상태입니다.
                          </p>
                          <p className={classes.text}>
                            4. 컨설팅 진행중 : 컨설팅 요청을 받은 컨설턴트가 컨설팅 요청을 수락하여 컨설팅이 진행중인 상태입니다.
                          </p>
                          <p className={classes.text}>
                            5. 컨설팅 중단 : 관리자에 의해 진행중인 컨설팅이 중단된 상태입니다.
                          </p>
                          <p className={classes.text}>
                            6. 컨설팅 완료 : 진행 중인 컨설팅에 대해 컨설턴트가 컨설팅 결과보고서를 업로드하여 컨설팅이 완료된 상태입니다.
                          </p>
                          <p className={classes.text}>
                            7. 최종완료 : 컨설팅 결과보고서를 확인한 관리자에 의해 컨설팅이 최종 완료된 상태입니다.
                          </p>
                        </>
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtistMyPage;
