import React, {
  useContext, useState,
} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import * as Yup from 'yup';
import {MenuItem} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ListAltIcon from '@material-ui/icons/ListAlt';
import {
  Field, Formik, Form,
} from 'formik';
import {TextField} from 'formik-material-ui';
import Button from '../../../Components/initComponents/CustomButtons/Button';
import FirebaseContext from '../../../Context/Firebase';
import AuthenticationContext from '../../../Context/Authentication';
import CommonConsultingProcessData from '../CommonConsultingProcessData';
import * as SelectItem from '../ConsultingRejectReason';
import * as Function from '../../../Utill/Functions';
import reportHwp from '../../../assets/file/reportSample.hwp';
import CustomFileInput from '../../../Components/initComponents/CustomFileInput/CustomFileInput';
import * as SendNotification from '../../../Utill/SendNotification';
import * as siteConfig from '../../../Constants/SiteConfig';

const useStyles = makeStyles({
  titleCell: {
    paddingLeft: '48px',
    width: '220px',
  },
  iconLocation: {
    position: 'relative',
    top: '7px',
    marginRight: '7px',
  },
});


export default function ConsultantDetailTable(props) {
  const classes = useStyles();
  const {
    displayConsulting,
    consultingRejectArea,
    setConsultingRejectArea,
    consultingReportArea,
    setConsultingReportArea,
    closeModal,
  } = props;
  const authUser = useContext(AuthenticationContext);
  const [fileNames, setFileNames] = useState('');
  const [files, setFiles] = useState(null);
  const [isConsultingReportNewUpload, setIsConsultingReportNewUpload] = useState(true);
  const firebase = useContext(FirebaseContext);


  const rejectConsultingValidationSchema = Yup.object().shape({
    consultingRequestRejectReason: Yup.string()
      .required('컨설팅요청 취소사유 선택를 선택해주세요'),
  });

  const rejectConsultingRequest = () => {
    setConsultingRejectArea(true);
  };
  const submitConsultingReport = (newUploadFlag) => {
    setConsultingReportArea(true);
    if (!newUploadFlag) {
      setIsConsultingReportNewUpload(false);
    }
  };

  const RejectConsultingOnSubmit = (value) => {
    if (window.confirm('정말로 컨설팅요청을 거절하시겠습니까?')) {
      firebase.firestore().collection('UsersCollection')
        .doc(authUser.email)
        .get()
        .then((result) => {
          const loginedUserInfo = result.data();

          firebase.firestore().collection('ConsultingList')
            .doc(displayConsulting.id)
            .update({
              consultingRequestRejectReason: value.consultingRequestRejectReason,
              consultingState: 3,
              reRequestConsulting: true,
            })
            .then(() => {
              firebase.firestore().collection('UsersCollection')
                .doc(displayConsulting.artistEmail)
                .update({ongoingRequestCase: firebase.firestore.FieldValue.increment(-1)});
              const sendEmailInfo = {
                artistEmail: displayConsulting.artistEmail,
                consultantEmail: loginedUserInfo.email,
                artistName: displayConsulting.artistName,
                consultantName: loginedUserInfo.name,
              };
              SendNotification.sendEmail(4, sendEmailInfo);
              closeModal();
            });
        });
    } else {
      return false;
    }
  };

  const acceptRequest = () => {
    firebase.firestore().collection('UsersCollection')
      .doc(authUser.email)
      .get()
      .then((result) => {
        const loginedUserInfo = result.data();

        if (loginedUserInfo.ongoingConsultingCase >= siteConfig.consultantOngoingConsultingLimitCase) {
          alert('동시진행 가능한 컨설팅 횟수를 초과했습니다.');
          return false;
        }
        // if (loginedUserInfo.completedConsultingCase >= siteConfig.maxConsultingComplteCase) {
        //   alert('진행가능 총 컨설팅 횟수를 초과했습니다.');
        //   return false;
        // }

        if (window.confirm('컨설팅 요청을 수락하시겠습니까?')) {
          firebase.firestore().collection('ConsultingList')
            .doc(displayConsulting.id)
            .update({
              consultingState: 4,
              consultingStartDate: new Date(),
            })
            .then(() => {
              firebase.firestore().collection('UsersCollection')
                .doc(displayConsulting.consultantEmail)
                .update({
                  ongoingConsultingCase: firebase.firestore.FieldValue.increment(+1),
                })
                .then(() => {
                  firebase.firestore().collection('UsersCollection')
                    .doc(displayConsulting.artistEmail)
                    .update({ongoingConsultingCase: firebase.firestore.FieldValue.increment(+1)})
                    .then(() => {
                      const sendEmailInfo = {
                        artistEmail: displayConsulting.artistEmail,
                        consultantEmail: loginedUserInfo.email,
                        artistName: displayConsulting.artistName,
                        consultantName: loginedUserInfo.name,
                      };
                      const sendSmsInfo = {
                        artistName: displayConsulting.artistName,
                        artistPhone: displayConsulting.artistPhone,
                        consultantName: loginedUserInfo.name,
                        consultantPhone: loginedUserInfo.phone,
                        consultingId: displayConsulting.id,
                      };
                      SendNotification.sendSms(1, sendSmsInfo);
                      SendNotification.sendEmail(5, sendEmailInfo);
                      closeModal();
                    });
                });
            });
        } else {
          return false;
        }
      });
  };

  const uploadFile = () => {
    if (files == null) {
      alert('컨설팅 결과보고서를 업로드해주세요.');
      return;
    }
    try {
      firebase.firestore().collection('UsersCollection')
        .doc(authUser.email)
        .get()
        .then((result) => {
          const loginedUserInfo = result.data();
          const randomKeys = Function.RandomKey(8);
          const today = Function.printDateDash(new Date());
          const uploadTask = firebase.storage().ref(`files/${loginedUserInfo.email}-${today}-${randomKeys}.hwp`).put(files[0]);
          uploadTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`upload is ${progress}% done`);
          }, (error) => {
            console.log(error);
          }, () => {
            const fileName = uploadTask.snapshot.ref.name;
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              firebase.firestore().collection('ConsultingList')
                .doc(displayConsulting.id)
                .update({
                  consultingResultReportUrl: downloadURL,
                  consultingResultReportFileName: fileName,
                  consultingEndDate: new Date(),
                  consultingState: 6,
                });
              setFileNames('');
              setFiles(null);
              if (isConsultingReportNewUpload) {
                const sendEmailInfo = {
                  artistEmail: displayConsulting.artistEmail,
                  consultantEmail: loginedUserInfo.email,
                  artistName: displayConsulting.artistName,
                  consultantName: loginedUserInfo.name,
                };
                const sendSmsInfo = {
                  artistName: displayConsulting.artistName,
                  artistPhone: displayConsulting.artistPhone,
                  consultantName: loginedUserInfo.name,
                  consultantPhone: loginedUserInfo.phone,
                  consultingId: displayConsulting.id,
                };
                SendNotification.sendEmail(7, sendEmailInfo);
                SendNotification.sendSms(2, sendSmsInfo);
              } else {
                if (displayConsulting.reRequestConsultingReportMessage && displayConsulting.ReRequestUploadConsultingReportState === 1) {
                  firebase.firestore().collection('ConsultingList')
                    .doc(displayConsulting.id)
                    .update({
                      ReRequestUploadConsultingReportState: 2,
                    });
                }
                const sendSmsReSubmitReportInfo = {
                  artistName: displayConsulting.artistName,
                  consultantName: loginedUserInfo.name,
                };
                // SendNotification.sendSms(4, sendSmsReSubmitReportInfo);
              }
              alert('컨설팅 결과보고서 제출을 완료하였습니다.');
              closeModal();
            });
          });
        });
    } catch (e) {
      console.log(e);
    }
  };


  return (
    <>
      <TableContainer component={Paper}>
        <Table
          style={{
            maxWidth: '450px',
            minWidth: '430px',
            width: '100%',
            overflow: 'auto',
          }}
          aria-label="simple table"
        >
          <TableBody>
            <TableRow style={{border: 'none'}}>
              <TableCell className={classes.titleCell}>
                <AccountBoxIcon className={classes.iconLocation}/>
                예술인
              </TableCell>
              <TableCell>
                {displayConsulting.artistName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.titleCell}>
                <HowToRegOutlinedIcon className={classes.iconLocation}/>
                예술인 활동분야
              </TableCell>
              <TableCell>
                {siteConfig.ArtistAreaToText[displayConsulting.artistArea]}
              </TableCell>
            </TableRow>
            <CommonConsultingProcessData
              displayConsulting={displayConsulting}
            />
            {displayConsulting.consultingState === 1
            && (
              <TableRow>
                <TableCell className={classes.titleCell}>
                  <ArrowForwardOutlinedIcon className={classes.iconLocation}/>
                  다음단계 진행
                </TableCell>
                <TableCell className={classes.titleCell}>
                  {!consultingRejectArea
                  && (
                    <Button color="rose" onClick={() => rejectConsultingRequest()}>
                      컨설팅 요청 거절
                    </Button>
                  )}
                  <Button color="primary" onClick={() => acceptRequest()}>
                    컨설팅 요청 수락
                  </Button>
                </TableCell>
              </TableRow>
            )}
            {consultingRejectArea
            && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Formik
                    initialValues={{consultingRequestRejectReason: ''}}
                    validationSchema={rejectConsultingValidationSchema}
                    onSubmit={RejectConsultingOnSubmit}
                  >
                    {({submitForm}) => (
                      <Form>
                        <Field
                          disabled={false}
                          component={TextField}
                          select
                          variant="outlined"
                          name="consultingRequestRejectReason"
                          label="컨설팅요청 취소사유 선택"
                          fullWidth
                        >
                          {SelectItem.rejectReason.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Field>
                        <Button
                          color="rose"
                          onClick={submitForm}
                        >
                          컨설팅요청 거절하기
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </TableCell>
              </TableRow>
            )}


            {(displayConsulting.consultingState === 4 || displayConsulting.consultingState === 6)
            && (
              <TableRow>
                <TableCell className={classes.titleCell}>
                  <ArrowForwardOutlinedIcon className={classes.iconLocation}/>
                  다음단계 진행
                </TableCell>
                <TableCell>
                  {
                    !consultingReportArea && (
                      displayConsulting.consultingResultReportFileName === ''
                        ? (
                          <Button color="primary" onClick={() => submitConsultingReport(true)}>
                            컨설팅 결과보고서 제출하기
                          </Button>
                        )
                        : (
                          <Button color="primary" onClick={() => submitConsultingReport(false)}>
                            컨설팅 결과보고서 수정하기
                          </Button>
                        )
                    )
                  }
                </TableCell>
              </TableRow>
            )}

            {displayConsulting.reRequestConsultingReportMessage && displayConsulting.ReRequestUploadConsultingReportState === 1 ? (
              <TableRow>
                <TableCell colSpan={2}>
                  {displayConsulting.reRequestConsultingReportMessage}
                </TableCell>
              </TableRow>
            ) : ''}


            {consultingReportArea && (
              <TableRow>
                <TableCell colSpan={2}>

                  <a href={reportHwp} download>
                    <GetAppIcon className={classes.iconLocation}/>
                    결과보고서 양식 샘플 다운로드
                  </a>
                  <p style={{fontSize: '12px'}}>
                    (*사용자 PC환경이나 Hwp뷰어 프로그램에 따라 샘플양식이 깨져보일수 있습니다. 이 경우 마이페이지 - 문의내역으로 문의해주세요.)
                  </p>

                  {displayConsulting.consultingResultReportFileName
                  && (
                    <p>
                      <a href={displayConsulting.consultingResultReportUrl} download>
                        <GetAppIcon className={classes.iconLocation}/>
                        현재 제출된 결과보고서 다운로드
                      </a>
                    </p>
                  )}
                  <CustomFileInput
                    fileNames={fileNames}
                    setFileNames={setFileNames}
                    files={files}
                    setFiles={setFiles}
                    variant="outlined"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: '클릭! 결과보고서 업로드',
                    }}
                    endButton={{
                      buttonProps: {
                        round: true,
                        color: 'rose',
                        justIcon: true,
                        fileButton: true,
                      },
                      icon: <FileCopyIcon/>,
                    }}
                  />

                  <p>
                    관리자가 최종완료하기 전까지 업로드하신 보고서를 수정할 수 있습니다.
                    <br/>
                    (최종완료후에는 수정이 불가합니다.)
                  </p>
                  <Button
                    fullWidth={false}
                    color="rose"
                    type="button"
                    onClick={uploadFile}
                  >
                    컨설팅보고서 제출하기
                  </Button>
                </TableCell>
              </TableRow>

            )}

            {displayConsulting.consultingState === 7
            && (
              <TableRow>
                <TableCell className={classes.titleCell}>
                  <ListAltIcon className={classes.iconLocation}/>
                  결과 보고서
                </TableCell>
                <TableCell>
                  <a href={displayConsulting.consultingResultReportUrl} download>
                    <GetAppIcon className={classes.iconLocation}/>
                    다운로드
                  </a>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
