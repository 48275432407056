import React, { useContext, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import ImageUploader from "react-images-upload";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { MenuItem } from "@material-ui/core";
import { Field, Formik, Form, ErrorMessage } from "formik";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import * as Yup from "yup";
import imageCompression from "browser-image-compression";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import Danger from "../../Components/initComponents/Typography/Danger";
import * as SiteConfig from "../../Constants/SiteConfig";
import FirebaseContext from "../../Context/Firebase";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import * as SelectItems from "./SelectItem";
import * as Functions from "../../Utill/Functions";
import * as SendNotification from "../../Utill/SendNotification";
import * as Routes from "../../Constants/Routes";
import ConsultantActiveTermModal from "./ConsultantActiveTermModal";
import PersonalTermModal from "../ArtistJoin/PersonalTermModal";
import ServiceTermModal from "../ArtistJoin/ServiceTermModal";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: SiteConfig.mainColor,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  checkbox:{
    display: 'flex',
    height:20,
    margin: 15,
  },
  terms:{
    display:'flex',
    alignItems: 'center',
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
    },
  }
}));

const initialValues = {
  email: "",
  name: "",
  phone: "",
  age: "",
  password: "",
  confirmPassword: "",
  consultingMainCatepory: "",
  consultingSubCatepory: "",
  // consultingGenre: '',
  // C1: false,
  // C2: false,
  // C3: false,
  // C4: false,
  // C5: false,
  // C6: false,
  // C7: false,
  // C8: false,
  // C9: false,
  briefProfile1: "",
  briefProfile2: "",
  briefProfile3: "",
  profileContent: "",
  serviceTerm: false,
  personalInformationTerm: false,
  consultantActiveTerm: false,
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("이메일 형식으로 입력해주세요").required("이메일을 입력해주세요"),
  name: Yup.string().required("이름을 입력해주세요"),
  phone: Yup.number().typeError("연락처는 숫자만 입력해주세요.").required("연락처를 입력해주세요"),
  password: Yup.string()
    .min(6, "비밀번호는 6자 이상 입력해주세요")
    .max(15, "비밀번호는 15자 이하로 입력해주세요")
    .required("비밀번호를 입력해주세요"),
  age: Yup.string()
    .required("연령대를 선택해주세요")
    .oneOf(["10대", "20대", "30대", "40대", "50대", "60대", "70대 이상"], "연령대를 선택해주세요"),
  confirmPassword: Yup.string()
    .required("비밀번호를 재입력해주세요")
    .oneOf([Yup.ref("password")], "입력하신 비밀번호와 일치하지 않습니다."),
  briefHistory1: Yup.string().required("주요 경력을 한가지 입력해주세요."),
  briefHistory2: Yup.string().notRequired(),
  briefHistory3: Yup.string().notRequired(),
  profileContent: Yup.string().required("상세 프로필 내용을 입력해주세요"),
  consultingMainCatepory: Yup.string()
    .required("컨설팅 분야를 선택해주세요")
    .oneOf(["C1", "C2", "C3", "C4", "C5", "C6", "C7"], "컨설팅 분야를 선택해주세요"),
  consultingSubCatepory: Yup.string()
    .required("컨설팅 세부분야를 선택해주세요")
    .oneOf(
      [
        "CG1",
        "CG2",
        "CG3",
        "CG4",
        "CG5",
        "CG6",
        "CG7",
        "CG8",
        "CG9",
        "CG10",
        "CG11",
        "CG12",
        "CG13",
        "CG14",
        "CG15",
        "CG16",
        "CG17",
        "CG18",
        "CG19",
        "CG20",
        "CG21",
        "CG22",
      ],
      "컨설팅 세부분야를 선택해주세요"
    ),
  // consultingGenre: Yup.string()
  //   .required('컨설팅 분야(대분류)를 선택해주세요')
  //   .oneOf(['CG1', 'CG2', 'CG3', 'CG4', 'CG5', 'CG6', 'CG7', 'CG8', 'CG9', 'CG10', 'CG11', 'CG12'], '컨설팅 분야(대분류)를 선택해주세요'),
  serviceTerm: Yup.boolean().oneOf([true], "서비스 이용약관에 동의해주세요"),
  personalInformationTerm: Yup.boolean().oneOf([true], "개인정보처리방침에 동의해주세요"),
  consultantActiveTerm: Yup.boolean().oneOf([true], "컨설턴트 운영방침에 동의해주세요"),
});

const Index = () => {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isPersonalTermModal, setIsPersonalTermModal] = useState(false);
  const [isServiceTermModal, setIsServiceTermModal] = useState(false);
  const [isConsontantActiveTermModal, setIsConsontantActiveTermModal] = useState(false);
  const [img, setImg] = useState([]);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onDropimg = (uploadImg) => {
    setImg(uploadImg[0]);
  };

  const uploadImg = async (values) => {
    if (img === undefined) {
      console.log("noImgUpload");
    }
    try {
      console.log("imgUpload");
      const compressedFile = await imageCompression(img, { maxWidthOrHeight: 640 });
      const randomKeys = Functions.RandomKey(8);
      const uploadTask = firebase.storage().ref(`images/${randomKeys}`).put(compressedFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.log(error);
        },
        () => {
          const profileImgName = uploadTask.snapshot.ref.name;
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            firebase.firestore().collection("UsersCollection").doc(values.email).update({ profileImgUrl: downloadURL, profileImgName });
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (values) => {
    setIsLoading(true);
    const {
      name,
      email,
      age,
      password,
      phone,
      briefHistory1,
      briefHistory2,
      briefHistory3,
      profileContent,
      consultingMainCatepory,
      consultingSubCatepory,
      // consultingGenre, C1, C2, C3, C4, C5, C6, C7, C8, C9,
    } = values;

    // const consultingAreaArray = [];
    // if (C1) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C1);
    // }
    // if (C2) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C2);
    // }
    // if (C3) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C3);
    // }
    // if (C4) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C4);
    // }
    // if (C5) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C5);
    // }
    // if (C6) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C6);
    // }
    // if (C7) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C7);
    // }
    // if (C8) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C8);
    // }
    // if (C9) {
    //   consultingAreaArray.push(SiteConfig.consultingAreaInfo.C9);
    // }
    const briefHistoryArr = [];
    briefHistoryArr.push(briefHistory1, briefHistory2, briefHistory3)
    const briefHistory = briefHistoryArr.filter((str) => str !== '')
    
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch(({ code: errorCode }) => {
        setIsLoading(false);
        if (errorCode === "auth/email-already-in-use") {
          window.alert("이미 가입된 이메일 계정이거나 탈퇴한 계정입니다.");
        } else if (errorCode === "auth/weak-password") {
          window.alert("입력하신 비밀번호가 적합하지 않습니다.");
        } else if (errorCode === "auth/invalid-email") {
          window.alert("입력하신 이메일 주소가 유효하지 않습니다.");
        } else {
          window.alert("오류가 발생하였습니다. 관리자에게 문의해주세요");
        }
      })
      .then((userCredentials) => {
        userCredentials.user.updateProfile({ displayName: name }).then(() => {
          firebase
            .firestore()
            .collection("UsersCollection")
            .doc(email)
            .set({
              uid: userCredentials.user.uid,
              name,
              email,
              phone: phone.replace(/ /gi, ""),
              age,
              position: "CONSULTANT",
              consultingMainCatepory,
              consultingSubCatepory,
              // consultingGenre,
              // consultingAreaText: consultingAreaArray.join('. '),
              // consultingArea: {
              //   C1,
              //   C2,
              //   C3,
              //   C4,
              //   C5,
              //   C6,
              //   C7,
              //   C8,
              //   C9,
              // },
              briefHistory:[
                briefHistory1 , briefHistory2??"", briefHistory3??""
              ],
              profileContent,
              profileImgUrl: "",
              profileImgName: "",
              ongoingConsultingCase: 0,
              completedConsultingCase: 0,
              isAccountActive: false,
              registrationDate: new Date(),
              latestLoginDate: new Date(),
              isWithdrawUser: false,
              withdrawalReason: "",
              userLevel: 1,
            })
            .then(() => {
              SendNotification.sendEmail(1, values);
              uploadImg(values);
              setIsLoading(false);
              firebase.auth().signInWithEmailAndPassword(email, password).then(()=>{
                window.location.reload();
              })
            })
            .catch((error) => {
              console.log(error);
            });
        });
      })
      .catch(() => {});
  };

  return (
    <Box mb={10}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <HowToRegIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Consultant Sign Up
          </Typography>
          <Typography variant="body2">컨설턴트 회원가입 페이지</Typography>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {() => (
              <Form className={classes.form}>
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="email"
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="name"
                  type="text"
                  label="이름"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="phone"
                  type="tel"
                  label="연락처"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="password"
                  type="password"
                  label="비밀번호"
                  margin="normal"
                  fullWidth
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="confirmPassword"
                  type="password"
                  label="비밀번호재입력"
                  margin="normal"
                  fullWidth
                />
                <Field disabled={false} component={TextField} select name="age" variant="outlined" margin="normal" label="연령대" fullWidth>
                  {SelectItems.ages.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="consultingMainCatepory"
                  label="컨설팅 분야"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                >
                  {SelectItems.consultingMainCatepory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="consultingSubCatepory"
                  label="컨설팅 세부분야"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                >
                  {SelectItems.consultingSubCatepory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                {/* <Field
                  disabled={false}
                  component={TextField}
                  select
                  name="consultingGenre"
                  label="컨설팅 분야(대분류)"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                >
                  {SelectItems.consultingGenre.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <div className="consultantJoinCheckboxTitle">
                  컨설팅 분야(소분류/다중선택 가능)
                </div>
                <div>
                  {SelectItems.consultingArea.map((option) => (
                    <Field
                      key={option.value}
                      disabled={false}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name={option.value}
                      Label={{label: option.label}}
                    />
                  ))}
                </div> */}
                <ImageUploader
                  label=""
                  withPreview
                  withIcon={false}
                  fileSizeError="파일 크기용량은 10MB 이하로 업로드 해주세요."
                  buttonText="프로필이미지 등록하기"
                  onChange={onDropimg}
                  singleImage
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={10485760}
                />
                <Field
                  disabled={false}
                  component={TextField}
                  name="briefHistory1"
                  variant="outlined"
                  type="text"
                  label="주요 경력 1"
                  fullWidth
                  margin="normal"
                />
                <Field
                  disabled={false}
                  component={TextField}
                  name="briefHistory2"
                  variant="outlined"
                  type="text"
                  label="주요 경력 2 (선택) "
                  fullWidth
                  margin="normal"
                />
                <Field
                  disabled={false}
                  component={TextField}
                  name="briefHistory3"
                  variant="outlined"
                  type="text"
                  label="주요 경력 3 (선택) "
                  fullWidth
                  margin="normal"
                />
                <Field
                  disabled={false}
                  component={TextField}
                  name="profileContent"
                  variant="outlined"
                  type="text"
                  label="상세 프로필"
                  fullWidth
                  multiline
                  rows={6}
                  margin="normal"
                />
                <div className={classes.checkbox}>
                  <Field
                    disabled={false}
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="serviceTerm"
                    // Label={{ label: "서비스이용약관 동의(필수)" }}
                  />
                  <span className={classes.terms} onClick={() => setIsServiceTermModal(true)}>서비스이용약관 동의(필수)</span>
                  <Danger>
                    <ErrorMessage name="serviceTerm" />
                  </Danger>
                </div>
                <div className={classes.checkbox}>
                  <Field
                    disabled={false}
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="personalInformationTerm"
                    // Label={{ label: "개인정보처리방침 동의(필수)" }}
                  />
                  <span className={classes.terms} onClick={() => setIsPersonalTermModal(true)}>개인정보처리방침 동의(필수)</span>
                  <Danger>
                    <ErrorMessage name="personalInformationTerm" />
                  </Danger>
                </div>
                <div className={classes.checkbox}>
                  <Field
                    disabled={false}
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="consultantActiveTerm"
                    // Label={{ label: "컨설턴트 운영방침 동의(필수)" }}
                  />
                  <span className={classes.terms} onClick={() => setIsConsontantActiveTermModal(true)}>컨설턴트 운영방침 동의(필수)</span>
                  {/* <span className="consultantActiveBtn" onClick={() => setIsModal(true)}>
                    내용보기
                  </span> */}
                  <Danger>
                    <ErrorMessage name="consultantActiveTerm" />
                  </Danger>
                </div>
                <br /> 
                <Typography variant="body2">
                  잠깐! 정확한 이메일계정과 휴대폰 번호를 입력하셨나요?
                  <br /> 컨설팅 진행과 관련된 알림 수신을 위해 정확한 정보를 입력해주세요!
                </Typography>
                <Typography variant="body2">회원가입 완료후 관리자 승인을 거쳐 컨설턴트로 컨설팅활동이 가능합니다.</Typography>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} size="large">
                  컨설턴트 회원가입하기
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <LoadingOverlay isLoading={isLoading} />

        {isPersonalTermModal && <PersonalTermModal isModal={isPersonalTermModal} setIsModal={setIsPersonalTermModal} />}
        {isServiceTermModal && <ServiceTermModal isModal={isServiceTermModal} setIsModal={setIsServiceTermModal} />}
        {isConsontantActiveTermModal && <ConsultantActiveTermModal isModal={isConsontantActiveTermModal} setIsModal={setIsConsontantActiveTermModal} />}

      </Container>
    </Box>
  );
};

export default Index;
