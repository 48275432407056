import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import * as Routes from "../../Constants/Routes";
import styles from "../../assets/jss/material-kit-react/components/footerStyle";
import logoBcf from "../../assets/img/logo_bcf.svg";

const useStyles = makeStyles((theme) => styles(theme));

const Footer = (props) => {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  return (
    <>
      {/* <footer className={footerClasses}> */}
      {/*  <div className={classes.container}> */}
      {/*    <div className={classes.left}> */}
      {/*      <List className={classes.list}> */}
      {/*        <ListItem className={classes.inlineBlock}> */}
      {/*          <a */}
      {/*            href="http://www.bscf.or.kr/" */}
      {/*            className={classes.block} */}
      {/*            target="_blank" */}
      {/*            rel="noopener noreferrer" */}
      {/*          > */}
      {/*            부산문화재단 */}
      {/*          </a> */}
      {/*        </ListItem> */}

      {/*        <ListItem className={classes.inlineBlock}> */}
      {/*          <a */}
      {/*            href="http://www.busan.go.kr/open/index.jsp" */}
      {/*            className={classes.block} */}
      {/*            target="_blank" */}
      {/*            rel="noopener noreferrer" */}
      {/*          > */}
      {/*            부산광역시 */}
      {/*          </a> */}
      {/*        </ListItem> */}

      {/*        <ListItem className={classes.inlineBlock}> */}
      {/*          <a */}
      {/*            href="http://www.bscc.or.kr/intro/" */}
      {/*            className={classes.block} */}
      {/*            target="_blank" */}
      {/*            rel="noopener noreferrer" */}
      {/*          > */}
      {/*            부산문화회관 */}
      {/*          </a> */}
      {/*        </ListItem> */}

      {/*        <ListItem className={classes.inlineBlock}> */}
      {/*          <a */}
      {/*            href="http://kto.visitkorea.or.kr/kor.kto" */}
      {/*            className={classes.block} */}
      {/*            target="_blank" */}
      {/*            rel="noopener noreferrer" */}
      {/*          > */}
      {/*            한국관광공사 */}
      {/*          </a> */}
      {/*        </ListItem> */}

      {/*        <ListItem className={classes.inlineBlock}> */}
      {/*          <a */}
      {/*            href="http://bta.or.kr/" */}
      {/*            className={classes.block} */}
      {/*            target="_blank" */}
      {/*            rel="noopener noreferrer" */}
      {/*          > */}
      {/*            부산광역시관광협회 */}
      {/*          </a> */}
      {/*        </ListItem> */}
      {/*        <ListItem className={classes.inlineBlock}> */}
      {/*          <a */}
      {/*            href="https://www.mcst.go.kr/kor/main.jsp" */}
      {/*            className={classes.block} */}
      {/*            target="_blank" */}
      {/*            rel="noopener noreferrer" */}
      {/*          > */}
      {/*            문화체육관광부 */}
      {/*          </a> */}
      {/*        </ListItem> */}
      {/*        <ListItem className={classes.inlineBlock}> */}
      {/*          <a */}
      {/*            href="http://www.bfc.or.kr/" */}
      {/*            className={classes.block} */}
      {/*            target="_blank" */}
      {/*            rel="noopener noreferrer" */}
      {/*          > */}
      {/*            부산영상위원회 */}
      {/*          </a> */}
      {/*        </ListItem> */}

      {/*      </List> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </footer> */}
      <footer className={footerClasses}>
        <div className={classes.container}>
          <div className={classes.left}>
            <div className={classes.leftContainer}>
              <div style={{ display: "flex" }}>
                <a
                  href="https://www.bscf.or.kr/main.do"
                  className={classes.block}
                  target="_blank"
                >
                  <img src={logoBcf} alt="logoBcf" style={{ width: "110px" }} />
                </a>
              </div>
              <List className={classes.list}>
                <p className={`${classes.block} ${classes.deskTopBlock}`}>
                  부산광역시 남구 우암로 84-1(감만동) T.051.744-7707 /
                  F.051.744-7708~9
                  <br />
                  Copyright BY 2015 BUSAN CULTURAL FOUNDATION. All rights
                  reserved.
                </p>
                <p className={`${classes.block} ${classes.mobileBlock}`}>
                  부산문화재단 아이컨택 <br />
                  부산광역시 남구 우암로 84-1(감만동) <br />
                  T.051.744-7707 | F.051.744-7708~9 <br />
                  Copyright BY 2015 BUSAN CULTURAL FOUNDATION.
                  <br />
                  All rights reserved.
                </p>
              </List>
            </div>
          </div>

          <div className={classes.right}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <Link
                  to={Routes.INDEX}
                  className={`${classes.block} ${classes.footerBtn}`}
                >
                  아이컨택
                </Link>
              </ListItem>

              <ListItem className={classes.inlineBlock}>
                <Link
                  to={Routes.SERVICETERM}
                  className={`${classes.block} ${classes.footerBtn}`}
                >
                  이용약관
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link
                  to={Routes.PERSONALTERM}
                  className={`${classes.block} ${classes.footerBtn}`}
                >
                  개인정보처리방침
                </Link>
              </ListItem>
            </List>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
