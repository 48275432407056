import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import CustomInput from '../CustomInput/CustomInput';
import Button from '../CustomButtons/Button';

const customFileInputStyle = {
  inputFile: {
    opacity: '0',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '-1',
  },
  inputFileWrapper: {
    '& button svg': {
      color: 'inherit',
    },
  },
};

const useStyles = makeStyles(customFileInputStyle);

export default function CustomFileInput(props) {
  const {
    fileNames, setFileNames, setFiles,
  } = props;


  const hiddenFile = React.createRef();
  const onFocus = (e) => {
    hiddenFile.current.click(e);
  };
  // eslint-disable-next-line
  const handleSubmit = e => {
    e.preventDefault();
    // files is the file/image uploaded
    // in this function you can save the image (files) on form submit
    // you have to call it yourself
  };
  const addFile = (e) => {
    let fileNames = '';
    const { files } = e.target;
    for (let i = 0; i < e.target.files.length; i++) {
      fileNames += e.target.files[i].name;
      if (props.multiple && i !== e.target.files.length - 1) {
        fileNames += ', ';
      }
    }
    setFiles(files);
    setFileNames(fileNames);
  };
  const {
    id,
    endButton,
    startButton,
    inputProps,
    formControlProps,
    multiple,
  } = props;
  const classes = useStyles();
  if (inputProps && inputProps.type && inputProps.type === 'file') {
    inputProps.type = 'text';
  }
  let buttonStart;
  let buttonEnd;
  if (startButton) {
    buttonStart = (
      <Button {...startButton.buttonProps}>
        {startButton.icon !== undefined ? startButton.icon : null}
        {startButton.text !== undefined ? startButton.text : null}
      </Button>
    );
  }
  if (endButton) {
    buttonEnd = (
      <Button {...endButton.buttonProps}>
        {endButton.icon !== undefined ? endButton.icon : null}
        {endButton.text !== undefined ? endButton.text : null}
      </Button>
    );
  }
  return (
    <div className={classes.inputFileWrapper}>
      <input
        accept=".hwp"
        type="file"
        className={classes.inputFile}
        multiple={multiple}
        ref={hiddenFile}
        onChange={addFile}
      />
      <CustomInput
        id={id}
        formControlProps={{
          ...formControlProps,
        }}
        inputProps={{
          ...inputProps,
          onClick: onFocus,
          value: fileNames,
          endAdornment: buttonEnd,
          startAdornment: buttonStart,
        }}
      />
    </div>
  );
}

CustomFileInput.defaultProps = {
  multiple: false,
};

CustomFileInput.propTypes = {
  id: PropTypes.string,
  endButton: PropTypes.object,
  startButton: PropTypes.object,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  multiple: PropTypes.bool,
};
