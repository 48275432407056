import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ErrorIcon from '@material-ui/icons/Error';
import GetAppIcon from '@material-ui/icons/GetApp';
import GavelIcon from '@material-ui/icons/Gavel';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DateRangeIcon from '@material-ui/icons/DateRange';
import * as Function from '../../Utill/Functions';
import * as SelectItem from '../MyConsulting/ConsultingRejectReason';
import ConsultingCeaseModal from './ConsultingCeaseModal';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';
import Firebase from '../../Context/Firebase';
import * as SendNotification from '../../Utill/SendNotification';
import ReRequestConsutingReportModal from './ReRequestConsutingReportModal';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  iconLocation: {
    position: 'relative',
    top: '7px',
    marginRight: '7px',
  },
});

export default function ConsultingMangeDetail(props) {
  const {item} = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [consultingCeaseModal, setConsultingCeaseModal] = useState(false);
  const [reRequestConsultingReportModal, setReRequestConsultingReportModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [isViewConsultingSurveyResult, setIsViewConsultingSurveyResult] = useState(false);
  const firebase = useContext(Firebase);

  const openConsultingCease = (targetItem) => {
    setConsultingCeaseModal(true);
    setSelectedItem(targetItem);
  };
  const closeConsultingCease = () => {
    setConsultingCeaseModal(false);
    setSelectedItem(null);
  };


  const openReRequestConsultingReport = (targetItem) => {
    setReRequestConsultingReportModal(true);
    setSelectedItem(targetItem);
  };
  const closeReRequestConsultingReport = () => {
    setReRequestConsultingReportModal(false);
    setSelectedItem(null);
  };

  const finalCompleteConsulting = (consultingValues) => {
    if (window.confirm('해당 컨설팅건을 최종완료하시겠습니까?')) {
      setIsLoading(true);
      firebase.firestore().collection('ConsultingList')
        .doc(consultingValues.id)
        .update({
          consultingState: 7,
        });

      firebase.firestore().collection('UsersCollection')
        .doc(consultingValues.consultantEmail)
        .update({
          ongoingConsultingCase: firebase.firestore.FieldValue.increment(-1),
          completedConsultingCase: firebase.firestore.FieldValue.increment(+1),
        });

      firebase.firestore().collection('UsersCollection')
        .doc(consultingValues.artistEmail)
        .update({
          ongoingConsultingCase: firebase.firestore.FieldValue.increment(-1),
          ongoingRequestCase: firebase.firestore.FieldValue.increment(-1),
          completedConsultingCase: firebase.firestore.FieldValue.increment(+1),
        });

      const sendEmailInfo = {
        artistEmail: consultingValues.artistEmail,
        artistName: consultingValues.artistName,
        consultantEmail: consultingValues.consultantEmail,
        consultantName: consultingValues.consultantName,
      };
      SendNotification.sendEmail(8, sendEmailInfo);
    } else {
      return false;
    }
  };

  return (
    <>
      <TableContainer component={Paper} style={{marginBottom: '10px'}}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>

            <TableRow>
              <TableCell align="left" className="consultingDetailTitle">
                <PersonIcon className={classes.iconLocation}/>
                컨설턴트
              </TableCell>
              <TableCell>
                <EmailIcon className={classes.iconLocation}/>
                {item.consultantEmail}
                &nbsp; &nbsp;
                <PhoneAndroidIcon className={classes.iconLocation}/>
                {Function.autoHypenPhone(item.consultantPhone)}
              </TableCell>
            </TableRow>


            <TableRow>
              <TableCell align="left" className="consultingDetailTitle">
                <PersonIcon className={classes.iconLocation}/>
                예술인
              </TableCell>
              <TableCell>
                <EmailIcon className={classes.iconLocation}/>
                {item.artistEmail}
                &nbsp; &nbsp;
                <PhoneAndroidIcon className={classes.iconLocation}/>
                {Function.autoHypenPhone(item.artistPhone)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" className="consultingDetailTitle">
                <DateRangeIcon className={classes.iconLocation}/>
                예술활동 시작연도
              </TableCell>
              <TableCell>{item.artActiveStartYear}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" className="consultingDetailTitle">
                <DateRangeIcon className={classes.iconLocation}/>
                예술인활동 기간
              </TableCell>
              <TableCell>{item.artActivePeriod}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" className="consultingDetailTitle">
                <DateRangeIcon className={classes.iconLocation}/>
                컨설팅 희망일시
              </TableCell>
              <TableCell>{item.expectMatchingDate}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" className="consultingDetailTitle">
                <TextsmsOutlinedIcon className={classes.iconLocation}/>
                컨설팅 요청내용
              </TableCell>
              <TableCell>{item.consultingRequestContent}</TableCell>
            </TableRow>

            {item.consultingRequestRejectReason
            && (
              <TableRow>
                <TableCell align="left" className="consultingDetailTitle">
                  <ErrorIcon className={classes.iconLocation}/>
                  컨설팅 거절 사유
                </TableCell>
                <TableCell>
                  {SelectItem.rejectReason[item.consultingRequestRejectReason - 1].label}
                </TableCell>
              </TableRow>
            )}
            {item.consultingCeaseReason
            && (
              <TableRow>
                <TableCell align="left" className="consultingDetailTitle">
                  <ErrorIcon className={classes.iconLocation}/>
                  컨설팅 중단 사유
                </TableCell>
                <TableCell>
                  {item.consultingCeaseReason}
                </TableCell>
              </TableRow>
            )}


            {item.consultingState === 4
            && (
              <TableRow>
                <TableCell align="left" className="consultingDetailTitle">
                  <GavelIcon className={classes.iconLocation}/>
                  관리자권한
                </TableCell>
                <TableCell>
                  <button
                    className="adminBtn"
                    onClick={() => openConsultingCease(item)}
                  >
                    컨설팅 중단하기
                  </button>
                </TableCell>
              </TableRow>
            )}


            {item.consultingResultReportUrl
            && (
              <TableRow>
                <TableCell align="left" className="consultingDetailTitle">
                  <InsertDriveFileIcon className={classes.iconLocation}/>
                  컨설팅 결과보고서
                </TableCell>
                <TableCell>
                  <p>
                    <a href={item.consultingResultReportUrl} download>
                      <GetAppIcon className={classes.iconLocation}/>
                      결과보고서 다운로드
                    </a>
                  </p>
                  {item.consultingState !== 7
                  && (
                    <button
                      className="adminBtn"
                      onClick={() => openReRequestConsultingReport(item)}
                    >
                      {item.ReRequestUploadConsultingReportState === 1 && (<span>결과보고서 재업로드 요청중</span>)}
                      {item.ReRequestUploadConsultingReportState === 2 && (<span>결과보고서 재업로드 요청하기</span>)}
                      {!item.ReRequestUploadConsultingReportState && (<span>결과보고서 재업로드 요청하기</span>)}

                    </button>
                  )}


                </TableCell>
              </TableRow>
            )}


            {item.consultingState >= 6 && (
              <TableRow>
                <TableCell align="left" className="consultingDetailTitle">
                  <InsertDriveFileIcon className={classes.iconLocation}/>
                  설문조사
                </TableCell>
                <TableCell>
                  {isViewConsultingSurveyResult && (
                    <>
                      {item.consultingSurveyResult.map((values) => (
                        <div key={values.questionCode}>
                          <p>
                            {values.questionText}
                          </p>
                          <p className="fb">{values.questionResultText}</p>
                        </div>
                      ))}
                    </>
                  )}
                  {item.consultingSurveyResult
                    ? (isViewConsultingSurveyResult
                        ?
                        <button className="surveyBtn" onClick={() => setIsViewConsultingSurveyResult(false)}>닫기</button>
                        : <button className="surveyBtn"
                                  onClick={() => setIsViewConsultingSurveyResult(true)}>결과보기</button>
                    )
                    : <p>미응답</p>}
                </TableCell>
              </TableRow>
            )}

            {(item.consultingState === 6 && item.consultingSurveyResult)
            && (
              <TableRow>
                <TableCell align="left" className="consultingDetailTitle">
                  <GavelIcon className={classes.iconLocation}/>
                  관리자권한
                </TableCell>
                <TableCell>
                  <button
                    className="adminBtn"
                    onClick={() => finalCompleteConsulting(item)}
                  >
                    컨설팅 최종완료하기
                  </button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadingOverlay
        isLoading={isLoading}
      />

      {consultingCeaseModal
      && (
        <ConsultingCeaseModal
          isModal={consultingCeaseModal}
          closeModal={closeConsultingCease}
          selectedItem={selectedItem}
        />
      )}
      {reRequestConsultingReportModal
      && (
        <ReRequestConsutingReportModal
          isModal={reRequestConsultingReportModal}
          closeModal={closeReRequestConsultingReport}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
}
