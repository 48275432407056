export const rejectReason = [
  {
    value: 1,
    label: '신청자와 민법 제 777조의 규정에 의한 친족관계',
  },
  {
    value: 2,
    label: '예술가가 원하는 컨설팅 불가',
  },
  {
    value: 3,
    label: '컨설팅 진행시 향후 제3자로부터 오해의 소지가 발생할 수 있음',
  },
  {
    value: 4,
    label: '컨설턴트 개인사유',
  },
];
