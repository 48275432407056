import React, { useContext, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import Button from "../../Components/initComponents/CustomButtons/Button";
import Card from "../../Components/initComponents/Card/Card";
import CardHeader from "../../Components/initComponents/Card/CardHeader";
import CardBody from "../../Components/initComponents/Card/CardBody";
import AuthenticationContext from "../../Context/Authentication";
import Firebase from "../../Context/Firebase";
import * as siteConfig from "../../Constants/SiteConfig";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import featuresStyle from "../ConsultantList/featuresStyle";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: siteConfig.mainColor,
  },
}));

const featureStyles = makeStyles(featuresStyle);
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

Transition.displayName = "Transition";

const WithdrawalModal = (props) => {
  const { isModal, closeModal, loginedUserInfo } = props;
  const authUser = useContext(AuthenticationContext);
  const classes = useStyles();
  const featureClasses = featureStyles();
  const [isLoading, setIsLoading] = useState(false);
  const firebase = useContext(Firebase);

  const validationSchema = Yup.object().shape({
    withdrawalReason: Yup.string()
      .max(500, "500자 이내로 입력해주세요.")
      .required("탈퇴 사유를 간단히 입력해주세요"),
    password: Yup.string().required("로그인 비밀번호를 입력해주세요"),
  });

  const onSubmit = (value) => {
    setIsLoading(true);
    if (
      loginedUserInfo.ongoingConsultingCase > 0 ||
      loginedUserInfo.ongoingRequestCase > 0
    ) {
      window.alert(
        "현재 진행중인 컨설팅을 완료하거나 요청중인 컨설팅을 취소 후 계정탈퇴가 가능합니다."
      );
      closeModal();
      return false;
    }

    firebase
      .auth()
      .signInWithEmailAndPassword(authUser.email, value.password)
      .then(() => {
        const data = {
          authUserId: authUser.uid,
        };
        axios({
          url: `${siteConfig.functionUrl}/withdrawalUser`,
          method: "post",
          data: {
            data,
          },
        })
          .then((r) => {
            if (r) {
              firebase
                .firestore()
                .collection("UsersCollection")
                .doc(authUser.email)
                .update({
                  isWithdrawUser: true,
                  isAccountActive: false,
                  withdrawalReason: value.withdrawalReason,
                })
                .then((result) => {
                  console.log("Result", result);
                  setIsLoading(false);
                  firebase.auth().signOut();
                  alert("정상적으로 계정탈퇴가 처리되었습니다.");
                });
            }
          })
          .catch((e) => {
            console.log("withdrawalAxiosError", e);
          });
      })
      .catch(() => {
        window.alert("현재 비밀번호가 일치하지 않습니다.");
        setIsLoading(false);
      });
  };

  return (
    <>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={isModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => closeModal()}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => closeModal()}
          >
            <Close className={classes.modalClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={featureClasses.modalBody}
        >
          <Card profile className="boxShadowNone">
            <CardHeader image>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <HowToRegIcon />
                </Avatar>
              </div>
            </CardHeader>
            <CardBody>
              <Typography variant="h5">계정탈퇴</Typography>
              <Typography variant="body2">
                회원계정 탈퇴 후 <br />
                동일한 계정(이메일)으로 재가입을 할 수 없습니다.
                <br />
                신중하게 선택해주세요.
              </Typography>
            </CardBody>
          </Card>
          <Formik
            initialValues={{ withdrawalReason: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form>
                <Field
                  disabled={false}
                  component={TextField}
                  name="withdrawalReason"
                  placeholder="탈퇴 사유를 간단히 입력해주세요"
                  type="textarea"
                  label="탈퇴 사유를 간단히 입력해주세요"
                  id="outlined-multiline-flexible"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  name="password"
                  type="password"
                  label="비밀번호"
                  margin="normal"
                  fullWidth
                />
                <Box p={2} className="tac">
                  <Button color="primary" type="submit">
                    계정탈퇴하기
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => closeModal()} color="primary" simple>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

export default WithdrawalModal;
