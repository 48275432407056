import React, { useContext, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RemoveIcon from '@material-ui/icons/Remove';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import Button from '@material-ui/core/Button';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import * as siteConfig from '../../Constants/SiteConfig';
import * as Function from '../../Utill/Functions';
import FirebaseContext from '../../Context/Firebase';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';
import QnaAnswerModal from './QnaAnswerModal';


const QnaManage = () => {
  const firebase = useContext(FirebaseContext);
  const [consultingList, setConsultingList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('InquiryList')
      .orderBy('regDate', 'desc')
      .onSnapshot((result) => {
        const resultData = [];
        result.forEach((doc) => {
          resultData.push(doc.data());
        });
        setConsultingList(resultData);
        setIsLoading(false);
      });
    return () => unsubscribe();
  }, []);

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModal(true);
  };
  const closeModal = () => {
    setSelectedItem(null);
    setIsModal(false);
  };

  return isLoading ? (
    <LoadingOverlay
      isLoading={isLoading}
    />
  ) : (
    <>
      <MaterialTable
        title="문의내역관리"
        columns={[
          {
            title: '답변유무',
            field: 'isAnswer',
            lookup: { true: '답변완료', false: '답변대기' },
          },
          {
            title: '작성자',
            field: 'questionUserName',
          },
          {
            title: '이메일',
            field: 'questionUserEmail',
          },
          {
            title: '등록일',
            render: (data) => Function.printDateDash(data.regDate.toDate()),
          },

        ]}
        data={consultingList}
        options={{
          search: true,
          pageSize: 20,
          pageSizeOptions: [5, 10, 20, 30, 50],
          grouping: true,
        }}

        localization={
            {
              ...siteConfig.tableSetLable,
              grouping: { placeholder: '정렬하고자 하는 컬럼을 드래그해주세요.' },
              toolbar: { exportName: ' CSV파일로 다운로드', nRowsSelected: '{0} 건이 선택되었습니다.' },
            }
          }
        icons={{
          Add: AddCircleIcon,
          Check: CheckBoxIcon,
          Clear: ClearIcon,
          Delete: DeleteIcon,
          DetailPanel: AddCircleIcon,
          Edit: EditIcon,
          Export: GetAppIcon,
          Filter: FilterListIcon,
          FirstPage: FirstPageIcon,
          LastPage: LastPageIcon,
          NextPage: NavigateNextIcon,
          PreviousPage: NavigateBeforeIcon,
          ResetSearch: ClearIcon,
          Search: SearchIcon,
          SortArrow: ArrowDownwardIcon,
          ThirdStateCheck: RemoveIcon,
          ViewColumn: ViewColumnIcon,
        }}
        detailPanel={(rowData) => (
          <div className="qnaWraper">
            <div>
              {rowData.questionText}
            </div>
            {rowData.questionAnswer
              && (
              <div>
                <SubdirectoryArrowRightIcon />
                {rowData.questionAnswer}
              </div>
              )}
            <div className="tar">
              {!rowData.isAnswer
                  && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => openModal(rowData)}
                    >
                      답글등록하기
                    </Button>
                  )}
              {rowData.isAnswer
                  && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => openModal(rowData)}
                    >
                      답글수정하기
                    </Button>
                  )}
            </div>
          </div>
        )}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />

      {isModal
      && (
        <QnaAnswerModal
          selectedItem={selectedItem}
          isModal={isModal}
          closeModal={closeModal}
        />
      )}

    </>
  );
};


export default QnaManage;
