// export const siteDomain = 'https://artistmatching-3f32f.web.app/';
export const siteDomain = "https://www.icontact.kr";

// export const functionUrl = 'http://localhost:5001/artistmatching-3f32f/us-central1';
export const functionUrl = "https://us-central1-artistmatching-3f32f.cloudfunctions.net";

export const firebaseConfig = {
  apiKey: "AIzaSyBxp4tkxfgPojrLSjXraQG9Muk3Ta9hjPI",
  authDomain: "artistmatching-3f32f.firebaseapp.com",
  databaseURL: "https://artistmatching-3f32f.firebaseio.com",
  projectId: "artistmatching-3f32f",
  storageBucket: "artistmatching-3f32f.appspot.com",
  messagingSenderId: "44240621827",
  appId: "1:44240621827:web:ffd1052922b3a4949ed936",
  measurementId: "G-T98C5YSYQG",
};

export const consultingProcess = {
  1: "컨설팅요청중",
  2: "컨설팅요청보류",
  3: "컨설팅요청거절",
  4: "컨설팅진행중",
  5: "컨설팅중단",
  6: "컨설팅완료",
  7: "최종완료",
};

export const ConsultingGenreToText = {
  CG1: "창작분야전문상담",
  CG2: "예술기획",
  CG3: "창작기술지원",
  CG4: "예술창업",
  CG5: "홍보및마케팅",
  CG6: "저작권",
  CG7: "법률",
  CG8: "회계",
  CG9: "성희롱성폭력",
  CG10: "민간컨설턴트",
  CG11: "노무",
  CG12: "심리상담",
};

export const consultingAreaInfo = {
  C1: "시각예술",
  C2: "공연예술",
  C3: "문학",
  C4: "문화일반",
  C5: "기타",
};

export const consultingMainCatepory = {
  C1: "법률",
  C2: "회계",
  C3: "노무",
  C4: "홍보",
  C5: "상담",
  C6: "문화예술",
  C7: "법무",
};
export const consultingSubCateporyList = {
  CA: [
    "CG1",
    "CG2",
    "CG3",
    "CG4",
    "CG5",
    "CG6",
    "CG7",
    "CG8",
    "CG9",
    "CG10",
    "CG11",
    "CG12",
    "CG13",
    "CG14",
    "CG15",
    "CG16",
    "CG17",
    "CG18",
    "CG19",
    "CG20",
    "CG21",
    "CG22",
  ],
  C1: ["CG1", "CG2"],
  C2: ["CG3"],
  C3: ["CG4"],
  C4: ["CG5"],
  C5: ["CG6", "CG7"],
  C6: ["CG8", "CG9", "CG10", "CG11", "CG12", "CG13", "CG14", "CG15", "CG16", "CG17", "CG18", "CG19", "CG20", "CG21"],
  C7: ["CG22"],
};
export const consultingSubCatepory = {
  CG1: "법률",
  CG2: "법률 - 저작권",
  CG3: "회계",
  CG4: "노무",
  CG5: "홍보",
  CG6: "상담 - 심리",
  CG7: "상담 - 성희롱성폭력",
  CG8: "기획",
  CG9: "기획 - 문학",
  CG10: "기획 - 시각",
  CG11: "기획 - 공연",
  CG12: "기획 - 영화",
  CG13: "기획 - 문화일반",
  CG14: "창작",
  CG15: "창작 - 문학",
  CG16: "창작 - 시각",
  CG17: "창작 - 공연",
  CG18: "창작 - 영화",
  CG19: "창작 - 문화일반",
  CG20: "창업",
  CG21: "기술",
  CG22: "법무",
  // C1: ["법률", "법률-저작권"],
  // C2: ["회계"],
  // C3: ["노무"],
  // C4: ["홍보"],
  // C5: ["상담-심리", "상담-성희롱성폭력"],
  // C6: [
  //   "기획",
  //   "기획-문학",
  //   "기획-시각",
  //   "기획-공연",
  //   "기획-영화",
  //   "기획-문화일반",
  //   "창작",
  //   "창작-문학",
  //   "창작-시각",
  //   "창작-공연",
  //   "창작-영화",
  //   "창작-문화일반",
  //   "창업",
  //   "기술",
  // ],
};

export const ArtistAreaToText = {
  A1: "시각예술",
  A2: "공연예술",
  A3: "문학",
};

export const tableSetLable = {
  body: {
    emptyDataSourceMessage: "관련 데이터가 없습니다.",
  },
  toolbar: {
    searchTooltip: "검색",
    searchPlaceholder: "검색",
  },
  pagination: {
    labelDisplayedRows: "현재: {from} - {to} / 전체: {count}",
    labelRowsSelect: "줄 씩 보기",
    firstAriaLabel: "첫 페이지",
    firstTooltip: "첫 페이지",
    previousAriaLabel: "이전 페이지",
    previousTooltip: "이전 페이지",
    nextAriaLabel: "다음 페이지",
    nextTooltip: "다음 페이지",
    lastAriaLabel: "마지막 페이지",
    lastTooltip: "마지막 페이지",
  },
};

export const surveyQuestion = {
  q1: "1.예술활동 분야를 선택해주세요",
  q2: "2-1.컨설팅에 대해 전반적으로 만족하셨습니까?",
  q3: "2-2.컨설팅이 만족스럽지 않았다면 사유를 작성해주세요. 작성해 주신 내용은 사업 보완 및 개선에 반영됩니다.",
  q4: "3-1.컨설팅 내용이 도움이 되었다고 생각하십니까?",
  q5: "3-2.컨설팅 내용이 도움이 되지 않았다면 사유를 작성해주세요. 작성해 주신 내용은 실효성있는 컨설팅을 위해 반영됩니다.",
  q6: "4-1.컨설팅을 통해 알게된 내용과 정보가 향후 예술활동에 도움이 될 것으로 기대되나요?",
  q7: "4-2.컨설팅 내용 공개시 비슷한 고민을 가진 예술가들에게 참고가 될수 있을것 같습니다. 컨설팅 내용공개에 동의 하시나요?(*개인정보는 공개 되지 않습니다.)",
  q8: "5-1.컨설팅매칭 프로그램에 대한 홍보가 충분하였습니까?",
  q9: "5-2.예술인컨설팅 매칭 [아이컨택] 웹사이트를 어떻게 알게 되셨나요?",
  q10: "6.컨설팅 장소 및 기타시설에 대해 만족하셨습니까?",
  q11: "7.향후 컨설팅 매칭사업을 추천해줄 의사가 있으십니까?",
};

export const sendEmailImgURL = {
  1: "https://firebasestorage.googleapis.com/v0/b/artistmatching-3f32f.appspot.com/o/serviceFiles%2F1.jpg?alt=media&token=a95d4847-b4c2-41f3-985b-f29662df018a",
  2: "https://firebasestorage.googleapis.com/v0/b/artistmatching-3f32f.appspot.com/o/serviceFiles%2F2.jpg?alt=media&token=835f705d-c34a-48ad-af79-67dbae2fd2a5",
  3: "https://firebasestorage.googleapis.com/v0/b/artistmatching-3f32f.appspot.com/o/serviceFiles%2F3.jpg?alt=media&token=6fc6e861-f3f6-4fa9-bcfa-1654472edb7b",
  4: "https://firebasestorage.googleapis.com/v0/b/artistmatching-3f32f.appspot.com/o/serviceFiles%2F4.jpg?alt=media&token=d57343f0-4535-4e3c-b629-96fbb62f79f8",
  5: "https://firebasestorage.googleapis.com/v0/b/artistmatching-3f32f.appspot.com/o/serviceFiles%2F5.jpg?alt=media&token=37efbc58-3d50-4493-83a0-471b9722dbae",
  6: "https://firebasestorage.googleapis.com/v0/b/artistmatching-3f32f.appspot.com/o/serviceFiles%2F6.jpg?alt=media&token=f1c0367c-3cd2-494a-9f6a-c13d9761592f",
  7: "https://firebasestorage.googleapis.com/v0/b/artistmatching-3f32f.appspot.com/o/serviceFiles%2F7.jpg?alt=media&token=29aff861-98e6-4004-9537-47d21e2a7916",
  8: "https://firebasestorage.googleapis.com/v0/b/artistmatching-3f32f.appspot.com/o/serviceFiles%2F8.jpg?alt=media&token=81ee160f-6afe-413b-b6db-a5632f9cf21b",
};

// export const artistRequestConsultingLimitCase = 2;
// export const cancelConsultingRequestLimitCase = 5;
// export const consultantOngoingConsultingLimitCase = 3;
// export const maxConsultingComplteCase = 5;

export const artistRequestConsultingLimitCase = 20;
export const cancelConsultingRequestLimitCase = 50;
export const consultantOngoingConsultingLimitCase = 30;
export const maxConsultingComplteCase = 50;

export const mainColor = "#b72026";
export const adminEmailAddress = "bsarts@bscf.or.kr";
export const adminPhoneNumber = "01012341234";
