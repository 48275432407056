import React, { useState, useEffect, useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import { Link } from "react-router-dom";

import styles from "../../../assets/jss/material-kit-react/components/headerStyle";
import logoImg from "../../../assets/img/logo.svg";
import logoBawsc from "../../../assets/img/logo_bawsc.svg";
import * as Routes from "../../../Constants/Routes";
import Firebase from "../../../Context/Firebase";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  transparent: {
    ...styles(theme).transparent,
    color: "#000",
  },
  viewsContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function Header(props) {
  const { mobileOpen, setMobileOpen } = props;
  const classes = useStyles();
  const firebase = useContext(Firebase);
  const [today, setToday] = useState();
  const [total, setTotal] = useState();

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("Global")
      .doc("views")
      .onSnapshot((result) => {
        setToday(result.data()?.today);
        setTotal(result.data()?.total);
      });
    return () => {
      unsubscribe();
    };
  }, [firebase]);

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  const brandComponent = (
    <div>
      <Link to={Routes.ROOT}>
        <Button className={classes.title}>
          <div className={classes.imgGroup}>
            <img src={logoImg} alt="logo" style={{ width: "120px" }} />
            <img src={logoBawsc} alt="logo_bawsc" style={{ width: "120px" }} />
          </div>
        </Button>
      </Link>
      <Hidden mdDown>
        <div className={classes.viewsContainer}>
          <div className={classes.views}>
            <span>TODAY {today}</span>
            <div className={classes.verticalLine}>&nbsp;</div>
            <span>TOTAL {total}</span>
          </div>
        </div>
      </Hidden>
    </div>
  );
  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={`${classes.flex} ${classes.translateY15Up}`}>
          {leftLinks !== undefined ? (
            <Hidden mdDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>
        <Hidden mdDown implementation="css" className={classes.translateY15Up}>
          {rightLinks}
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.translateY15Up}
          >
            <Menu fontSize="large" />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden lgUp implementation="js">
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            <div>
              <Link to={Routes.ROOT}>
                <Button
                  className={classes.title}
                  onClick={() => {
                    setMobileOpen(false);
                  }}
                  style={{ padding: 0 }}
                >
                  <div className={classes.imgGroup}>
                    <img src={logoImg} alt="logo" style={{ width: "120px" }} />
                    <img
                      src={logoBawsc}
                      alt="logo_bawsc"
                      style={{ width: "120px" }}
                    />
                  </div>
                </Button>
              </Link>
              <div className={classes.viewsContainer}>
                <div className={classes.views}>
                  <span>TODAY {today}</span>
                  <div className={classes.verticalLine}>&nbsp;</div>
                  <span>TOTAL {total}</span>
                </div>
              </div>
            </div>
            {leftLinks}
            {rightLinks}
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "transparent",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};
