import React from "react";
import MainScreen from "./MainScreen";
import ConsultantList from "./ConsultantList";
import Banner from "./Banner";

const Index = () => (
  <>
    {/* <MainScreen /> */}
    <ConsultantList />
    {/* <Banner /> */}
  </>
);

export default Index;
