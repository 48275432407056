import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import NearMeIcon from "@material-ui/icons/NearMe";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import GridItem from "../../Components/initComponents/Grid/GridItem";
import featuresStyle from "./featuresStyle";
import GridContainer from "../../Components/initComponents/Grid/GridContainer";
import InfoArea from "../../Components/initComponents/InfoArea/InfoArea";
import iphone from "../../assets/img/main.jpg";

const useStyles = makeStyles(featuresStyle);

const MainScreen = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.features3}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={5}>
            <div className={classes.phoneContainer}>
              <img src={iphone} alt="mainImg" />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={7}>
            <div style={{ textAlign: "center" }}>
              <h2 className={classes.title}>당신과 Eye-Contact!</h2>
            </div>
            <InfoArea
              className={classes.infoArea}
              icon={NearMeIcon}
              title="부산문화재단과 함께하는 아이컨택!"
              description="아이컨택은 부산에서 활동하고 있는 예술인을 위한 전문 컨설팅매칭 서비스입니다."
              iconColor="rose"
            />
            <InfoArea
              className={classes.infoArea}
              icon={EmojiPeopleIcon}
              title="예술인이신가요?"
              description="당신의 어려움을 함께 고민할 전문 컨설턴트가 여기 있습니다. 지금 바로 시작하세요!"
              iconColor="rose"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default MainScreen;
