import React, { useContext, useEffect, useState } from 'react';
import AuthenticationContext from '../../Context/Authentication';
import ArtistMembership from './ArtistMembership';
import ConsultantMembership from './ConsultantMembership';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';
import FirebaseContext from '../../Context/Firebase';

const Membership = () => {
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthenticationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [loginedUserInfo, setLoginedUserInfo] = useState(null);
  const positionFlag = authUser.position === 'ARTIST';

  useEffect(() => {
    firebase.firestore().collection('UsersCollection')
      .doc(authUser.email)
      .get()
      .then((doc) => {
        setLoginedUserInfo(doc.data());
        setIsLoading(false);
      });
  }, [firebase]);


  return isLoading
    ? (
      <LoadingOverlay
        isLoading={isLoading}
      />
    )
    : (
      positionFlag
        ? (
          <ArtistMembership
            authUser={loginedUserInfo}
          />
        )
        : (
          <ConsultantMembership
            authUser={loginedUserInfo}
          />
        )
    );
};


export default Membership;
