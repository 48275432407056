import React, { useContext, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField } from 'formik-material-ui';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as Yup from 'yup';
import { Field, Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import * as SiteConfig from '../../Constants/SiteConfig';
import FirebaseContext from '../../Context/Firebase';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';
import * as Routes from '../../Constants/Routes';
import AuthenticationContext from '../../Context/Authentication';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: SiteConfig.mainColor,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const authUser = useContext(AuthenticationContext);
  const firebase = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState(false);


  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('현재 비밀번호를 입력해주세요.'),
    newPassword: Yup.string()
      .min(6, '비밀번호는 6자 이상 입력해주세요')
      .max(15, '비밀번호는 15자 이하로 입력해주세요')
      .required('새로운 비밀번호를 입력해주세요.'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], '입력하신 비밀번호와 일치하지 않습니다.')
      .required('새로운 비밀번호를 다시 입력해주세요.'),

  });

  const onSubmit = (values) => {
    setIsLoading(true);
    firebase.auth()
      .signInWithEmailAndPassword(authUser.email, values.currentPassword)
      .then(() => {
        firebase.auth().currentUser.updatePassword(values.newPassword).then(() => {
          window.alert('비밀번호가 정상적으로 변경되었습니다.');
          history.push(Routes.INDEX);
        }).catch((err) => {
          console.log('updatePasswdErr', err);
        });
      }).catch(() => {
        window.alert('현재 비밀번호가 일치하지 않습니다.');
        setIsLoading(false);
      });
  };

  return (
    <Box height="100vh">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Typography variant="body2">
            비밀번호 변경하기
          </Typography>
          <Formik
            initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: '' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form className={classes.form}>
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  type="password"
                  fullWidth
                  label="현재 비밀번호"
                  name="currentPassword"
                />
                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  type="password"
                  fullWidth
                  label="새로운 비밀번호"
                  name="newPassword"
                />

                <Field
                  disabled={false}
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  type="password"
                  fullWidth
                  label="비밀번호 재입력"
                  name="confirmNewPassword"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size="large"
                >
                  비밀번호 변경하기
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <LoadingOverlay
          isLoading={isLoading}
        />
      </Container>
    </Box>
  );
};


export default ChangePassword;
