import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import styles from "../../../assets/jss/material-kit-react/views/componentsSections/javascriptStyles";
import Button from "../../../Components/initComponents/CustomButtons/Button";
import Card from "../../../Components/initComponents/Card/Card";
import CardBody from "../../../Components/initComponents/Card/CardBody";
import featuresStyle from "../../ConsultantList/featuresStyle";
import ArtistDetailTable from "./ArtistDetailTable";

const useStyles = makeStyles(styles);
const featureStyles = makeStyles(featuresStyle);
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));
Transition.displayName = "Transition";

const ArtistDetailModal = (props) => {
  const { isModal, closeModal, selectedItem } = props;
  const classes = useStyles();
  const featureClasses = featureStyles();

  return (
    selectedItem && (
      <>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={isModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => closeModal()}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => closeModal()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={featureClasses.modalBody}
          >
            <Card profile className="boxShadowNone">
              <CardBody style={{ padding: "0" }}>
                <ArtistDetailTable
                  displayConsulting={selectedItem}
                  closeModal={closeModal}
                />
              </CardBody>
            </Card>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => closeModal()} color="primary" simple>
              닫기
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default ArtistDetailModal;
