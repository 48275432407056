import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";

import moment from "moment";
import styles from "../../assets/jss/material-kit-react/views/componentsSections/javascriptStyles";
import Button from "../../Components/initComponents/CustomButtons/Button";

import Firebase from "../../Context/Firebase";
import featuresStyle from "../ConsultantList/featuresStyle";
import * as Functions from "../../Utill/Functions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles(styles);
const featureStyles = makeStyles(featuresStyle);
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

Transition.displayName = "Transition";

const BannerAddModal = (props) => {
  const { isModal, setIsModal } = props;
  const firebase = useContext(Firebase);
  const classes = useStyles();
  const featureClasses = featureStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState([]);
  const today = moment(new Date());
  const firstDay = moment(new Date()).startOf("month");
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(today);
  const [bestConsultant, setBestConsultant] = useState(null);
  const [savedEmail, setSavedEmail] = useState(null);

  const saveEmail = (event) => {
    setSavedEmail(event.target.value);
  };

  const addConsultant = (email) => {
    setIsLoading(true);
    const resultData = [];
    firebase
      .firestore()
      .collection("UsersCollection")
      .where("position", "==", "CONSULTANT")
      .where("email", "==", email)
      .get()
      .then((result) => {
        result.forEach((doc) => {
          resultData.push(doc.data());
          // bestConsultant.push(doc.data());
        });
      })
      .then(() => {
        if (resultData.length == 0) {
          alert(
            `컨설턴트를 찾을 수 없습니다.\n검색하신 이메일 주소 [ ${email} ]를 다시 확인해주세요.`
          );
            setIsLoading(false);
        } else {
          firebase.firestore().collection("UsersCollection").doc(email).update({
            isConsultantOfTheMonth: true,
            isConsultantOfTheMonthAt: new Date(),
          });
          setBestConsultant(bestConsultant.concat(resultData))
          setIsLoading(false);
        }
      });
  };

  const deleteInBestConsultantList = (email) => {
    setBestConsultant((current) =>
      current.filter((bestConsultant) => bestConsultant.email !== email)
    );

    firebase.firestore().collection("UsersCollection").doc(email).update({
      isConsultantOfTheMonth: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    firebase
      .firestore()
      .collection("UsersCollection")
      .where("isConsultantOfTheMonth", "==", true)
      .get()
      .then((result) => {
        const resultData = [];
        result.forEach((doc) => {
          resultData.push(doc.data());
        });
        setBestConsultant(resultData);
        setIsLoading(false);
      });
  }, []);

  // useEffect(()=>{
  //   console.log(bestConsultant)
  // },[bestConsultant])

  return (
    !isLoading &&
    !!bestConsultant && (
      <>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={isModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setIsModal(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogContent
            id="classic-modal-slide-description"
            className={featureClasses.modalBody}
          >
            <div style={{ display: "flex", justifyContent: "end" }}>
              <input
                type="text"
                placeholder="컨설턴트 이메일"
                onChange={saveEmail}
                style={{ height: "28px", width: "200px", fontSize: "14px" }}
              />
              <Button
                style={{
                  cursor: "pointer",
                  margin: "0px 10px",
                  height: "28px",
                  width: "18px",
                }}
                onClick={() => addConsultant(savedEmail)}
              >
                등록
              </Button>
            </div>

            {bestConsultant.length > 0 ? (
              //   bestConsultant.map((e) => <div>{e.name}</div>)
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">번호</TableCell>
                      {/* <TableCell align="center">등록날짜</TableCell> */}
                      <TableCell align="center">이름</TableCell>
                      <TableCell align="center">이메일</TableCell>
                      <TableCell align="center">삭제</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bestConsultant.map((e, i) => (
                      <TableRow>
                        <TableCell>{++i}</TableCell>
                        {/* <TableCell>
                          {Functions.printDateDash(
                            e.isConsultantOfTheMonthAt.toDate()
                          )}
                        </TableCell> */}
                        <TableCell>{e.name}</TableCell>
                        <TableCell>{e.email}</TableCell>
                        <TableCell>
                          <IconButton
                            // className={classes.modalCloseButton}
                            // key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => deleteInBestConsultantList(e.email)}
                          >
                            <Close className={classes.modalClose} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "30px 0",
                }}
              >
                등록된 컨설턴트가 없습니다.
              </div>
            )}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => setIsModal(false)} color="primary" simple>
              닫기
            </Button>
          </DialogActions>
        </Dialog>
        {/* <LoadingOverlay isLoading={isLoading} /> */}
      </>
    )
  );
};

export default BannerAddModal;
