import React, {useEffect, useState, useContext} from 'react';
import FirebaseContext from '../../Context/Firebase';

import {
  useLocation
} from 'react-router-dom';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';
import ArtistMembership from './ArtistMembership';
import ConsultantMembership from './ConsultantMembership';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UserEditByAdmin = () => {
  const query = useQuery();
  const firebase = useContext(FirebaseContext);

  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);


  useEffect(() => {
    firebase.firestore().collection('UsersCollection')
      .doc(query.get('email'))
      .get()
      .then((doc) => {
        setUserInfo(doc.data());
        setIsLoading(false);
      });
  }, []);


  return isLoading
    ? (
      <LoadingOverlay
        isLoading={isLoading}
      />
    )
    : (
      userInfo.position === "ARTIST"
        ? (
          <ArtistMembership
            adminMode
            authUser={userInfo}
          />
        )
        : (
          <ConsultantMembership
            adminMode
            authUser={userInfo}
          />
        )
    );
};


export default UserEditByAdmin;
