import React, { useContext, useState, useEffect } from "react";
import { useFormik } from "formik";
import { DebounceInput } from "react-debounce-input";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useHistory } from "react-router-dom";
import GridItem from "../../Components/initComponents/Grid/GridItem";
import featuresStyle from "../ConsultantList/featuresStyle";
import GridContainer from "../../Components/initComponents/Grid/GridContainer";
import * as Routes from "../../Constants/Routes";
import FirebaseContext from "../../Context/Firebase";
import * as siteConfig from "../../Constants/SiteConfig";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import { surveyQuestion } from "../../Constants/SiteConfig";

const useStyles = makeStyles(featuresStyle);

const SelectRadioBtn = ({ handleChange }) => (
  <>
    <FormControlLabel
      value="매우그러함"
      control={<Radio />}
      label="매우그러함"
      onChange={handleChange}
    />
    <FormControlLabel
      value="그러함"
      control={<Radio />}
      label="그러함"
      onChange={handleChange}
    />
    <FormControlLabel
      value="보통"
      control={<Radio />}
      label="보통"
      onChange={handleChange}
    />
    <FormControlLabel
      value="그렇지않음"
      control={<Radio />}
      label="그렇지않음"
      onChange={handleChange}
    />
    <FormControlLabel
      value="전혀그렇지않음"
      control={<Radio />}
      label="전혀그렇지않음"
      onChange={handleChange}
    />
  </>
);

const Survey = () => {
  const classes = useStyles();
  const history = useHistory();
  const firebase = useContext(FirebaseContext);
  const consultingId = history.location.pathname.split("/")[2];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    firebase
      .firestore()
      .collection("ConsultingList")
      .doc(consultingId)
      .get()
      .then((resultData) => {
        if (!resultData.exists) {
          history.push(Routes.INDEX);
          return false;
        }

        if (resultData.data().consultingSurveyResult) {
          alert("이미 설문조사를 진행하였습니다.");
          history.push(Routes.INDEX);
          return false;
        }
      });
  }, []);

  const onSubmit = (value) => {
    setIsLoading(true);
    const surveyResultValues = Object.keys(value).map((item) => ({
      questionText: surveyQuestion[item],
      questionResultText: value[item],
      questionCode: item,
    }));

    firebase
      .firestore()
      .collection("ConsultingList")
      .doc(consultingId)
      .set(
        {
          consultingSurveyResult: surveyResultValues,
        },
        { merge: true }
      )
      .then(() => {
        alert("설문조사에 참여해주셔서 감사합니다.");
        setIsLoading(false);
        history.push(Routes.INDEX);
      });
  };

  const { errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q6: "",
      q7: "",
      q8: "",
      q9: "",
      q10: "",
      q11: "",
    },
    validationSchema: Yup.object().shape({
      q1: Yup.string().required("해당 항목을 체크해주세요."),
      q2: Yup.string().required("해당 항목을 체크해주세요."),
      q4: Yup.string().required("해당 항목을 체크해주세요."),
      q6: Yup.string().required("해당 항목을 체크해주세요."),
      q7: Yup.string().required("해당 항목을 체크해주세요."),
      q8: Yup.string().required("해당 항목을 체크해주세요."),
      q9: Yup.string().required("해당 항목을 체크해주세요."),
      q10: Yup.string().required("해당 항목을 체크해주세요."),
      q11: Yup.string().required("해당 항목을 체크해주세요."),
    }),
    onSubmit,
  });

  return (
    <>
      <div className={classes.container}>
        <div className={classes.features3}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10} lg={8}>
              <div style={{ textAlign: "center" }}>
                <h2 className={classes.title}>컨설팅 설문조사</h2>
                <h4 className={classes.infoArea}>
                  컨설팅 설문조사에 참여해주셔서 감사합니다.
                  <br />
                  각 항목에 대한 응답을 체크해주시고 하단의 제출하기 버튼을
                  클릭하시면 설문조사가 완료됩니다.
                  <br />
                  <br />
                </h4>
              </div>

              <form onSubmit={handleSubmit}>
                <Box mb={4}>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={classes.surveyTitle}
                    >
                      {surveyQuestion.q1}
                    </FormLabel>
                    <RadioGroup name="q1">
                      <FormControlLabel
                        value="시각예술"
                        control={<Radio />}
                        label="시각예술"
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        value="공연예술"
                        control={<Radio />}
                        label="공연예술"
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        value="문학"
                        control={<Radio />}
                        label="문학"
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        value="문화일반"
                        control={<Radio />}
                        label="문화일반"
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        value="기타"
                        control={<Radio />}
                        label="기타"
                        onChange={handleChange}
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="fcRed blinking">
                    {errors.q1 && touched.q1 && errors.q1}
                  </div>
                </Box>

                <Box mb={4}>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={classes.surveyTitle}
                    >
                      {surveyQuestion.q2}
                    </FormLabel>
                    <RadioGroup name="q2">
                      <SelectRadioBtn handleChange={handleChange} />
                    </RadioGroup>
                  </FormControl>
                  <div className="fcRed blinking">
                    {errors.q2 && touched.q2 && errors.q2}
                  </div>
                </Box>

                <Box mb={4}>
                  <FormLabel component="legend" className={classes.surveyTitle}>
                    {surveyQuestion.q3}
                  </FormLabel>
                  <DebounceInput
                    minLength={0}
                    debounceTimeout={500}
                    placeholder="사유입력"
                    fullWidth
                    variant="outlined"
                    element={TextField}
                    name="q3"
                    onChange={handleChange}
                  />
                </Box>

                <Box mb={4}>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={classes.surveyTitle}
                    >
                      {surveyQuestion.q4}
                    </FormLabel>
                    <RadioGroup name="q4">
                      <SelectRadioBtn handleChange={handleChange} />
                    </RadioGroup>
                  </FormControl>
                  <div className="fcRed blinking">
                    {errors.q4 && touched.q4 && errors.q4}
                  </div>
                </Box>

                <Box mb={4}>
                  <FormLabel component="legend" className={classes.surveyTitle}>
                    {surveyQuestion.q5}
                  </FormLabel>
                  <DebounceInput
                    minLength={0}
                    debounceTimeout={500}
                    placeholder="사유입력"
                    fullWidth
                    variant="outlined"
                    element={TextField}
                    name="q5"
                    onChange={handleChange}
                  />
                </Box>

                <Box mb={4}>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={classes.surveyTitle}
                    >
                      {surveyQuestion.q6}
                    </FormLabel>
                    <RadioGroup name="q6">
                      <SelectRadioBtn handleChange={handleChange} />
                    </RadioGroup>
                  </FormControl>
                  <div className="fcRed blinking">
                    {errors.q6 && touched.q6 && errors.q6}
                  </div>
                </Box>

                <Box mb={4}>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={classes.surveyTitle}
                    >
                      {surveyQuestion.q7}
                    </FormLabel>
                    <RadioGroup name="q7">
                      <FormControlLabel
                        value="동의"
                        control={<Radio />}
                        label="동의"
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        value="비동의"
                        control={<Radio />}
                        label="비동의"
                        onChange={handleChange}
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="fcRed blinking">
                    {errors.q7 && touched.q7 && errors.q7}
                  </div>
                </Box>

                <Box mb={4}>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={classes.surveyTitle}
                    >
                      {surveyQuestion.q8}
                    </FormLabel>
                    <RadioGroup name="q8">
                      <SelectRadioBtn handleChange={handleChange} />
                    </RadioGroup>
                  </FormControl>
                  <div className="fcRed blinking">
                    {errors.q8 && touched.q8 && errors.q8}
                  </div>
                </Box>

                <Box mb={4}>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={classes.surveyTitle}
                    >
                      {surveyQuestion.q9}
                    </FormLabel>
                    <RadioGroup name="q9">
                      <FormControlLabel
                        value="부산문화재단 홈페이지"
                        control={<Radio />}
                        label="부산문화재단 홈페이지"
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        value="부산문화재단 SNS"
                        control={<Radio />}
                        label="부산문화재단 SNS"
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        value="언론보도"
                        control={<Radio />}
                        label="언론보도"
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        value="지인추천"
                        control={<Radio />}
                        label="지인추천"
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        value="기타"
                        control={<Radio />}
                        label="기타"
                        onChange={handleChange}
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="fcRed blinking">
                    {errors.q9 && touched.q9 && errors.q9}
                  </div>
                </Box>

                <Box mb={4}>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={classes.surveyTitle}
                    >
                      {surveyQuestion.q10}
                    </FormLabel>
                    <RadioGroup name="q10">
                      <SelectRadioBtn handleChange={handleChange} />
                    </RadioGroup>
                  </FormControl>
                  <div className="fcRed blinking">
                    {errors.q10 && touched.q10 && errors.q10}
                  </div>
                </Box>

                <Box mb={4}>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={classes.surveyTitle}
                    >
                      {surveyQuestion.q11}
                    </FormLabel>
                    <RadioGroup name="q11">
                      <SelectRadioBtn handleChange={handleChange} />
                    </RadioGroup>
                  </FormControl>
                  <div className="fcRed blinking">
                    {errors.q11 && touched.q11 && errors.q11}
                  </div>
                </Box>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  {Object.keys(errors).length > 0 ? (
                    <span>미응답 항목이 있습니다.</span>
                  ) : (
                    <span>설문조사 제출하기</span>
                  )}
                </Button>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

export default Survey;
