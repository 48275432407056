import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import moment from "moment";
import imageCompression from "browser-image-compression";
import ImageUploader from "react-images-upload";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import styles from "../../assets/jss/material-kit-react/views/componentsSections/javascriptStyles";
import Button from "../../Components/initComponents/CustomButtons/Button";
import Card from "../../Components/initComponents/Card/Card";
import CardBody from "../../Components/initComponents/Card/CardBody";
import Firebase from "../../Context/Firebase";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import featuresStyle from "../ConsultantList/featuresStyle";
import * as Functions from "../../Utill/Functions";

const useStyles = makeStyles(styles);
const featureStyles = makeStyles(featuresStyle);
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

Transition.displayName = "Transition";

const BannerEditModal = (props) => {
  const { isModal, closeModal, selectedItem } = props;
  const firebase = useContext(Firebase);
  const classes = useStyles();
  const featureClasses = featureStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(selectedItem.bannerStartDate.toDate())
  );
  const [endDate, setEndDate] = useState(
    moment(selectedItem.bannerEndDate.toDate())
  );

  const onDropimg = (uploadImg) => {
    setImg(uploadImg[0]);
  };
  const uploadImg = async () => {
    if (img === undefined || img.length < 1) {
      return false;
    }
    try {
      console.log("imgUpload");
      const compressedFile = await imageCompression(img, {
        maxWidthOrHeight: 800,
      });
      const randomKeys = Functions.RandomKey(8);
      const uploadTask = firebase
        .storage()
        .ref(`banners/${randomKeys}`)
        .put(compressedFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.log(error);
        },
        () => {
          const BannerImgName = uploadTask.snapshot.ref.name;
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            firebase
              .firestore()
              .collection("Banners")
              .doc(selectedItem.id)
              .set(
                { BannerImgUrl: downloadURL, BannerImgName },
                { merge: true }
              );
            setIsLoading(false);
            closeModal();
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (value) => {
    setIsLoading(true);
    firebase.firestore().collection("Banners").doc(selectedItem.id).update({
      bannerLink: value.bannerLink,
      bannerStartDate: startDate.toDate(),
      bannerEndDate: endDate.toDate(),
    });
    uploadImg();
    setIsLoading(false);
    closeModal();
  };

  return (
    <>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={isModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => closeModal()}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => closeModal()}
          >
            <Close className={classes.modalClose} />
          </IconButton>
        </DialogTitle>

        <DialogContent
          id="classic-modal-slide-description"
          className={featureClasses.modalBody}
        >
          <Card profile className="boxShadowNone">
            <img
              src={`${selectedItem.BannerImgUrl}`}
              style={{ width: "100%" }}
            />
            <CardBody>
              <Formik
                initialValues={{
                  bannerLink: selectedItem.bannerLink,
                }}
                onSubmit={onSubmit}
              >
                {({ submitForm }) => (
                  <Form>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                      locale="ko"
                    >
                      <ImageUploader
                        label=""
                        withPreview
                        withIcon={false}
                        fileSizeError="파일 크기용량은 10MB 이하로 업로드 해주세요."
                        buttonText="배너이미지 수정하기(가로 800px이상)"
                        onChange={onDropimg}
                        singleImage
                        imgExtension={[".jpg", ".jpeg", ".png"]}
                        maxFileSize={10485760}
                      />
                      <Grid container justify="flex-end" spacing={4}>
                        <Grid item>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="YYYY-MM-DD(dd)"
                            margin="normal"
                            id="date-picker-from"
                            label="시작 날짜"
                            value={startDate}
                            onChange={setStartDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="YYYY-MM-DD(dd)"
                            margin="normal"
                            id="date-picker-to"
                            label="종료 날짜"
                            value={endDate}
                            onChange={setEndDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Field
                        disabled={false}
                        component={TextField}
                        variant="outlined"
                        name="bannerLink"
                        type="text"
                        label="배너링크 입력(예: http://www.naver.com) - 없을경우 공란"
                        margin="normal"
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                    <Box p={2} className="tac">
                      <Button color="primary" onClick={submitForm}>
                        수정하기
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => closeModal()} color="primary" simple>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

export default BannerEditModal;
