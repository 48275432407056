import React, { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, useMediaQuery, Hidden } from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { DebounceInput } from "react-debounce-input";
import TextField from "@material-ui/core/TextField";
import GridContainer from "../../Components/initComponents/Grid/GridContainer";
import GridItem from "../../Components/initComponents/Grid/GridItem";
import Card from "../../Components/initComponents/Card/Card";
import CardHeader from "../../Components/initComponents/Card/CardHeader";
import CardFooter from "../../Components/initComponents/Card/CardFooter";
import featuresStyle from "./featuresStyle";
import FirebaseContext from "../../Context/Firebase";
import sampleImg from "../../assets/img/samplePeople.jpg";
import bgImg from "../../assets/img/bg2.svg";
import RequestConsultingModal from "./RequestConsultingModal";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import * as siteConfig from "../../Constants/SiteConfig";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles({
  ...featuresStyle,
  searchInputArea: {
    "& .MuiOutlinedInput-root": { height: "46px", width: "165px" },
  },
});

const ConsultantList = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 767px)");

  const [isLoading, setIsLoading] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [initDate, setInitData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const firebase = useContext(FirebaseContext);
  const [displayConsultant, setDisplayConsultant] = useState([]);
  const [firstCateResultData, setFirstCateResultData] = useState(null);

  const [firstCategory, setFirstCategory] = useState("CA");
  const [secondCategory, setSecondCategory] = useState("CGA");
  const [searchKeyword, setSearchKeyword] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory();

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const unsubscribe = firebase
      .firestore()
      .collection("UsersCollection")
      .where("position", "==", "CONSULTANT")
      .where("isAccountActive", "==", true)
      .onSnapshot((result) => {
        const resultData = [];
        result.forEach((doc) => {
          const docData = doc.data();
          resultData.push({
            ...docData,
            id: doc.id,
          });
        });
        const sortedData = resultData.sort(() => Math.random() - Math.random());
        setDisplayConsultant(sortedData);
        setInitData(sortedData);

        if (sessionStorage.getItem("selectedItem") != null) {
          setSelectedItem(JSON.parse(sessionStorage.getItem("selectedItem")));
          setIsModal(true);
          sessionStorage.removeItem("selectedItem");
        }
        setIsLoading(false);
      });

    return () => {
      unsubscribe();
    };
  }, [firebase]);

  useEffect(() => {
    if (initDate.length === 0) return;
    const queryFirstCategory = urlParams.get("firstCate");
    const querySecondCategory = urlParams.get("secondCate");
    const querySearchKeyword = urlParams.get("searchKeyword");

    if (queryFirstCategory !== null) {
      setFirstCategory(queryFirstCategory);
    }
    if (querySecondCategory !== null) {
      setSecondCategory(querySecondCategory);
    }
    if (querySearchKeyword !== null) {
      setSearchKeyword(querySearchKeyword);
    }
    // firstHandleChange(queryFirstCategory);
    // secondHandleChange(querySecondCategory);
    // handleSearch(querySearchKeyword);

    // if (queryFirstCategory !== null) {
    //   firstHandleChange(queryFirstCategory);
    // }
    // if (querySecondCategory !== null && firstCategory !== "CGA") {
    //   secondHandleChange(querySecondCategory);
    // }
    // if (querySearchKeyword !== null) {
    //   handleSearch(querySearchKeyword);
    // }
  }, [initDate]);

  useEffect(() => {
    setFilteredData(
      initDate
        .filter((e) =>
          firstCategory === "CA"
            ? true
            : e.consultingMainCatepory === firstCategory
        )
        .filter((e) =>
          secondCategory === "CGA"
            ? true
            : e.consultingSubCatepory === secondCategory
        )
        .filter((r) => {
          if (searchKeyword == null) {
            return r;
          }
          if (
            r.name.includes(searchKeyword) ||
            // r.consultingAreaText.includes(searchKeyword) ||
            r.profileContent.includes(searchKeyword)
          ) {
            return r;
          }
        })
    );
  }, [firstCategory, secondCategory, searchKeyword]);

  const firstHandleChange = (event) => {
    let changedValue = "";
    if (typeof event === "string") {
      changedValue = event;
    } else {
      changedValue = event.target.value;
    }

    if (changedValue !== firstCategory) {
      // setDisplayConsultant(initDate);
      setSecondCategory("CGA");
    }
    urlParams.set("firstCate", changedValue);
    history.push(`/consultantList?${urlParams.toString()}`);
    // else {
    //   const categoryData = initDate.filter(
    //     (value) => value.consultingGenre === changedValue
    //   );
    //   setDisplayConsultant(categoryData);
    //   setFirstCateResultData(categoryData);
    // }
    setFirstCategory(changedValue);
  };

  const secondHandleChange = (event) => {
    let changedValue = "";
    if (typeof event === "string") {
      changedValue = event;
    } else {
      changedValue = event.target.value;
    }
    urlParams.set("secondCate", changedValue);
    history.push(`/consultantList?${urlParams.toString()}`);
    // if (firstCateResultData == null || firstCategory === "CGA") {
    //   alert("컨설팅 분야(대분류)를 먼저 선택해주세요");
    //   return false;
    // }
    // const result = firstCateResultData.filter((r) => {
    //   if (changedValue === "ALL") {
    //     return r;
    //   }
    //   if (r.consultingAreaText.includes(changedValue)) {
    //     return r;
    //   }
    // });
    // setDisplayConsultant(result);
    setSecondCategory(changedValue);
  };

  const handleSearch = (event) => {
    let keyword = "";
    if (typeof event === "string") {
      keyword = event;
    } else {
      keyword = event.target.value;
    }
    const result = initDate.filter((r) => {
      if (keyword == null) {
        return r;
      }
      if (
        r.name.includes(keyword) ||
        // r.consultingAreaText.includes(keyword) ||
        r.profileContent.includes(keyword)
      ) {
        return r;
      }
    });
    if (firstCategory !== "CA" || secondCategory !== "CGA") {
      setFirstCategory("CA");
      setSecondCategory("CGA");
      urlParams.delete("firstCate");
      urlParams.delete("secondCate");
    }
    if (keyword === "") {
      urlParams.delete("searchKeyword");
    } else {
      urlParams.set("searchKeyword", keyword);
    }
    history.push(`/consultantList?${urlParams.toString()}`);
    setSearchKeyword(keyword);
    setDisplayConsultant(result);
  };

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModal(true);
  };
  const closeModal = () => {
    setSelectedItem(null);
    setIsModal(true);
  };

  return (
    <>
      <Box
        // mt={10}
        pt={10}
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
        }}
      >
        <div className={`${classes.container} tal mb25`}>
          <Box mb={6}>
            <GridContainer style={{ display: "flex", alignItems: "end" }}>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <Hidden mdUp>
                  <h3
                    style={{
                      fontWeight: 700,
                      color: "#212121",
                      fontSize: "32px",
                    }}
                  >
                    컨설턴트 목록
                  </h3>
                  <div style={{ width: "100%" }}>
                    <h5 className={classes.description}>
                      원하는 컨설턴트를 선택하여 1:1로 컨설팅을 받아보세요.
                    </h5>
                  </div>
                </Hidden>
                <Hidden smDown>
                  <h3
                    style={{
                      fontWeight: 700,
                      color: "#212121",
                      fontSize: "48px",
                      // paddingTop: "50px",
                    }}
                  >
                    컨설턴트 목록
                  </h3>
                  <div>
                    <h5 className={classes.description}>
                      원하는 컨설턴트를 선택하여 1:1로 컨설팅을 받아보세요.
                    </h5>
                  </div>
                </Hidden>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={6}
                style={
                  isMobile
                    ? { display: "flex" }
                    : {
                        display: "flex",
                        justifyContent: "end",
                      }
                }
              >
                <div>
                  <FormControl variant="outlined">
                    {/* <InputLabel id="demo-simple-select-outlined-label">분야</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={firstCategory}
                      onChange={firstHandleChange}
                      style={{
                        marginRight: "10px",
                        marginBottom: "5px",
                        height: "46px",
                        width: "136px",
                      }}
                    >
                      <MenuItem value="CA">분야 선택</MenuItem>
                      {Object.entries(siteConfig.consultingMainCatepory).map(
                        ([key, value]) => (
                          <MenuItem value={key}>{value}</MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined">
                    {/* <InputLabel id="demo-simple-select-label">
                      세부분야
                    </InputLabel> */}
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={secondCategory}
                      onChange={secondHandleChange}
                      style={{
                        marginRight: "10px",
                        height: "46px",
                        width: "165px",
                        marginBottom: "5px",
                      }}
                    >
                      <MenuItem value="CGA">세부분야 선택</MenuItem>
                      {siteConfig.consultingSubCateporyList[firstCategory].map(
                        (r) => (
                          <MenuItem value={r}>
                            {siteConfig.consultingSubCatepory[r]}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>

                  <DebounceInput
                    className={`${classes.searchInputArea} searchInputArea`}
                    variant="outlined"
                    element={TextField}
                    minLength={1}
                    type="search"
                    debounceTimeout={500}
                    placeholder="검색어 입력"
                    onChange={handleSearch}
                    value={searchKeyword}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </Box>
        </div>
        <div className={classes.container}>
          <GridContainer>
            {initDate.length > 0 ? (
              initDate
                .filter((e) =>
                  firstCategory === "CA"
                    ? true
                    : e.consultingMainCatepory === firstCategory
                )
                .filter((e) =>
                  secondCategory === "CGA"
                    ? true
                    : e.consultingSubCatepory === secondCategory
                )
                .filter((r) => {
                  if (searchKeyword == null) {
                    return r;
                  }
                  if (
                    r.name.includes(searchKeyword) ||
                    // r.consultingAreaText.includes(searchKeyword) ||
                    r.profileContent.includes(searchKeyword)
                  ) {
                    return r;
                  }
                })
                .map((r) => (
                  <GridItem sm={12} md={6} key={r.id}>
                    {!isMobile && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            maxHeight: 362,
                            overflow: "hidden",
                            borderRadius: 20,
                            border: "1px solid #E0E0E0",
                            marginBottom: 44,
                          }}
                          // onClick={() => openModal(r)}
                        >
                          <div
                            style={{
                              padding: "64px 17px 17px 44px",
                              width: "60%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "32px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "48px",
                                letterSpacing: "-2px",
                              }}
                            >
                              {r.name}
                            </div>
                            <div
                              style={{
                                height: 117,
                                margin: "12px 0 18px 0",
                                overflow: "hidden",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "22px",
                                color: "#212121",
                              }}
                            >
                              {r.briefHistory?.map((str) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "start",
                                  }}
                                >
                                  <p style={{ width: "12px", margin: 0 }}>•</p>
                                  <p
                                    style={{
                                      margin: "0",
                                      marginBottom: 6,
                                      wordBreak: "break-all",
                                      width: "calc(100% - 12px)",
                                    }}
                                  >
                                    {str}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: 10,
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  height: "29px",
                                  padding: "10px 12px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  borderRadius: "100px",
                                  backgroundColor: "#6FBA2C",
                                  fontSize: 13,
                                  fontWeight: 700,
                                  lineHeight: 22,
                                  color: "white",
                                }}
                              >
                                {"# " +
                                  siteConfig.consultingSubCatepory[
                                    r.consultingSubCatepory
                                  ]}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                margin: "10px 0px",
                                justifyContent: "right",
                                fontSize: 13,
                                fontWeight: 700,
                                color: "#BDBDBD",
                              }}
                            >
                              <p
                                style={{
                                  padding: "10px 5px",
                                  fontSize: 13,
                                  fontWeight: 700,
                                  color: "#BDBDBD",
                                  cursor: "pointer",
                                }}
                                onClick={() => openModal(r)}
                              >
                                {" "}
                                {"바로가기 " + ">"}
                              </p>
                            </div>
                          </div>

                          <div
                            style={{
                              maxHeight: "362px",
                              width: "45%",
                              display: "block",
                            }}
                          >
                            <img
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              src={
                                r.profileImgUrl ? r.profileImgUrl : sampleImg
                              }
                              alt={`${r.name} 프로필 이미지`}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {isMobile && (
                      <div>
                        <div
                          style={{
                            minWidth: "342px",
                            overflow: "hidden",
                            borderRadius: 20,
                            border: "1px solid #E0E0E0",
                            marginBottom: 44,
                            backgroundColor: "white",
                          }}
                          // onClick={() => openModal(r)}
                        >
                          <div
                            style={{
                              height: "300px",
                              width: "100%",
                            }}
                          >
                            <img
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              src={
                                r.profileImgUrl ? r.profileImgUrl : sampleImg
                              }
                              alt={`${r.name} 프로필 이미지`}
                            />
                          </div>
                          <div style={{ padding: "26px", height: "280px" }}>
                            <div
                              style={{
                                fontSize: "24px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "36px",
                                letterSpacing: "-2px",
                                marginBottom: "10px",
                              }}
                            >
                              {r.name}
                            </div>
                            <div
                              style={{
                                height: 117,
                                margin: "12px 0 18px 0",
                                overflow: "hidden",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "22px",
                                color: "#212121",
                              }}
                            >
                              {r.briefHistory?.map((str) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "start",
                                  }}
                                >
                                  <p style={{ width: "12px", margin: 0 }}>•</p>
                                  <p
                                    style={{
                                      margin: 0,
                                      marginBottom: 6,
                                      wordBreak: "break-all",
                                      width: "calc(100% - 12px)",
                                    }}
                                  >
                                    {str}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: 10,
                                flexWrap: "wrap",
                                margin: "10px 0px 30px 0px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  height: "29px",
                                  padding: "10px 12px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  borderRadius: "100px",
                                  backgroundColor: "#6FBA2C",
                                  fontSize: 13,
                                  fontWeight: 700,
                                  lineHeight: 22,
                                  color: "white",
                                }}
                              >
                                {"# " +
                                  siteConfig.consultingSubCatepory[
                                    r.consultingSubCatepory
                                  ]}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                margin: "10px 0px",
                                justifyContent: "right",
                                fontSize: 13,
                                fontWeight: 700,
                                color: "#BDBDBD",
                              }}
                            >
                              <p
                                style={{
                                  padding: "10px 5px",
                                  fontSize: 13,
                                  fontWeight: 700,
                                  color: "#BDBDBD",
                                  cursor: "pointer",
                                }}
                                onClick={() => openModal(r)}
                              >
                                {" "}
                                {"바로가기 " + ">"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </GridItem>
                ))
            ) : (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "30px 0",
                }}
              >
                등록된 컨설턴트가 없습니다.
              </div>
            )}
          </GridContainer>
        </div>
      </Box>

      {isModal && (
        <RequestConsultingModal
          selectedItem={selectedItem}
          isModal={isModal}
          closeModal={closeModal}
        />
      )}
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

export default ConsultantList;
