import {
  container,
  mlAuto,
  mrAuto,
  blackColor,
  whiteColor,
  hexToRgb,
} from '../../assets/jss/material-kit-pro-react';

const faq = {
  container,
  mlAuto,
  mrAuto,
  title: {
    fontWeight: 700,
    color: '#212121',
    fontSize: '48px',
  },
  features1: {
    // textAlign: 'center',
    // padding: '80px 0',
  },
  features2: {
    padding: '80px 0',
  },
  features3: {
    padding: '0',
    '& $phoneContainer': {
      maxWidth: '100%',
      margin: '0 auto',
    },
  },
  features4: {
    padding: '80px 0',
    '& $phoneContainer': {
      maxWidth: '260px',
      margin: '60px auto 0',
    },
  },
  features5: {
    padding: '80px 0',
    backgroundSize: 'cover',
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    '& $title': {
      marginBottom: '30px',
    },
    '& $title,& $container': {
      position: 'relative',
      zIndex: '2',
      color: whiteColor,
    },
    '&:after': {
      background: `rgba(${hexToRgb(blackColor)},0.55)`,
      position: 'absolute',
      width: '100%',
      height: '100%',
      content: "''",
      zIndex: '0',
      left: '0px',
      top: '0px',
    },
    '& $container': {
      '& $gridContainer:last-child': {
        '& $gridItem': {
          borderBottom: '0',
        },
      },
      '& $gridItem': {
        border: `1px solid rgba(${hexToRgb(whiteColor)}, 0.35)`,
        borderTop: '0',
        borderLeft: '0',
        '&:last-child': {
          borderRight: '0',
        },
      },
    },
    '& $infoArea5': {
      textAlign: 'center',
      maxWidth: '310px',
      minHeight: '320px',
      '& h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
        color: whiteColor,
      },
    },
  },
  gridContainer: {},
  gridItem: {},
  textCenter: {
    textAlign: 'center',
  },
  phoneContainer: {
    '& img': {
      width: '100%',
    },
  },
  infoArea: {
    maxWidth: 'none',
    margin: '0 auto',
    padding: '10px 0 0px',
  },
  infoArea5: {},
  description: {
    fontWeight: 500,
    color: '#757575',
    fontSize: '14px',
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      color:'#757575',
      padding: '28px'
    },
  },
};

export default faq;
