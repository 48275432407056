import React, { useContext, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";

import styles from "../../../assets/jss/material-kit-react/views/componentsSections/javascriptStyles";
import Button from "../../../Components/initComponents/CustomButtons/Button";
import Card from "../../../Components/initComponents/Card/Card";
import CardHeader from "../../../Components/initComponents/Card/CardHeader";
import sampleImg from "../../../assets/img/samplePeople.jpg";
import CardBody from "../../../Components/initComponents/Card/CardBody";
import Firebase from "../../../Context/Firebase";

import featuresStyle from "../../ConsultantList/featuresStyle";
import * as siteConfig from "../../../Constants/SiteConfig";

const useStyles = makeStyles(styles);
const featureStyles = makeStyles(featuresStyle);
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

Transition.displayName = "Transition";

const ConsultantDetailInfoModal = (props) => {
  const { isModal, closeModal, consultantEmail } = props;

  const classes = useStyles();
  const featureClasses = featureStyles();
  const firebase = useContext(Firebase);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    firebase
      .firestore()
      .collection("UsersCollection")
      .doc(consultantEmail)
      .get()
      .then((result) => {
        setSelectedItem(result.data());
        setIsLoading(false);
      });
  }, [consultantEmail, firebase]);

  return (
    !isLoading && (
      <>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={isModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => closeModal()}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => closeModal()}
            >
              <Close className={classes.modalClose} />
            </IconButton>
          </DialogTitle>

          <DialogContent
            id="classic-modal-slide-description"
            className={featureClasses.modalBody}
          >
            <Card profile className="boxShadowNone">
              <CardHeader image>
                <img
                  src={
                    selectedItem.profileImgUrl
                      ? selectedItem.profileImgUrl
                      : sampleImg
                  }
                  alt="..."
                />
                <div className={featureClasses.cardTitleAbsolute} />
              </CardHeader>
              <CardBody>
                <h3 className={featureClasses.modalTitle}>
                  {selectedItem.name}
                </h3>
                <p className={featureClasses.cardDescription}>
                  {
                    siteConfig.ConsultingGenreToText[
                      selectedItem.consultingGenre
                    ]
                  }
                  <br />
                  {selectedItem.consultingAreaText}
                </p>
                <pre className={featureClasses.cardDescription}>
                  {selectedItem.profileContent}
                </pre>
              </CardBody>
            </Card>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => closeModal()} color="primary" simple>
              닫기
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default ConsultantDetailInfoModal;
