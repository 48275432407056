import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import * as Routes from "../../Constants/Routes";

const JoinPageLink = () => (
  <>
    <Box mt={5}>
      <Typography variant="body2" color="textSecondary" align="center">
        예술인 컨설팅 매칭 서비스 Eye-Contact
      </Typography>

      <Typography variant="body2" color="textSecondary" align="center">
        아직 회원이 아니신가요?
        <Link to={Routes.ARTISTJOIN}>
          <Button>회원가입하기</Button>
        </Link>
        <Link to={Routes.CONSULTANTJOIN}>
          <Button>컨설턴트라면 여기를 눌러 회원가입해주세요</Button>
        </Link>
      </Typography>
    </Box>
  </>
);

export default JoinPageLink;
