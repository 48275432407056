import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { Link, useHistory } from "react-router-dom";
import CustomDropdown from "../../Components/initComponents/CustomDropdown/CustomDropdown";
import iconSearchWhite from "../../assets/img/ic_search_white.svg";
import { container } from "../../assets/jss/material-kit-pro-react";
import * as Routes from "../../Constants/Routes";
import * as siteConfig from "../../Constants/SiteConfig";

const useStyles = makeStyles((theme) => ({
  container: {
    ...container,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      maxWidth: "100%",
    },
  },
  searchContainer: {
    height: 0,
    marginBottom: "170px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      marginBottom: "unset",
      display: "block",
    },
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "auto",
    zIndex: 1300,
    background: "#fff",
    borderRadius: "20px",
    padding: "44px 64px",
    boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)",
    position: "relative",
    top: "-75px",
    gap: "15px",
    minWidth: "65%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      width: "100%",
      padding: "30px 24px 30px 34px",
      boxShadow: "unset",
      borderRadius: "unset",
      top: "unset",
    },
  },
  header: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
    gridRow: 1,
    [theme.breakpoints.down("sm")]: {
      gridRow: "unset",
      margin: "unset",
    },
  },
  searchBtn: {
    padding: "20px",
    borderRadius: "10px",
    background: "#6FBA2C",
    cursor: "pointer",
    "&:hover": {
      background: "#63a527",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      height: "100%",
    },
  },
  searchInputArea: {
    padding: "0 !important",
    "& .MuiInput-underline:before": {
      borderBottom: "unset",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid #6FBA2C",
    },
    "& .MuiInput-underline:hover": {
      borderBottom: "1px solid #6FBA2C",
    },
    "& .MuiInputBase-root": {
      fontFamily: "'Noto Sans KR', sans-serif",
      color: "#424242",
    },
    "& :root": {
      "--swiper-theme-color": "#6fba2c !important",
    },
    "& .MuiInputBase-input": {
      padding: "2px 0 7px",
    },
    // "& .MuiInputBase-input::placeholder": {
    //   color: "#424242",
    // },
  },
  dropdownButton: {
    display: "flex",
    padding: "7px 0 0 0",
    margin: 0,
    fontSize: "16px",
    width: "140px",
    justifyContent: "space-between",
    "& b": {
      borderTop: "5px solid",
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
    },
    "& span": {
      paddingRight: "7px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3px 0 0 0",
    },
  },
  dropdownPopper: {
    left: "-17px !important",
    right: "unset !important",
  },
  dropdownPaper: ({ firstCategory, secondCategory }) => ({
    boxShadow: "none",
    borderRadius: "10px",
    padding: "16.5px 20px",
    border: "1px solid #6FBA2C",
    "& li": {
      fontFamily: "'Noto Sans KR', sans-serif",
      fontSize: "16px",
      margin: 0,
      padding: "3.5px 0",
      "&:hover": {
        textDecoration: "none",
        backgroundColor: "transparent",
        color: "#6FBA2C",
      },
    },
  }),
  firstCateory: ({ firstCategory }) => ({
    "& svg.MuiSelect-icon": {
      color: firstCategory === "CA" ? "#9E9E9E" : "#424242",
    },
    color: firstCategory === "CA" ? "#9E9E9E" : "#424242",
  }),
  secondCategory: ({ secondCategory }) => ({
    "& svg.MuiSelect-icon": {
      color: secondCategory === "CGA" ? "#9E9E9E" : "#424242",
    },
    color: secondCategory === "CGA" ? "#9E9E9E" : "#424242",
  }),
  consultantCategory: {
    marginBottom: "0 !important",
  },
  selected: {
    color: "#6FBA2C !important",
  },
  cateContainer: {
    display: "grid",
    justifyContent: "space-between",
    width: "100%",
    columnGap: "15px",
    gridTemplateColumns: "repeat(3, 1fr)",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto 1fr",
      rowGap: "15px",
      columnGap: "22px",
    },
  },
  cateItem: {
    display: "flex",
  },
}));

const Search = () => {
  const [firstCategory, setFirstCategory] = useState("CA");
  const [secondCategory, setSecondCategory] = useState("CGA");
  const [searchKeyword, setSearchKeyword] = useState("");
  const classes = useStyles({ firstCategory, secondCategory });
  const history = useHistory();

  const firstHandleChange = (changedValue) => {
    if (changedValue !== firstCategory) {
      // setDisplayConsultant(initDate);
      setSecondCategory("CGA");
    }
    setFirstCategory(changedValue);
  };

  const secondHandleChange = (changedValue) => {
    // if (firstCategory === "CGA") {
    //   alert("컨설팅 분야(대분류)를 먼저 선택해주세요");
    //   return false;
    // }
    setSecondCategory(changedValue);
  };

  const handleSearch = (event) => {
    const keyword = event.target.value;
    if (firstCategory !== "CA") {
      setFirstCategory("CA");
      setSecondCategory("CGA");
    }
    setSearchKeyword(keyword);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const handleClickSearch = () => {
    history.push(
      `${Routes.CONSULTANTLIST}${searchKeyword !== "" ? "" : firstCategory !== "CA" ? `?firstCate=${firstCategory}` : ""}${
        searchKeyword !== "" ? "" : secondCategory !== "CGA" ? `${firstCategory === "CA" ? "?" : "&"}secondCate=${secondCategory}` : ""
      }${searchKeyword !== "" ? `?searchKeyword=${searchKeyword}` : ""}`
    );
  };

  const getCategoryName = (code, isFirst) => {
    let result = "";
    if (isFirst) {
      switch (code) {
        case "CA":
          result = "선택해주세요";
          break;
        case "C1":
          result = "법률";
          break;
        case "C2":
          result = "회계";
          break;
        case "C3":
          result = "노무";
          break;
        case "C4":
          result = "홍보";
          break;
        case "C5":
          result = "상담";
          break;
        case "C6":
          result = "문화예술";
          break;
        case "C7":
          result = "법무";
          break;
        default:
          result = "선택해주세요";
      }
    } else {
      switch (code) {
        case "CGA":
          result = "선택해주세요";
          break;
        case "CG1":
          result = "법률";
          break;
        case "CG2":
          result = "법률 - 저작권";
          break;
        case "CG3":
          result = "회계";
          break;
        case "CG4":
          result = "노무";
          break;
        case "CG5":
          result = "홍보";
          break;
        case "CG6":
          result = "상담 - 심리";
          break;
        case "CG7":
          result = "상담 - 성희롱성폭력";
          break;
        case "CG8":
          result = "기획";
          break;
        case "CG9":
          result = "기획 - 문학";
          break;
        case "CG10":
          result = "기획 - 시각";
          break;
        case "CG11":
          result = "기획 - 공연";
          break;
        case "CG12":
          result = "기획 - 영화";
          break;
        case "CG13":
          result = "기획 - 문화일반";
          break;
        case "CG14":
          result = "창작";
          break;
        case "CG15":
          result = "창작 - 문학";
          break;
        case "CG16":
          result = "창작 - 시각";
          break;
        case "CG17":
          result = "창작 - 공연";
          break;
        case "CG18":
          result = "창작 - 영화";
          break;
        case "CG19":
          result = "창작 - 문화일반";
          break;
        case "CG20":
          result = "창업";
          break;
        case "CG21":
          result = "기술";
          break;
        case "CG22":
          result = "법무";
          break;
        default:
          result = "선택해주세요";
      }
    }
    return result;
  };

  return (
    <div className={classes.container}>
      <div className={classes.searchContainer}>
        <div className={classes.flexContainer}>
          <div className={classes.cateContainer}>
            <div className={classes.header}>분야</div>
            <div>
              <CustomDropdown
                left
                hoverColor="transparent"
                buttonText={getCategoryName(firstCategory, true)}
                buttonProps={{
                  className: `${classes.firstCateory} ${classes.dropdownButton}`,
                  color: "transparent",
                }}
                paperProps={{
                  className: classes.dropdownPaper,
                }}
                popperProps={{
                  className: classes.dropdownPopper,
                }}
                dropdownList={[
                  <div onClick={() => firstHandleChange("CA")} className={firstCategory === "CA" ? classes.selected : ""}>
                    선택해주세요
                  </div>,
                  ...Object.entries(siteConfig.consultingMainCatepory).map(([key, value]) => (
                    <div onClick={() => firstHandleChange(key)} className={firstCategory === key ? classes.selected : ""}>
                      {value}
                    </div>
                  )),
                ]}
              />
            </div>
            <div className={classes.header}>세부분야</div>
            <div>
              <CustomDropdown
                left
                hoverColor="transparent"
                buttonText={getCategoryName(secondCategory, false)}
                buttonProps={{
                  className: `${classes.secondCategory} ${classes.dropdownButton}`,
                  color: "transparent",
                }}
                paperProps={{
                  className: classes.dropdownPaper,
                }}
                popperProps={{
                  className: classes.dropdownPopper,
                }}
                dropdownList={[
                  <div onClick={() => secondHandleChange("CGA")} className={secondCategory === "CGA" ? classes.selected : ""}>
                    선택해주세요
                  </div>,
                  ...siteConfig.consultingSubCateporyList[firstCategory].map((r) => (
                    <div onClick={() => secondHandleChange(r)} className={secondCategory === r ? classes.selected : ""}>
                      {siteConfig.consultingSubCatepory[r]}
                    </div>
                  )),
                ]}
              />
            </div>
            <div className={classes.header}>검색</div>
            <div>
              <DebounceInput
                className={`${classes.searchInputArea} searchInputArea`}
                element={TextField}
                minLength={1}
                type="search"
                debounceTimeout={500}
                placeholder="검색어 입력"
                onChange={handleSearch}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>
          <div>
            <Link
              to={`${Routes.CONSULTANTLIST}${searchKeyword !== "" ? "" : firstCategory !== "CA" ? `?firstCate=${firstCategory}` : ""}${
                searchKeyword !== ""
                  ? ""
                  : secondCategory !== "CGA"
                  ? `${firstCategory === "CA" ? "?" : "&"}secondCate=${secondCategory}`
                  : ""
              }${searchKeyword !== "" ? `?searchKeyword=${searchKeyword}` : ""}`}
            >
              <div className={classes.searchBtn} onClick={handleClickSearch}>
                <img src={iconSearchWhite} alt="search" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
