import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { container } from "../../assets/jss/material-kit-pro-react";
import { makeStyles } from "@material-ui/core/styles";
import bannerImg from "../../assets/img/banner.svg";
import banner2Img from "../../assets/img/banner2.svg";
import bannerMobileImg from "../../assets/img/banner_mobile.svg";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

const useStyles = makeStyles((theme) => ({
  bannerRoot: {
    paddingTop: "48px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "12px",
    },
  },
  container: {
    ...container,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      padding: 0,
    },
  },
  swiper: {
    "& .swiper-button-prev": {
      color: "#fff",
      "--swiper-navigation-size": "27px",
      left: "35px",
    },
    "& .swiper-button-next": {
      color: "#fff",
      "--swiper-navigation-size": "27px",
      right: "35px",
    },
    "& .swiper-wrapper": {
      display: "flex",
      alignItems: "center",
    },
  },
  bannerImg: {
    width: "100%",
    borderRadius: "30px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  // firstBanner: {
  //   content: `url(${bannerImg})`,
  //   [theme.breakpoints.down("sm")]: {
  //     content: `url(${bannerMobileImg})`,
  //   },
  // },
  desktopSlide: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileSlide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const Banner = () => {
  const classes = useStyles();
  const [swiperHeight, setSwiperHeight] = useState(0);
  const [slides, setSlides] = useState([]);

  SwiperCore.use([Navigation, Autoplay]);

  useEffect(() => {
    if (slides.length === 0) return;
    slides.forEach((slide) => {
      if (slide.className.includes("swiper-slide-active")) {
        setSwiperHeight(slide.clientHeight);
      }
    });
  }, [slides]);

  return (
    <>
      <Box
        // mt={10}
        // pt={6}
        className={classes.bannerRoot}
      >
        <div className={`${classes.container} tal`}>
          <Swiper
            className={classes.swiper}
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={15}
            slidesPerView={1}
            navigation
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            onSwiper={(swiper) => {}}
            onSlideChange={(e) => {
              setSlides(
                e.slides.map((slide) => {
                  const { className, clientHeight } = slide;
                  if (className.includes("swiper-slide-active"))
                    return {
                      className: className,
                      clientHeight: clientHeight,
                    };
                })
              );
            }}
            // style={{ height: swiperHeight }}
          >
            <SwiperSlide className={classes.desktopSlide}>
              <img
                className={`${classes.bannerImg} ${classes.firstBanner}`}
                src={bannerImg}
                alt="banner"
              />
            </SwiperSlide>
            <SwiperSlide className={classes.mobileSlide}>
              <img
                className={`${classes.bannerImg} ${classes.firstBanner}`}
                src={bannerMobileImg}
                alt="banner"
              />
            </SwiperSlide>
            {/* <SwiperSlide>
              <img
                className={classes.bannerImg}
                src={banner2Img}
                alt="banner"
              />
            </SwiperSlide> */}
          </Swiper>
        </div>
      </Box>
    </>
  );
};

export default Banner;
