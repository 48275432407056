import { container, primaryColor } from "../../material-kit-react";

const footerStyle = (theme) => ({
  block: {
    // color: "inherit",
    color: "#757575 !important",
    fontSize: "12px",
    // padding: "0.9375rem",
    margin: 0,
    fontWeight: "500",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "400",
    },
  },
  deskTopBlock: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  mobileBlock: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      lineHeight: "normal",
    },
  },
  footerBtn: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 700,
      color: "#9e9e9e !important",
    },
  },
  left: {
    float: "left!important",
    display: "block",
    padding: "0 15px",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    [theme.breakpoints.down("sm")]: {
      gap: "12px",
    },
  },
  right: {
    textAlign: "left",
    margin: "0",
    fontSize: "12px",
    float: "right!important",
    padding: "0 15px",
  },
  footer: {
    paddingTop: "50px",
    paddingBottom: "60px",
    borderTop: "1px solid #ccc",
    // padding: "0.9375rem 0",
    display: "flex",
    zIndex: "2",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "26px",
      paddingBottom: "33px",
    },
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#000",
    },
  },
  container: {
    ...container,
    padding: "0 !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    // flexDirection: "row-reverse",
    flexWrap: "wrap",
    gap: "15px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "left",
      gap: "18px",
    },
  },
  list: {
    display: "flex",
    gap: "40px",
    marginBottom: "0",
    marginTop: "0",
    padding: 0,
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px",
  },
});
export default footerStyle;
