import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Box from '@material-ui/core/Box';

import * as Yup from 'yup';
import SubBanner from '../../Components/SubBanner/Index';
import styles from '../../assets/jss/material-kit-react/views/componentsSections/tabsStyle';
import bgImg from '../../assets/img/bg2.svg';
import GridContainer from '../../Components/initComponents/Grid/GridContainer';
import GridItem from '../../Components/initComponents/Grid/GridItem';
import Button from '../../Components/initComponents/CustomButtons/Button';

import AuthenticationContext from '../../Context/Authentication';
import Firebase from '../../Context/Firebase';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';
import InquiryList from './InquiryList';

const useStyles = makeStyles({
  ...styles,
  section: {
    ...styles.section,
    padding: '15px 0',
    marginTop: '50px',
    backgroundImage: `url(${bgImg})`,
    backgroundPosition: 'top right',
    backgroundSize: 'cover',
    background: 'transparent',
    minHeight: '60vh',
  },
  titleWrap: {
    marginBottom: '35px',
    textAlign: 'right',
  },
});


const validationSchema = Yup.object().shape({
  question: Yup.string()
    .max(1000, '1000자 이내로 입력해주세요.')
    .required('문의사항을 입력해주세요.'),
});


const Index = () => {
  const classes = useStyles();
  const authUser = useContext(AuthenticationContext);
  const firebase = useContext(Firebase);
  const [isLoading, setIsLoading] = useState(true);
  const [displayQna, setDisplayQna] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('UsersCollection')
      .doc(authUser.email)
      .collection('MyInquiryList')
      .orderBy('regDate', 'desc')
      .onSnapshot((result) => {
        const resultData = [];
        result.forEach((doc) => {
          resultData.push(doc.data());
        });
        setDisplayQna(resultData);
        setIsLoading(false);
      });
    return () => {
      unsubscribe();
    };
  }, [firebase, authUser.email]);

  const onSubmit = (value, { resetForm }) => {
    const nowTime = new Date();
    firebase.firestore().collection('InquiryList')
      .add({
        questionText: value.question,
        questionUserEmail: authUser.email,
        questionUserName: authUser.name,
        questionAnswer: '',
        regDate: nowTime,
        isAnswer: false,
      })
      .then((r) => {
        firebase.firestore().collection('InquiryList')
          .doc(r.id)
          .set({
            id: r.id,
          }, { merge: true });

        firebase.firestore().collection('UsersCollection')
          .doc(authUser.email)
          .collection('MyInquiryList')
          .doc(r.id)
          .set({
            questionText: value.question,
            questionAnswer: '',
            regDate: nowTime,
            isAnswer: false,
            docId: r.id,
          });
        resetForm();
      });
  };

  return isLoading
    ? (
      <LoadingOverlay
        isLoading={isLoading}
      />
    )
    : (
      <>
        <SubBanner />
        <div className={classes.section}>
          <div className={classes.container}>
            <div id="nav-tabs">
              <div className={classes.titleWrap}>
                <h5 className={classes.siteInfo}>
                  <span className="naviInfoFirstChar">M</span>
                  AIN
                  <ArrowRightIcon fontSize="small" className="naviInfoIcon" />
                  MY INQUIRY
                </h5>
              </div>
            </div>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={7}>
                <InquiryList
                  displayQna={displayQna}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={7}>
                <Box mt={5}>
                  <Formik
                    initialValues={{ question: '' }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {() => (
                      <Form>
                        <Field
                          disabled={false}
                          component={TextField}
                          name="question"
                          placeholder="문의사항을 입력해주세요. 관리자가 확인 후 답변을 드리겠습니다!"
                          type="textarea"
                          label="문의사항을 입력해주세요"
                          id="outlined-multiline-flexible"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={3}

                        />
                        <Box pt={2} className="tac">
                          <Button
                            className="fs17"
                            color="primary"
                            type="submit"
                            fullWidth
                          >
                            문의하기
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </GridItem>
            </GridContainer>
          </div>
        </div>

      </>
    );
};


export default Index;
